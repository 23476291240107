import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { WsEmailElementComponent } from '../ws-email-element/ws-email-element.component'

@Component({
  selector: 'app-ws-email-social-element',
  templateUrl: './ws-email-social-element.component.html',
  styleUrls: ['./ws-email-social-element.component.scss', '../ws-email-element/ws-email-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsEmailSocialElementComponent extends WsEmailElementComponent implements OnInit, AfterViewInit {}
