{
  "container-background-color": "",
  "css-class": "",
  "padding": "",
  "padding-bottom": "",
  "padding-left": "",
  "padding-right": "",
  "padding-top"	 : "",
  "mode": "horizontal"
}

