{
  "desktopPreview": "Vorschau Desktop",
  "mobilePreview": "Vorschau Mobil",
  "emailAddress": "Email Adresse",
  "save": "Speichern",
  "cancel": "Abbrechen",
  "delete": "löschen",
  "edit": "bearbeiten",
  "send": "Versenden",
  "height": "Höhe",
  "layers": "Ebenen",
  "drafts": "Vorlagen",
  "bricks": "Bausteine",
  "unsavedChanges": "Ungespeicherte Änderungen gehen verloren",
  "unsavedChangesLost": "Wenn Sie fortfahren, gehen Ihre ungespeicherten Änderungen verloren.",
  "element": {
    "name": "Bausteinbezeichnung",
    "saveElementBlock": "Baustein speichern",
    "errorSaveElementBlock": "Baustein konnte nicht gespeichert werden. Bitte überprüfen Sie die Eingaben.",
    "successSaveElementBlock": "Baustein erfolgreich gespeichert",
    "type": {
      "section": "Zeile",
      "sections": "Zeilen",
      "headline": "Überschrift",
      "column": "Spalte",
      "columns": "Spalten",
      "image": "Bild",
      "spacer": "Abstand",
      "divider": "Linie"
    },
    "dropContentHint": "Inhalt hier einfügen",
    "emptySection": "Leere Zeile"
  },
  "template": {
    "subject": "Betreff",
    "create" : "Template erstellen",
    "enterTextHint": "Ausschließlich Buchstaben und Zahlen verwenden",
    "save" : "Template speichern",
    "duplicate": "Template duplizieren",
    "name": "Templatebezeichnung",
    "rename": "Template umbenennen",
    "saveChanges" : "Änderungen speichern",
    "edit" : "Template bearbeiten",
    "select": "Tempalte auswählen",
    "delete": "Template löschen",
    "deleteTemplateDialog": "Sind Sie sicher, dass sie das Template löschen möchten?",
    "datasetChoiceDialog": "Bitte wählen Sie einen Datensatz aus.",
    "close": "Schließen",
    "enterName" : "Template Bezeichnung eingeben",
    "nameExample" : "z.B. Buchungsbestätigung",
    "objectSettings": "Objekt",
    "layerOverview": "Ebenen",
    "content": "Inhalt",
    "settings": {
      "settings": "Einstellungen",
      "page": "Seite",
      "save": "Einstellungen speichern",
      "pageLayout": "Seitenlayout",
      "header": "Kopfzeile",
      "footer": "Fußzeile",
      "margins": "Ränder",
      "marginTop": "Rand oben",
      "marginBottom": "Rand unten",
      "marginLeft": "Rand links",
      "marginRight": "Rand rechts",
      "orientation": "Ausrichtung",
      "portrait": "Hochformat",
      "landscape": "Querformat",
      "displayPageCounter": "Seitenzahl anzeigen",
      "hidePageCounter": "Seitenzahl ausblenden",
      "displayHeaderOnFirstPage": "Kopfzeile nur auf erster Seite anzeigen",
      "displayFooterOnFirstPage": "Fußzeile nur auf erster Seite anzeigen"
    },
    "configuration": {
      "configuration": "Konfiguration",
      "open": "Konfiguration einblenden",
      "hide": "Konfiguration ausblenden",
      "overview": "Übersicht",
      "element": "Element",
      "textSettings": "Textierung"
    },
    "help": "Hilfe",
    "element": {
      "padding": "Abstand",
      "innerPadding": "Innenabstand",
      "top": "Oben",
      "bottom": "Unten",
      "left": "Links",
      "right": "Rechts",
      "mobile": "Mobil",
      "hideOnMobile": "Auf Mobilgerät ausblenden",
      "groupedColumns": "Auf Mobilgerät in einer Reihe anzeigen",
      "layer": "Ebene",
      "fullWidth": "Ganze Breite",
      "url": "URL",
      "content": "Inhalt",
      "imageUrl": "Bild URL",
      "name": "Elementname",
      "visibility": "Sichtbarkeit",
      "show": "einblenden",
      "hide": "ausblenden",
      "copy": "kopieren",
      "delete": "löschen",
      "zIndex": "Ebene",
      "size": "Größe",
      "height": "Höhe",
      "width": "Breite",
      "scaleProportionally": "proportional skalieren",
      "position": "Position",
      "positionInfo": "obere linke Ecke des Elements",
      "horizontal": "horizontal",
      "horiz": "horiz.",
      "vertical": "vertikal",
      "vert": "vert.",
      "border": "Umrandung",
      "borderNone": "keine",
      "borderColor": "Farbe",
      "borderRadius": "Abgerundete Ecken",
      "radius": "Rundung",
      "background": "Hintergrund",
      "backgroundImageUrl": "Hintergrundbild URL",
      "backgroundColor": "Hintergrundfarbe",
      "rotation": "Rotation",
      "degrees": "Grad",
      "opacity": "Deckkraft",
      "text": "Text",
      "textFormat": "Textformatierung",
      "textAlign": "Textausrichtung",
      "textAlignLeft": "links ausrichten",
      "textAlignCenter": "mittig ausrichten",
      "textAlignRight": "rechts ausrichten",
      "textAlignJustify": "füllen",
      "textAlignTop": "oben ausrichten",
      "textAlignBottom": "unten ausrichten",
      "textWordBreak": "Zeilenumbruch",
      "textWordBreakNormal": "normal",
      "textWordBreakBreakAll": "alles",
      "textWordBreakKeepAll": "keine",
      "textWordBreakBreakWord": "wortweise",
      "textTransformUpperCase": "Großbuchstaben",
      "textTransformLowerCase": "Kleinbuchstaben",
      "textTransformNone": "normal",
      "textUnderline": "unterstrichen",
      "textLineSpacing": "Zeilenabstand",
      "font": "Schrift",
      "fontFamily": "Schriftart",
      "fontSize": "Schriftgröße",
      "fontStyle": "Schriftstil",
      "fontItalic": "kursiv",
      "fontWeight": "Schriftstärke",
      "fontBold": "fett",
      "fontColor": "Schriftfarbe",
      "boundary": "Element Begrenzung",
      "alignment": "Element Ausrichtung",
      "widthUnitSelection": "Einheit von Breite wechseln",
      "imageAltText": "Alternativer Text",
      "style": "Stil",
      "dotted": "Gepunktet",
      "dashed": "Gestrichelt",
      "solid": "Durchgezogen",
      "icons-size": "Icons Größe"
    },
    "editor": {
      "label": "Textfeld hinzufügen",
      "placeholder": "Platzhalter hinzufügen",
      "image": "Bild hinzufügen",
      "qrCode": "QR-Code hinzufügen",
      "container": "Container hinzufügen",
      "newDataObject": "{ Datenobjekt }",
      "newTextField": "Neues Textfeld",
      "preview": "PDF Vorschau",
      "testData": "Testdatensatz",
      "editTestData": "Testdatensatz bearbeiten",
      "pageCounter": "Seitenzahl hinzufügen",
      "dataObject": "Datenobjekt hinzufügen",
      "templateOverview": "alle Templates",
      "helpingGrid": "Hilfslinien",
      "toggleHelpingGridOn": "Hilfslinien anzeigen",
      "toggleHelpingGridOff": "Hilfslinien ausblenden",
      "testDelivery": "Testversand",
      "recipient": "Empfänger",
      "earlierVersions": "Frühere Versionen"
    },
    "fields": "Eigene Felder für Gutscheinvorlage",
    "field": {
      "add": "Feld für Vorlage hinzufügen",
      "key": "Key für Feld",
      "value": "Text",
      "deleteConfirm": "Bist du sicher, dass du das Feld \"{{key}}\" in der Sprache \"{{lang}}\" löschen willst?",
      "delete": "Feld löschen"
    },
    "message": {
      "testDelivery": {
        "success": "Testversand erfolgreich",
        "error": "Testversand fehlgeschlagen"
      },
      "saveSuccess" : "Template erfolgreich gespeichert",
      "saveNoChanges" : "Template weist keine Änderungen auf",
      "createSuccess" : "Template erfolgreich erstellt",
      "createError" : "Template konnte nicht erstellt werden",
      "invalidNameError" : "Template konnte nicht erstellt werden. Bitte geben Sie eine gültige Bezeichnung ein.",
      "loadingError" : "Templates konnten nicht geladen werden",
      "doesNotExistError": "Template existiert nicht"
    }
  },
  "emailBlock": {
    "deleteEmailBlockDialog": "Sind Sie sicher, dass sie den Baustein löschen möchten?",
    "delete": "Baustein löschen",
    "deleteSuccess": "Baustein erfolgreich gelöscht",
    "deleteError": "Baustein konnte nicht gelöscht werden. Bitte versuchen Sie es später erneut."
  }
}
