import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core'
import {
  AdvancedFileUploadComponent,
  DialogComponent,
  WsDialogService,
  WsNotificationsService
} from '@ws-core/core-library'
import { ImageStorageService } from '../../../services/util/image-storage.service'
import { UploadedFile } from '../../../data/types/uploaded-file'
import { FormControl, Validators } from '@angular/forms'
import { EditorStateService } from '../../../services/util/editor-state.service'
import { debounceTime } from 'rxjs'

@Component({
  selector: 'app-ws-email-select-image-dialog',
  templateUrl: './ws-email-select-image-dialog.component.html',
  styleUrl: './ws-email-select-image-dialog.component.scss'
})
export class WsEmailSelectImageDialogComponent implements OnInit {
  // @Input()
  @Input() showDialog: boolean = false

  // Simple types
  uploading: boolean = false
  selectedFile: File | null = null
  imageSelectDialogState: ImageSelectDialogState = ImageSelectDialogStates.Selection

  // Arrays of objects
  allImages: UploadedFile[] = []
  shownImages: UploadedFile[] = []

  // Variables with other decorations (e.g. @ViewChild)
  @ViewChild('fileUpload') fileUpload: AdvancedFileUploadComponent | undefined

  // FormGroup declarations
  linkInputFormControl = new FormControl('', [Validators.pattern(/^(https:\/\/.*|\$\{[^{}]*})$/)])
  searchFormControl = new FormControl('')

  // @Output()
  @Output() showDialogChange = new EventEmitter<boolean>()
  @Output() imageSelected = new EventEmitter<string>()

  // Injectables
  readonly imageUploadService = inject(ImageStorageService)
  readonly editorStateService = inject(EditorStateService)
  readonly dialogService: WsDialogService = inject(WsDialogService)
  readonly notificationsService: WsNotificationsService = inject(WsNotificationsService)
  protected readonly ImageSelectDialogStates = ImageSelectDialogStates

  // Component lifecycle hooks that initialize
  ngOnInit() {
    this.searchFormControl.valueChanges.pipe(debounceTime(300)).subscribe((searchTerm) => {
      this.searchMenuItems(searchTerm)
    })
  }

  onShowDialogChange($event: boolean) {
    this.showDialogChange.emit($event)
    this.imageSelectDialogState = ImageSelectDialogStates.Selection
    if ($event) {
      this.loadImages()
    }
  }

  onFileSelected(file: File | null) {
    this.selectedFile = file
  }

  onUploadImage() {
    if (!this.selectedFile) return
    const imageExists = this.doesImageExist(this.selectedFile.name)
    imageExists ? this.confirmAndUpload() : this.uploadImage()
  }

  onSelectImage(url: string | undefined) {
    if (url === undefined) {
      return
    }
    this.showDialog = false
    this.showDialogChange.emit(false)
    this.imageSelected.emit(url)
    this.editorStateService.updateViewOfAllElements()
  }

  onLinkSelected() {
    if (this.linkInputFormControl.valid && this.linkInputFormControl.value) {
      const url = this.linkInputFormControl.value
      this.onSelectImage(url)
    }
  }

  private loadImages() {
    this.imageUploadService.getImages().subscribe({
      next: (images) => {
        this.allImages = images
        this.shownImages = images
      },
      error: () => {
        this.notificationsService.error('template.editor.image.overview.error')
      }
    })
  }

  private doesImageExist(filename: string): boolean {
    return !!this.allImages.find((image) => image.filename === filename)
  }

  private confirmAndUpload() {
    const dialog: DialogComponent = this.dialogService.confirm('template.editor.image.upload.confirmOverride')
    dialog.afterClose$.subscribe((action: string) => {
      if (action === 'confirm') this.uploadImage()
    })
  }

  private uploadImage() {
    if (!this.selectedFile) return
    this.uploading = true

    this.imageUploadService.uploadImage(this.selectedFile).subscribe({
      next: (mediaFile) => {
        this.handleImageUploadResponse(mediaFile)
      },
      error: () => {
        this.notificationsService.error('template.editor.image.upload.error')
        this.uploading = false
      }
    })
  }

  private handleImageUploadResponse(mediaFile: UploadedFile) {
    const existingImage = this.allImages.find((image) => image.identifier === mediaFile.identifier)

    if (!existingImage) {
      this.allImages.push(mediaFile)
    } else {
      existingImage.url = mediaFile.url
    }

    this.resetUploadState()
    this.onSelectImage(mediaFile.url)
  }

  private resetUploadState() {
    this.selectedFile = null
    this.fileUpload?.removeFromFiles(0)
    this.uploading = false
    this.imageSelectDialogState = ImageSelectDialogStates.Selection
    this.shownImages = this.allImages
  }

  private searchMenuItems(searchTerm: string | null) {
    if (searchTerm === null) {
      this.shownImages = this.allImages
      return
    }
    this.shownImages = this.allImages.filter((image) =>
      image.filename?.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }
}

export const ImageSelectDialogStates = {
  Selection: 'Selection',
  LinkInput: 'LinkInput',
  Upload: 'Upload'
} as const

export type ImageSelectDialogState = (typeof ImageSelectDialogStates)[keyof typeof ImageSelectDialogStates]
