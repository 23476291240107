import { Component, Input } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { FieldComponent } from '../field/field.component'

@Component({
  selector: 'ws-form-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent extends FieldComponent implements ControlValueAccessor {
  @Input() public step? = 1
  @Input() public min? = 0
  @Input() public max? = 100

  public override value = 0

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public override onChange(newValue: number) {}

  public override registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn
  }

  public override writeValue(value: number): void {
    this.value = value
  }

  public override onFieldChange(input: number) {
    super.onFieldChange(input)
  }
}
