import { Component, Input } from '@angular/core'
import { ControlValueAccessor } from '@angular/forms'
import { FieldComponent } from '../field/field.component'

@Component({
  selector: 'ws-form-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent extends FieldComponent implements ControlValueAccessor {
  @Input() readonly = false

  public override value = false

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public override onChange(newValue: boolean): void {}

  public override registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn
  }

  public override writeValue(value: boolean): void {
    this.value = value
  }

  public override onFieldChange(input: boolean) {
    super.onFieldChange(input)
  }
}
