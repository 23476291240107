<app-ws-email-element-wrapper
  #wrapper
  [element]="element"
  [elementIsActive]="elementIsActive"
  [parent]="parent"
  [dragHandle]="dragHandle"
  [component]="this"
>
  <p style="font-size: 10px" [ngStyle]="element?.getStyles(template.testData).p"></p>
</app-ws-email-element-wrapper>
