import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { WsEmailElementComponent } from '../ws-email-element/ws-email-element.component'
import { Toolbar } from 'ngx-editor'

@Component({
  selector: 'app-ws-email-button',
  templateUrl: './ws-email-button.component.html',
  styleUrls: ['./ws-email-button.component.scss', '../ws-email-element/ws-email-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsEmailButtonComponent extends WsEmailElementComponent implements OnInit, AfterViewInit {
  @Input() toolbar: Toolbar = [['bold', 'italic'], ['underline', 'strike'], ['format_clear']]

  override elementIsActiveChange(event: boolean) {
    if (!this.element) return
    this.convertTags()
    this.cd.detectChanges()
  }

  /**
   * Convert p tags since to avoid problems with the editor (problem with nested p tags)
   * since button itself is within p tags
   */
  convertTags() {
    this.element!.content = this.element!.content.replaceAll('</p><p>', '<br>')
      .replaceAll('<p>', '')
      .replaceAll('</p>', '')
  }
}
