import { Injectable } from '@angular/core'
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs'
import { Brick } from '../../data/models/brick'
import { WsEmailElementWrapperComponent } from '../../modules/block-components/ws-email-element-wrapper/ws-email-element-wrapper.component'

@Injectable({
  providedIn: 'root'
})
export class EditorStateService {
  private selectedElementSubject = new BehaviorSubject<WsEmailElementWrapperComponent | null>(null)
  selectedElement$: Observable<WsEmailElementWrapperComponent | null> = this.selectedElementSubject.asObservable().pipe(
    distinctUntilChanged() // Only emit if the selected element changes
  )
  private subject: BehaviorSubject<{ [p: string]: string | number }> = new BehaviorSubject<{
    [p: string]: string | number
  }>({})
  styles: Observable<{ [p: string]: string | number }> = this.subject.asObservable()

  private wrapperComponents: Map<string, WsEmailElementWrapperComponent> = new Map()
  element: Brick | undefined = undefined

  constructor() {}

  showGuidelines = false

  selectElement(element: Brick, scrollIntoView = false) {
    if (this.preventElementSelection(element)) return
    if (this.wrapperComponents.has(element.id)) {
      this.setSelectedElement(this.wrapperComponents.get(element.id)!)
    }
    if (scrollIntoView) {
      this.selectedElementSubject.value?.el.nativeElement.scrollIntoView({ behavior: 'instant', block: 'center' })
    }
  }

  duplicateElement(element: Brick) {
    if (element && this.wrapperComponents.has(element.id)) {
      this.wrapperComponents.get(element.id)?.duplicateElement()
    }
  }

  deleteElement(element: Brick) {
    if (element) {
      this.wrapperComponents.get(element.id)?.removeElement()
    }
  }

  hasRegisteredComponentOfElement(element: Brick) {
    return this.wrapperComponents.has(element.id)
  }

  hasRegisteredComponent(component: WsEmailElementWrapperComponent) {
    return this.wrapperComponents.has(component.element?.id || '')
  }

  registerComponent(component: WsEmailElementWrapperComponent) {
    if (component.element) {
      this.wrapperComponents.set(component.element?.id, component)
    }
  }
  unregisterComponent(component: WsEmailElementWrapperComponent) {
    if (component.element) {
      this.wrapperComponents.delete(component.element.id)
    }
  }

  setSelectedElement(selectedElement: WsEmailElementWrapperComponent): void {
    if (this.preventElementSelection(selectedElement.element)) return
    const lastSelectedElement = this.selectedElementSubject.value
    if (lastSelectedElement && lastSelectedElement != selectedElement) {
      this.unselectSelectedElement()
    }
    if (lastSelectedElement != selectedElement) {
      selectedElement.addLabelToElement()
      this.element = selectedElement.element
      this.selectedElementSubject.next(selectedElement)
    }
  }

  unselectSelectedElement() {
    if (this.selectedElementSubject.value !== null) {
      this.selectedElementSubject.value.removeLabelFromElement()
    }
    this.selectedElementSubject.next(null)
    this.element = undefined
  }

  updateViewOfAllElements() {
    this.wrapperComponents.forEach((component: WsEmailElementWrapperComponent) => {
      component.triggerElementComponentChangeDetection()
    })
  }

  updateViewOfSelectedElement(updateParent = false, updateChildren = false) {
    this.selectedElementSubject.value?.triggerElementComponentChangeDetection()
    if (updateParent) {
      const parentElement = this.selectedElementSubject.value?.parent
      if (parentElement) {
        this.wrapperComponents.get(parentElement.id)?.triggerElementComponentChangeDetection()
      }
    }
    if (updateChildren) {
      this.selectedElementSubject.value?.element?.getChildren()?.forEach((child) => {
        this.wrapperComponents.get(child.id)?.triggerElementComponentChangeDetection()
      })
    }
  }

  /**
   * Prevents deselect of  selected text/button element when marking text
   * and element to select is a section
   */
  preventElementSelection(elementToSelect?: Brick) {
    const selection = window.getSelection()
    return (
      (elementToSelect?.type || '') === 'Section' &&
      ['Text', 'Button'].includes(this.selectedElementSubject?.value?.element?.type || '') &&
      (selection?.toString() || '').length > 0
    )
  }
}
