{
  "background-color": "",
  "background-position": "top center",
  "background-position-x": "",
  "background-position-y": "",
  "background-repeat": "repeat",
  "background-size": "auto",
  "background-url": "",
  "border": "",
  "border-bottom": "",
  "border-left": "",
  "border-radius": "",
  "border-right": "",
  "border-top": "",
  "css-class": "",
  "direction": "ltr",
  "full-width": "",
  "padding": "20px 0",
  "padding-bottom": "",
  "padding-left": "",
  "padding-right": "",
  "padding-top": "",
  "text-align": "center"
}
