import { Component, OnInit } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { SelectOption, WsNotificationsService } from '@ws-core/core-library'
import { first } from 'rxjs'
import { EmailService } from '../../../services/email.service'
import { Template } from '../../../data/models/template'
import { WsEmailDeleteTemplateDialogComponent } from '../../dialogs/ws-email-delete-template-dialog/ws-email-delete-template-dialog.component'
import { WsEmailCreateTemplateDialogComponent } from '../../dialogs/ws-email-create-template-dialog/ws-email-create-template-dialog.component'

@Component({
  selector: 'app-ws-email-admin',
  templateUrl: './ws-email-admin.component.html',
  styleUrl: './ws-email-admin.component.scss'
})
export class WsEmailAdminComponent implements OnInit {
  constructor(
    private emailService: EmailService,
    public dialog: MatDialog,
    private router: Router,
    private wsNotificationsService: WsNotificationsService
  ) {}

  ngOnInit() {
    this.loadAllTemplates()
  }

  templatesOptions: SelectOption[] = []

  deleteTemplate(templateId: number | null): void {
    if (templateId != null) {
      this.emailService
        .deleteTemplate(templateId)
        .pipe(first())
        .subscribe({
          next: (response: any) => {
            this.loadAllTemplates()
            this.handleResponse(200)
          },
          error: (err: any) => {
            this.handleResponse(500)
          }
        })
    }
  }

  openDeleteTemplateDialog(templateToDeleteId: number): void {
    const deleteTemplateDialogConfig = new MatDialogConfig()

    deleteTemplateDialogConfig.disableClose = true
    deleteTemplateDialogConfig.autoFocus = true
    deleteTemplateDialogConfig.maxWidth = '70vw'
    deleteTemplateDialogConfig.maxHeight = '90vh'
    deleteTemplateDialogConfig.data = this.templatesOptions.find(
      (templateOption) => templateOption.value === templateToDeleteId
    )?.label

    const deleteTemplateDialogRef = this.dialog.open(WsEmailDeleteTemplateDialogComponent, deleteTemplateDialogConfig)

    deleteTemplateDialogRef.afterClosed().subscribe((deleteTemplate) => {
      if (deleteTemplate) {
        this.deleteTemplate(templateToDeleteId)
        this.loadAllTemplates()
      }
    })
  }

  openCreateTemplateDialog(templateToDuplicateId?: number): void {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.disableClose = false
    dialogConfig.autoFocus = true
    dialogConfig.width = '400px'
    dialogConfig.maxHeight = '50vh'
    if (templateToDuplicateId) {
      dialogConfig.data = templateToDuplicateId
    }
    const dialogRef = this.dialog.open(WsEmailCreateTemplateDialogComponent, dialogConfig)

    dialogRef.afterClosed().subscribe((newTemplate: Template) => {
      this.templatesOptions = [this.templateToOptions([newTemplate])[0], ...this.templatesOptions]
      this.router.navigate(['/editor/template', newTemplate.id])
    })
  }

  loadAllTemplates(): void {
    this.emailService
      .loadAllTemplates()
      .pipe(first())
      .subscribe(
        (templates: Template[]) => {
          this.templatesOptions = this.templateToOptions(templates)
        },
        (err: any) => {
          // const errorMessage: Message = { severity: 'error', detail: this.translate.instant('template.loadTemplatesError') }
          // this.loadingTemplatesErrorMsg = [errorMessage]
        }
      )
  }

  public templateToOptions(templates: Template[]): SelectOption[] {
    return templates
      .map((template: Template) => {
        return { label: template.name, value: template.wsEmailTemplateId }
      })
      .sort((a: SelectOption, b: SelectOption) => a.label.localeCompare(b.label))
  }

  private handleResponse(code: number) {
    console.log('HANDLE RESPONSE | CODE: ', code)
  }
}
