<mat-accordion>
  @for (section of template.getChildren(); track section.id; let idx1 = $index) {
    <mat-expansion-panel [togglePosition]="'before'">
      <mat-expansion-panel-header
        class="px-4 flex flex-row items-stretch justify-between text-sm cursor-pointer hover:bg-gray-100 transition-all duration-200 ease-in-out"
      >
        <div
          (click)="onLayerClick($event, section)"
          class="flex items-center break-words truncate hover:text-clip flex-grow"
        >
          @if (section.name) {
            {{ section.name }}
          } @else {
            {{ section.type }}-{{ idx1 }}
          }
        </div>
        <div (click)="$event.stopPropagation()" class="flex items-center">
          <span
            class="pr-2 flex items-center"
            matTooltip="{{ 'template.element.layer' | translate }}"
            matTooltipPosition="below"
          >
            1
          </span>
          <button
            *ngIf="isVisible(section)"
            (click)="setInvisible(section)"
            mat-icon-button
            class="px-1"
            matTooltip="{{ 'template.element.hide' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>visibility</mat-icon>
          </button>
          <button
            *ngIf="!isVisible(section)"
            (click)="setVisible(section)"
            mat-icon-button
            class="px-1"
            matTooltip="{{ 'template.element.show' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>visibility_off</mat-icon>
          </button>
          <button
            (click)="duplicateElement(section)"
            mat-icon-button
            class="px-0"
            matTooltip="{{ 'template.element.copy' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>content_copy</mat-icon>
          </button>
          <button
            (click)="deleteElement(section)"
            mat-icon-button
            class="px-1"
            matTooltip="{{ 'template.element.delete' | translate }}"
            matTooltipPosition="below"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </mat-expansion-panel-header>
      <mat-accordion>
        @for (column of section.getChildren(); track column.id; let idx2 = $index) {
          <mat-expansion-panel [togglePosition]="'before'">
            <mat-expansion-panel-header
              class="flex flex-row items-stretch justify-between text-sm cursor-pointer hover:bg-gray-100 transition-all duration-200 ease-in-out"
            >
              <div
                (click)="onLayerClick($event, column)"
                class="pl-6 flex items-center break-words truncate hover:text-clip flex-grow"
              >
                @if (column.name) {
                  {{ column.name }}
                } @else {
                  {{ column.type }}-{{ idx2 }}
                }
              </div>
              <div (click)="$event.stopPropagation()" class="flex items-center">
                <span
                  class="pr-2 flex items-center"
                  matTooltip="{{ 'template.element.layer' | translate }}"
                  matTooltipPosition="below"
                >
                  2
                </span>
                <button
                  *ngIf="isVisible(column)"
                  (click)="setInvisible(column)"
                  mat-icon-button
                  class="px-1"
                  matTooltip="{{ 'template.element.hide' | translate }}"
                  matTooltipPosition="below"
                >
                  <mat-icon>visibility</mat-icon>
                </button>
                <button
                  *ngIf="!isVisible(column)"
                  (click)="setVisible(column)"
                  mat-icon-button
                  class="px-1"
                  matTooltip="{{ 'template.element.show' | translate }}"
                  matTooltipPosition="below"
                >
                  <mat-icon>visibility_off</mat-icon>
                </button>
                <button
                  (click)="duplicateElement(column)"
                  mat-icon-button
                  class="px-0"
                  matTooltip="{{ 'template.element.copy' | translate }}"
                  matTooltipPosition="below"
                >
                  <mat-icon>content_copy</mat-icon>
                </button>
                <button
                  (click)="deleteElement(column)"
                  mat-icon-button
                  class="px-1"
                  matTooltip="{{ 'template.element.delete' | translate }}"
                  matTooltipPosition="below"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </mat-expansion-panel-header>
            <mat-accordion>
              @for (element of column.getChildren(); track element.id; let idx3 = $index) {
                <div class="pl-12 ws-email-layer-last-element">
                  <div
                    class="pl-4 flex flex-row items-stretch justify-between text-sm cursor-pointer hover:bg-gray-100 transition-all duration-200 ease-in-out"
                  >
                    <div
                      (click)="onLayerClick($event, element)"
                      class="flex items-center break-words truncate hover:text-clip flex-grow"
                    >
                      @if (element.name) {
                        {{ element.name }}
                      } @else {
                        {{ element.type }}-{{ idx3 }}
                      }
                    </div>
                    <div (click)="$event.stopPropagation()" class="pr-6 flex items-center">
                      <span
                        class="pr-2 flex items-center"
                        matTooltip="{{ 'template.element.layer' | translate }}"
                        matTooltipPosition="below"
                      >
                        3
                      </span>
                      <button
                        *ngIf="isVisible(element)"
                        (click)="setInvisible(element)"
                        mat-icon-button
                        class="px-1"
                        matTooltip="{{ 'template.element.hide' | translate }}"
                        matTooltipPosition="below"
                      >
                        <mat-icon>visibility</mat-icon>
                      </button>
                      <button
                        *ngIf="!isVisible(element)"
                        (click)="setVisible(element)"
                        mat-icon-button
                        class="px-1"
                        matTooltip="{{ 'template.element.show' | translate }}"
                        matTooltipPosition="below"
                      >
                        <mat-icon>visibility_off</mat-icon>
                      </button>
                      <button
                        (click)="duplicateElement(element)"
                        mat-icon-button
                        class="px-0"
                        matTooltip="{{ 'template.element.copy' | translate }}"
                        matTooltipPosition="below"
                      >
                        <mat-icon>content_copy</mat-icon>
                      </button>
                      <button
                        (click)="deleteElement(element)"
                        mat-icon-button
                        class="pr-1"
                        matTooltip="{{ 'template.element.delete' | translate }}"
                        matTooltipPosition="below"
                      >
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <mat-accordion>
                  @if (element.type === 'Social') {
                    @for (element of element.getChildren(); track element.id; let idx4 = $index) {
                      <div class="pl-16 ws-email-layer-last-element">
                        <div
                          class="pl-4 flex flex-row items-stretch justify-between text-sm cursor-pointer hover:bg-gray-100 transition-all duration-200 ease-in-out"
                        >
                          <div
                            (click)="onLayerClick($event, element)"
                            class="flex items-center break-words truncate hover:text-clip flex-grow"
                          >
                            @if (element.name) {
                              {{ element.name }}
                            } @else {
                              {{ element.type }}-{{ idx4 }}
                            }
                          </div>
                          <div (click)="$event.stopPropagation()" class="pr-6 flex items-center">
                            <span
                              class="pr-2 flex items-center"
                              matTooltip="{{ 'template.element.layer' | translate }}"
                              matTooltipPosition="below"
                            >
                              4
                            </span>
                            <button
                              *ngIf="isVisible(element)"
                              (click)="setInvisible(element)"
                              mat-icon-button
                              class="px-1"
                              matTooltip="{{ 'template.element.hide' | translate }}"
                              matTooltipPosition="below"
                            >
                              <mat-icon>visibility</mat-icon>
                            </button>
                            <button
                              *ngIf="!isVisible(element)"
                              (click)="setVisible(element)"
                              mat-icon-button
                              class="px-1"
                              matTooltip="{{ 'template.element.show' | translate }}"
                              matTooltipPosition="below"
                            >
                              <mat-icon>visibility_off</mat-icon>
                            </button>
                            <button
                              (click)="duplicateElement(element)"
                              mat-icon-button
                              class="px-0"
                              matTooltip="{{ 'template.element.copy' | translate }}"
                              matTooltipPosition="below"
                            >
                              <mat-icon>content_copy</mat-icon>
                            </button>
                            <button
                              (click)="deleteElement(element)"
                              mat-icon-button
                              class="pr-1"
                              matTooltip="{{ 'template.element.delete' | translate }}"
                              matTooltipPosition="below"
                            >
                              <mat-icon>delete</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    }
                  }
                </mat-accordion>
              }
            </mat-accordion>
          </mat-expansion-panel>
        }
      </mat-accordion>
    </mat-expansion-panel>
  }
</mat-accordion>
