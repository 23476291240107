import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-ws-email-delete-template-dialog',
  templateUrl: './ws-email-delete-template-dialog.component.html',
  styleUrl: './ws-email-delete-template-dialog.component.scss'
})
export class WsEmailDeleteTemplateDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<WsEmailDeleteTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}

  save() {
    this.dialogRef.close(true)
  }

  close() {
    this.dialogRef.close(false)
  }
}
