import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, Validators } from '@angular/forms'

@Component({
  selector: 'app-ws-email-ai-dialog',
  templateUrl: './ws-email-ai-dialog.component.html',
  styleUrl: './ws-email-ai-dialog.component.scss'
})
export class WsEmailAiDialogComponent implements OnInit {
  testDataFormField = new FormControl('', [Validators.required])

  constructor(
    private dialogRef: MatDialogRef<WsEmailAiDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.testDataFormField.setValue(data)
  }

  ngOnInit() {
    this.testDataFormField.valueChanges.subscribe((value) => {
      if (value) {
        this.dataChanged(value)
      }
    })
  }

  dataChanged(event: string) {
    this.data = event
    // try {
    //   this.data = JSON.parse(event)
    // } catch (error) {
    //   this.dataNotParseable()
    // }
  }

  dataNotParseable() {
    console.log('parsing failed')
    this.testDataFormField.setErrors({ json: true })
  }

  save() {
    this.dialogRef.close(this.data)
  }

  close() {
    this.dialogRef.close()
  }
}
