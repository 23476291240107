import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'
import { Brick } from '../../../data/models/brick'
import { AsyncValidatorFn, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { Column } from '../../../data/models/column'
import { SelectOption } from '@ws-core/core-library'
import { EditorStateService } from '../../../services/util/editor-state.service'
import { fontOptions } from '../ws-email-text-settings/ws-email-text-settings.component'
import { debounceTime, Subscription } from 'rxjs'
import { SocialElement, SocialNetwork, SocialNetworks } from '../../../data/models/social-element'

@Component({
  selector: 'app-ws-email-element-settings',
  templateUrl: './ws-email-element-settings.component.html',
  styleUrl: './ws-email-element-settings.component.scss'
})
export class WsEmailElementSettingsComponent implements OnChanges, OnDestroy {
  @Input() selectedElement?: Brick
  @Output() selectedElementPropertyChange = new EventEmitter()

  selectedSubElementIndex = 0
  timeoutPropertyChange?: number
  formSubscription: Subscription | undefined
  elementSettingsForm: FormGroup = new FormGroup({})
  formIsInitialized = false
  fontOptions = fontOptions
  padding = { paddingTop: '0', paddingRight: '0', paddingBottom: '0', paddingLeft: '0' }
  innerPadding = { top: '0', right: '0', bottom: '0', left: '0' }
  border = { width: '', style: '', color: '' }
  borderStyleOptions: SelectOption[] = [
    { label: 'template.element.dashed', value: 'dashed' },
    { label: 'template.element.dotted', value: 'dotted' },
    { label: 'template.element.solid', value: 'solid' }
  ]

  socialPlatform = 'facebook'
  socialPlatformOptions: SelectOption[] = [
    { label: 'Facebook', value: 'facebook-noshare' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Youtube', value: 'youtube' },
    { label: 'Vimeo', value: 'vimeo' },
    { label: 'Xing', value: 'xing-noshare' },
    { label: 'Linkedin', value: 'linkedin-noshare' },
    { label: 'Pinterest', value: 'pinterest-noshare' },
    { label: 'X', value: 'x-noshare' },
    { label: 'Custom', value: 'custom' }
  ]

  widthSuffix: string = 'px'

  urlValidator: ValidatorFn = Validators.pattern(/^(https:\/\/.*|\$\{[^{}]*})$/)
  altValidator: ValidatorFn = Validators.pattern(/^[a-zA-Z0-9\s-]*$/)
  colorValidator: ValidatorFn = Validators.pattern(/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$|^\$\{[a-zA-Z_][a-zA-Z0-9_-]*}$/)

  @Input() isSubElement: boolean = false
  showSelectedElement = true
  showSelectedSubElement = true

  onWidthSuffixChange() {
    this.widthSuffix = this.widthSuffix === 'px' ? '%' : 'px'
    this.elementSettingsForm.patchValue({ width: this.elementSettingsForm.get('width')?.value })
    this.elementSettingsForm.patchValue({ widthSuffix: this.widthSuffix !== 'px' })
  }

  setWidthSuffix(defaultSuffix: string) {
    this.widthSuffix = this.getSizeSuffix('width', defaultSuffix)
  }

  private getSizeSuffix(property: string, defaultSuffix: string): string {
    const value = this.selectedElement?.styles[property]

    if (value) {
      const match = value.toString().match(/(\d+(\.\d+)?)(px|%)\s*$/)
      return match?.[3] || defaultSuffix
    } else {
      return defaultSuffix
    }
  }

  constructor(private editorStateService: EditorStateService) {}

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['selectedElement'] &&
      changes['selectedElement'].currentValue !== changes['selectedElement'].previousValue
    ) {
      if (this.formSubscription) {
        this.formSubscription.unsubscribe()
      }
      this.formIsInitialized = false
      this.initElementSettingsForm()
    }
  }

  initElementSettingsForm() {
    this.setPadding()
    if (this.selectedElement) {
      switch (this.selectedElement.type) {
        case 'Section':
          this.elementSettingsForm = this.createFormGroupForSection()
          break
        case 'Image':
          this.elementSettingsForm = this.createFormGroupForImage()
          break
        case 'Text':
          this.elementSettingsForm = this.createFormGroupForText()
          break
        case 'Column':
          this.elementSettingsForm = this.createFormGroupForColumn()
          break
        case 'Button':
          this.elementSettingsForm = this.createFormGroupForButton()
          break
        case 'Divider':
          this.elementSettingsForm = this.createFormGroupForDivider()
          break
        case 'Spacer':
          this.elementSettingsForm = this.createFormGroupForSpacer()
          break
        case 'Social':
          this.elementSettingsForm = this.createFormGroupForSocial()
          break
        case 'SocialElement':
          this.elementSettingsForm = this.createFormGroupForSocialElement()
          break
        default:
          return
      }
    }
    this.formSubscription = this.elementSettingsForm.valueChanges.pipe(debounceTime(200)).subscribe((value) => {
      Object.keys(value).forEach((field) => {
        if (this.elementSettingsForm.get(field)?.errors || this.selectedElement == undefined) return
        switch (field) {
          case 'unitToggle':
            break
          case 'inner-padding-top':
          case 'inner-padding-right':
          case 'inner-padding-bottom':
          case 'inner-padding-left':
            if (
              value['inner-padding-top'] === null ||
              value['inner-padding-right'] === null ||
              value['inner-padding-bottom'] === null ||
              value['inner-padding-left'] === null
            ) {
              return
            }
            this.selectedElement.styles['inner-padding'] =
              `${value['inner-padding-top']}px ${value['inner-padding-right']}px ${value['inner-padding-bottom']}px ${value['inner-padding-left']}px`
            break
          case 'padding-top':
          case 'padding-right':
          case 'padding-bottom':
          case 'padding-left': {
            if (
              value['padding-top'] === null ||
              value['padding-right'] === null ||
              value['padding-bottom'] === null ||
              value['padding-left'] === null
            ) {
              return
            }
            const paddingTop = this.selectedElement.styles['padding-top']
            if (paddingTop !== undefined && paddingTop !== null && paddingTop !== '') {
              this.selectedElement.styles['padding-top'] = value['padding-top'] + 'px'
            }
            const paddingBottom = this.selectedElement.styles['padding-bottom']
            if (paddingBottom !== undefined && paddingBottom !== null && paddingBottom !== '') {
              this.selectedElement.styles['padding-bottom'] = value['padding-bottom'] + 'px'
            }
            const paddingLeft = this.selectedElement.styles['padding-left']
            if (paddingLeft !== undefined && paddingLeft !== null && paddingLeft !== '') {
              this.selectedElement.styles['padding-left'] = value['padding-left'] + 'px'
            }
            const paddingRight = this.selectedElement.styles['padding-right']
            if (paddingRight !== undefined && paddingRight !== null && paddingRight !== '') {
              this.selectedElement.styles['padding-right'] = value['padding-right'] + 'px'
            }
            this.selectedElement.styles['padding'] =
              `${value['padding-top']}px ${value['padding-right']}px ${value['padding-bottom']}px ${value['padding-left']}px`
            break
          }
          case 'font-size':
            this.selectedElement.styles[field] = value[field] + 'px'
            break
          case 'grouped-columns':
            if (!this.selectedElement.styles['grouped-columns']) {
              delete this.selectedElement.styles['grouped-columns']
            }
            break
          case 'columns':
          case 'full-width':
            break
          case 'width':
            if (this.selectedElement.type === 'Image') {
              const maxWidth = 600
              const widthPercentage = value['width']
              if (widthPercentage !== null && widthPercentage !== undefined) {
                if (this.elementSettingsForm.get('height')?.value) {
                  this.elementSettingsForm.controls['height'].setValue('')
                  delete this.selectedElement.styles['height']
                }
                this.selectedElement.styles[field] = ((widthPercentage / 100) * maxWidth).toFixed() + 'px'
              }
              break
            }
            if (value[field] === '' || value[field] === null || value[field] === 'auto') {
              delete this.selectedElement.styles[field]
              break
            }
            this.selectedElement.styles[field] = value[field] + this.widthSuffix
            break
          case 'border-radius':
            if (this.selectedElement.type === 'Social') {
              this.selectedElement.getChildren().forEach((child) => {
                if (!value[field]) {
                  child.styles[field] = 0
                } else {
                  child.styles[field] = value[field] + 'px'
                }
              })
            }
            if (!value[field]) {
              this.selectedElement.styles[field] = 0
              break
            }
            this.selectedElement.styles[field] = value[field] + 'px'
            break
          case 'height':
            if (!value[field]) {
              delete this.selectedElement.styles[field]
              break
            }
            this.selectedElement.styles[field] = value[field] + 'px'
            break
          case 'href':
            if (value[field] === '' || value[field] === 'null') {
              delete this.selectedElement.styles[field]
              break
            }
            this.selectedElement.styles[field] = value[field]
            break
          case 'hide-on-mobile':
            break
          case 'border-width':
          case 'border-color':
          case 'border-style':
            if (this.selectedElement.type === 'Divider') {
              if (field === 'border-width') {
                this.selectedElement.styles[field] = value[field] + 'px'
                break
              }
              this.selectedElement.styles[field] = value[field]
              break
            }
            if (!value['border-width'] || !value['border-style'] || !value['border-color']) {
              this.resetBorder()
              break
            }
            this.selectedElement.styles['border'] =
              `${value['border-width']}px ${value['border-style']} ${value['border-color']}`
            break
          case 'name': {
            //change icon
            const name = this.selectedElement.styles['name'].toString()
            if (name === value[field]) {
              //no change
              return
            }
            this.selectedElement.styles['name'] = value[field]
            const networks: SocialNetworks = (this.selectedElement as SocialElement).defaultSocialNetworks
            const network: SocialNetwork | undefined =
              networks[value[field].split('-')[0].toLowerCase() as keyof SocialNetworks]
            if (!network) return
            this.elementSettingsForm.patchValue({
              'background-color': network['background-color'],
              src: network.src,
              href: network['base-url'],
              alt: network.alt,
              title: network.alt
            })
            break
          }
          case 'icon-size': {
            if (this.selectedElement.type === 'Social') {
              this.selectedElement.getChildren().forEach((child) => {
                child.styles[field] = value[field] + 'px'
                child.styles['icon-height'] = value[field] + 'px'
              })
            }
            this.selectedElement.styles[field] = value[field] + 'px'
            break
          }
          default:
            if (value[field] === '' || value[field] === null || value[field] === undefined) {
              delete this.selectedElement.styles[field]
              break
            }
            this.selectedElement.styles[field] = value[field]
        }
      })
      this.updateStyleOfSelectedElement(true)
      clearTimeout(this.timeoutPropertyChange)
      this.timeoutPropertyChange = setTimeout(() => {
        this.selectedElementPropertyChange.emit()
      }, 500)
    })

    this.formIsInitialized = true
  }

  addEmailColumn() {
    if (this.selectedElement?.getChildren().length === 4) return
    this.selectedElement!.add(new Column({}))
    this.elementSettingsForm.patchValue({ columns: this.selectedElement?.getChildren().length })
  }
  removeEmailColumn() {
    this.selectedElement?.getChildren().pop()
    this.elementSettingsForm.patchValue({ columns: this.selectedElement?.getChildren().length })
  }

  private createFormGroupForSection() {
    //outlook does not consider spaces when background-url is set - so background url is removed

    const formControlFields = this.createFormControlsForElement([
      // { name: 'background-url', validators: [this.urlValidator] },
      { name: 'columns' },
      { name: 'background-color', validators: [this.colorValidator] },
      { name: 'padding-top', defaultValue: this.padding.paddingTop },
      { name: 'padding-right', defaultValue: this.padding.paddingRight },
      { name: 'padding-bottom', defaultValue: this.padding.paddingBottom },
      { name: 'padding-left', defaultValue: this.padding.paddingLeft },
      { name: 'grouped-columns' },
      { name: 'full-width' }
    ])
    formControlFields['columns'] = new FormControl(this.selectedElement?.getVisibleChildren().length)
    return new FormGroup(formControlFields)
  }

  private createFormGroupForColumn() {
    this.setWidthSuffix('%')
    this.setBorder()
    const formControlFields = this.createFormControlsForElement([
      // { name: 'vertical-align' },
      { name: 'width' },
      // { name: 'padding-top', defaultValue: this.padding.paddingTop },
      // { name: 'padding-right', defaultValue: this.padding.paddingRight },
      // { name: 'padding-bottom', defaultValue: this.padding.paddingBottom },
      // { name: 'padding-left', defaultValue: this.padding.paddingLeft },
      { name: 'border-width', defaultValue: this.border.width },
      { name: 'border-color', defaultValue: this.border.color, validators: [this.colorValidator] },
      { name: 'border-style', defaultValue: this.border.style },
      { name: 'background-color', validators: [this.colorValidator] },
      { name: 'widthSuffix' }
    ])
    return new FormGroup(formControlFields)
  }

  private createFormGroupForText() {
    const formControlFields = this.createFormControlsForElement([
      { name: 'padding-top', defaultValue: this.padding.paddingTop },
      { name: 'padding-right', defaultValue: this.padding.paddingRight },
      { name: 'padding-bottom', defaultValue: this.padding.paddingBottom },
      { name: 'padding-left', defaultValue: this.padding.paddingLeft }
    ])
    return new FormGroup(formControlFields)
  }

  private createFormGroupForImage() {
    this.widthSuffix = 'px'

    let widthPercentage
    if (this.selectedElement?.styles['width']) {
      widthPercentage = (Number(this.selectedElement?.styles['width']?.toString().replace('px', '').trim()) / 600) * 100
    }

    const formControlFields = this.createFormControlsForElement([
      { name: 'align' },
      // { name: 'vertical-align' },
      { name: 'width' },
      { name: 'height' },
      { name: 'src', validators: [this.urlValidator] },
      { name: 'padding-top', defaultValue: this.padding.paddingTop },
      { name: 'padding-right', defaultValue: this.padding.paddingRight },
      { name: 'padding-bottom', defaultValue: this.padding.paddingBottom },
      { name: 'padding-left', defaultValue: this.padding.paddingLeft },
      { name: 'href', validators: [this.urlValidator] },
      { name: 'alt', validators: [this.altValidator] }
    ])

    const formGroup = new FormGroup(formControlFields)
    if (widthPercentage) {
      formGroup.patchValue({ width: widthPercentage })
    }
    return formGroup
  }

  private createFormGroupForButton() {
    this.setWidthSuffix('px')

    this.setInnerPadding()
    this.setBorder()

    const formControlFields = this.createFormControlsForElement([
      { name: 'align' },
      // { name: 'vertical-align' },
      { name: 'color', validators: [this.colorValidator] },
      { name: 'background-color', validators: [this.colorValidator] },
      { name: 'href', validators: [this.urlValidator] },
      { name: 'padding-top', defaultValue: this.padding.paddingTop },
      { name: 'padding-right', defaultValue: this.padding.paddingRight },
      { name: 'padding-bottom', defaultValue: this.padding.paddingBottom },
      { name: 'padding-left', defaultValue: this.padding.paddingLeft },
      { name: 'width' },
      { name: 'height' },
      { name: 'border-width', defaultValue: this.border.width },
      { name: 'border-color', defaultValue: this.border.color, validators: [this.colorValidator] },
      { name: 'border-style', defaultValue: this.border.style },
      { name: 'border-radius' },
      { name: 'widthSuffix' },
      { name: 'inner-padding-top', defaultValue: this.innerPadding.top },
      { name: 'inner-padding-right', defaultValue: this.innerPadding.right },
      { name: 'inner-padding-bottom', defaultValue: this.innerPadding.bottom },
      { name: 'inner-padding-left', defaultValue: this.innerPadding.left }
    ])
    return new FormGroup(formControlFields)
  }

  private createFormGroupForDivider() {
    this.widthSuffix = '%'
    const formControlFields = this.createFormControlsForElement([
      { name: 'width' },
      { name: 'border-width' },
      { name: 'border-color', validators: [this.colorValidator] },
      { name: 'border-style' },
      { name: 'padding-top', defaultValue: this.padding.paddingTop },
      { name: 'padding-right', defaultValue: this.padding.paddingRight },
      { name: 'padding-bottom', defaultValue: this.padding.paddingBottom },
      { name: 'padding-left', defaultValue: this.padding.paddingLeft }
    ])
    return new FormGroup(formControlFields)
  }

  createFormGroupForSpacer() {
    return new FormGroup(this.createFormControlsForElement([{ name: 'height' }]))
  }

  createFormGroupForSocial() {
    return new FormGroup(
      this.createFormControlsForElement([
        { name: 'icon-size' },
        { name: 'border-radius' },
        { name: 'align' },
        { name: 'padding-top', defaultValue: this.padding.paddingTop },
        { name: 'padding-right', defaultValue: this.padding.paddingRight },
        { name: 'padding-bottom', defaultValue: this.padding.paddingBottom },
        { name: 'padding-left', defaultValue: this.padding.paddingLeft }
      ])
    )
  }

  createFormGroupForSocialElement() {
    this.setNameOfSocialElement()
    return new FormGroup(
      this.createFormControlsForElement([
        { name: 'src' },
        { name: 'alt', validators: [this.altValidator] },
        { name: 'background-color', validators: [this.colorValidator] },
        { name: 'name', defaultValue: 'facebook-noshare' },
        { name: 'href', validators: [this.urlValidator] },
        { name: 'padding-top', defaultValue: this.padding.paddingTop },
        { name: 'padding-right', defaultValue: this.padding.paddingRight },
        { name: 'padding-bottom', defaultValue: this.padding.paddingBottom },
        { name: 'padding-left', defaultValue: this.padding.paddingLeft }
      ])
    )
  }

  createFormControlsForElement(
    fields: {
      name: string
      defaultValue?: string | boolean
      validators?: ValidatorFn | ValidatorFn[] | null
      asyncValidators?: AsyncValidatorFn | AsyncValidatorFn[] | null
    }[]
  ): { [key: string]: FormControl } {
    const formControlFields: { [key: string]: FormControl } = {}

    fields.forEach((field) => {
      if (field.name === 'widthSuffix') {
        formControlFields[field.name] = new FormControl(this.widthSuffix !== 'px')
        return
      }

      const sanitizedValue =
        this.selectedElement?.styles[field.name]
          ?.toString()
          .replace('auto', '')
          .replace('null', '')
          .replace('px', '')
          .replace('%', '')
          .replace('none', '') ||
        (field.defaultValue ?? '')

      formControlFields[field.name] = new FormControl(
        sanitizedValue,
        field.validators || [],
        field.asyncValidators || []
      )
    })

    formControlFields['hide-on-mobile'] = new FormControl(this.selectedElement?.isHiddenMobile())

    return formControlFields
  }

  atLeastOneFieldExists(fieldNames: string[]) {
    return fieldNames.some((fieldName: string) => this.fieldExists(fieldName))
  }

  fieldExists(fieldName: string) {
    return !!this.elementSettingsForm.get(fieldName)
  }

  setGroupColumnsOfSection() {
    if (this.selectedElement?.type === 'Section') {
      this.selectedElement.styles['grouped-columns'] = this.elementSettingsForm.get('grouped-columns')?.value
      if (!this.selectedElement.styles['grouped-columns']) {
        delete this.selectedElement.styles['grouped-columns']
      }
      this.selectedElementPropertyChange.emit()
    }
  }

  showGroupedColumns() {
    if (!this.selectedElement) return false
    const noColumnsIsFull = !this.selectedElement.getChildren().some((column) => column.styles['width'] === '100%')
    return (
      this.fieldExists('grouped-columns') &&
      this.selectedElement?.getChildren().length > 1 &&
      this.selectedElement?.type === 'Section' &&
      noColumnsIsFull
    )
  }

  setFullWithSection() {
    if (this.selectedElement?.type === 'Section') {
      this.elementSettingsForm.get('full-width')?.value.toString() === 'false'
        ? delete this.selectedElement.styles['full-width']
        : (this.selectedElement.styles['full-width'] = 'full-width')
      this.selectedElementPropertyChange.emit()
    }
  }

  paddingExists() {
    return (
      this.fieldExists('padding-top') ||
      this.fieldExists('padding-bottom') ||
      this.fieldExists('padding-left') ||
      this.fieldExists('padding-right')
    )
  }

  innerPaddingExists() {
    return (
      this.fieldExists('inner-padding-top') ||
      this.fieldExists('inner-padding-bottom') ||
      this.fieldExists('inner-padding-left') ||
      this.fieldExists('inner-padding-right')
    )
  }

  hideOnMobile() {
    if (!this.selectedElement) return
    if (this.selectedElement.styles['css-class']?.toString()?.includes('hide-mobile')) {
      this.selectedElement.styles['css-class'] = this.selectedElement.styles['css-class']
        ?.toString()
        .replace('hide-mobile', '')
      return
    }
    if (this.selectedElement.styles['css-class']) {
      this.selectedElement.styles['css-class'] += ' hide-mobile'
      return
    }
    this.selectedElement.styles['css-class'] = 'hide-mobile'
  }

  private setBorder() {
    const borderAttributes = this.selectedElement?.styles['border']?.toString().split(' ')

    if (borderAttributes?.length == 3) {
      this.border.width = borderAttributes[0].replace('px', '')
      this.border.style = borderAttributes[1]
      this.border.color = borderAttributes[2]
      return
    }
    this.border = { width: '', style: '', color: '' }
  }

  resetBorder(resetFormFields = false) {
    delete this.selectedElement?.styles['border']
    if (resetFormFields) {
      this.elementSettingsForm.patchValue({ 'border-width': '', 'border-style': '', 'border-color': '' })
    }
    this.setBorder()
  }

  setNameOfSocialElement() {
    if (!this.selectedElement) return
    this.socialPlatform = this.selectedElement.styles['name']?.toString() || 'facebook'
    this.selectedElement.styles['name'] = this.socialPlatform
  }

  private setInnerPadding() {
    const innerPadding = this.selectedElement?.styles['inner-padding']?.toString().replaceAll('px', '').split(' ') || [
      '10',
      '25',
      '10',
      '25'
    ]

    if (innerPadding?.length === 1) {
      this.innerPadding = {
        top: innerPadding[0],
        right: innerPadding[0],
        bottom: innerPadding[0],
        left: innerPadding[0]
      }
    } else if (innerPadding?.length === 2) {
      this.innerPadding = {
        top: innerPadding[0],
        right: innerPadding[1],
        bottom: innerPadding[0],
        left: innerPadding[1]
      }
    } else if (innerPadding?.length === 3) {
      this.innerPadding = {
        top: innerPadding[0],
        right: innerPadding[1],
        bottom: innerPadding[2],
        left: innerPadding[1]
      }
    } else {
      this.innerPadding = {
        top: innerPadding[0],
        right: innerPadding[1],
        bottom: innerPadding[2],
        left: innerPadding[3]
      }
    }
  }

  private setPadding() {
    this.padding = { paddingTop: '0', paddingRight: '0', paddingBottom: '0', paddingLeft: '0' }
    this.selectedElement?.getPadding()
    if (this.selectedElement?.getPadding()) {
      const selectedElementPadding = this.selectedElement?.getPadding()?.toString().replaceAll('px', '').split(' ') || [
        '0',
        '0',
        '0',
        '0'
      ]
      this.padding = {
        paddingTop: selectedElementPadding[0],
        paddingRight: selectedElementPadding[1],
        paddingBottom: selectedElementPadding[2],
        paddingLeft: selectedElementPadding[3]
      }
    }
  }

  private updateStyleOfSelectedElement(updateParent = false) {
    this.editorStateService.updateViewOfSelectedElement(
      updateParent || this.selectedElement?.type === 'Column',
      this.selectedElement?.type === 'Social'
    )
  }

  updateFormValue(key: string, value: string) {
    this.elementSettingsForm.controls[key].setValue(value)
    this.updateStyleOfSelectedElement()
  }

  reset(property: string) {
    this.elementSettingsForm.patchValue({ [property]: '' })
  }

  resetFields(properties: string[]) {
    properties.forEach((property) => this.reset(property))
  }
}
