import { BrickComposite } from './brickComposite'
import defaultStyles from './columnStyles.json'

export class Column extends BrickComposite {
  constructor(styles: { [key: string]: string | number } = {}) {
    super('Column', '', styles, 'mj-column')
    this.addDefaultStyles(defaultStyles)
  }

  override hasGutter() {
    return ['padding', 'padding-bottom', 'padding-left', 'padding-right', 'padding-top'].some(
      (attr) => !!this.styles[attr]
    )
  }

  override getStyles(testData: { [p: string]: any } = {}): any {
    const tableStyle = {
      'background-color': this.replaceTestData(this.styles['background-color']?.toString() || '', testData),
      border: this.styles['border'],
      // 'border-bottom': this.styles['border-bottom'],
      // 'border-left': this.styles['border-left'],
      // 'border-radius': this.styles['border-radius'],
      // 'border-right': this.styles['border-right'],
      // 'border-top': this.styles['border-top'],
      'vertical-align': this.styles['vertical-align']
    }

    return {
      div: {
        'font-size': '0px',
        'text-align': 'left',
        direction: this.styles['direction'],
        display: 'inline-block',
        'vertical-align': this.styles['vertical-align']
      },
      table: {
        ...(this.hasGutter()
          ? {
              'background-color': this.styles['inner-background-color'],
              border: this.styles['inner-border']
              // 'border-bottom': this.styles['inner-border-bottom'],
              // 'border-left': this.styles['inner-border-left'],
              // 'border-radius': this.styles['inner-border-radius'],
              // 'border-right': this.styles['inner-border-right'],
              // 'border-top': this.styles['inner-border-top']
            }
          : tableStyle)
      },
      tdOutlook: {
        'vertical-align': this.styles['vertical-align']
      },
      gutter: {
        ...tableStyle,
        'vertical-align': this.styles['vertical-align'],
        padding: this.getPadding()
      }
    }
  }
}
