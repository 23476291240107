import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Template } from '../../../data/models/template'
import { EmailService } from '../../../services/email.service'
import { BrickFactoryService } from '../../../services/util/brick-factory.service'
import { Brick } from '../../../data/models/brick'
import { Column } from '../../../data/models/column'
import { WsEmailBlock } from '../../../data/types/ws-email-block'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { WsNotificationsService } from '@ws-core/core-library'
import { Section } from '../../../data/models/section'
import { first } from 'rxjs'
import { WsEmailDeleteEmailBlockDialogComponent } from '../../dialogs/ws-email-delete-email-block-dialog/ws-email-delete-email-block-dialog.component'
import { CdkDragAndDropService } from '../../../services/util/cdk-drag-and-drop.service'

@Component({
  selector: 'app-ws-email-default-blocks',
  templateUrl: './ws-email-default-blocks.component.html',
  styleUrl: './ws-email-default-blocks.component.scss'
})
export class WsEmailDefaultBlocksComponent implements OnInit {
  @Input() template: Template = new Template('')
  @Input() sectionIds!: string[]
  @Input() columnIds: string[] = []

  @Output() templateChange = new EventEmitter()

  defaultBlocks: WsEmailBlock[] = []
  defaultBlocksSectionMap: Map<WsEmailBlock, Section> = new Map<WsEmailBlock, Section>()
  defaultBlocksColumnMap: Map<WsEmailBlock, Column> = new Map<WsEmailBlock, Column>()
  defaultBlocksLastChildMap: Map<WsEmailBlock, Brick> = new Map<WsEmailBlock, Brick>()
  constructor(
    private emailService: EmailService,
    private brickFactoryService: BrickFactoryService,
    public dialog: MatDialog,
    public wsNotificationsService: WsNotificationsService,
    protected dragAndDropService: CdkDragAndDropService
  ) {}

  ngOnInit(): void {
    this.emailService.defaultBlocksObservable.subscribe((blocks) => {
      this.defaultBlocks = blocks
      this.buildDefaultBlocks()
    })
    this.loadDefaultBlocks()
  }

  private loadDefaultBlocks() {
    this.emailService.loadDefaultBricks()
  }

  buildDefaultBlocks() {
    this.buildDefaultBlocksSection()
    this.buildDefaultBlocksColumn()
    this.buildDefaultBlocksLastChild()
  }

  buildDefaultBlocksSection() {
    this.defaultBlocksSectionMap.clear()
    this.defaultBlocks.forEach((block) => {
      if (block.type === 'Section') {
        const sections: Section[] = this.brickFactoryService.createSectionsFromDefaultBrick(block)
        sections.forEach((section: Section) => {
          section.name = block.name
          if (!section.styles['background-color'] && !section.styles['background']) {
            section.styles['background-color'] = '#ffffff'
          }
          this.defaultBlocksSectionMap.set(block, section)
          section.getHtmlWithTestData(this.template.testData, this.template.styles)
        })
      }
    })
  }
  buildDefaultBlocksColumn() {
    this.defaultBlocksColumnMap.clear()
    this.defaultBlocks.forEach((block) => {
      if (block.type === 'Column') {
        const column: Column = this.brickFactoryService.createColumnsFromDefaultBrick(block)
        column.name = block.name
        this.defaultBlocksColumnMap.set(block, column)
        column.getHtmlWithTestData(this.template.testData, this.template.styles)
        //use section for preview - looks better
        const section = new Section()
        section.add(column)
        column.htmlWithTestData = section.getHtmlWithTestData(this.template.testData, this.template.styles)
      }
    })
  }

  buildDefaultBlocksLastChild() {
    this.defaultBlocksLastChildMap.clear()
    this.defaultBlocks.forEach((block) => {
      if (block.type !== 'Section' && block.type !== 'Column') {
        const lastChild = this.brickFactoryService.createLastChildFromDefaultBrick(block)
        lastChild.name = block.name
        this.defaultBlocksLastChildMap.set(block, lastChild)
        lastChild.getHtmlWithTestData(this.template.testData, this.template.styles)
      }
    })
  }

  deleteEmailBlock(emailBlock: WsEmailBlock, brick: Brick) {
    const deleteTemplateDialogConfig = new MatDialogConfig()
    deleteTemplateDialogConfig.disableClose = true
    deleteTemplateDialogConfig.autoFocus = true
    deleteTemplateDialogConfig.maxWidth = '70vw'
    deleteTemplateDialogConfig.maxHeight = '90vh'
    deleteTemplateDialogConfig.autoFocus = 'first-heading'
    deleteTemplateDialogConfig.data = { brick: brick, template: this.template }

    const deleteTemplateDialogRef = this.dialog.open(WsEmailDeleteEmailBlockDialogComponent, deleteTemplateDialogConfig)

    deleteTemplateDialogRef.afterClosed().subscribe((deleteEmailBlock) => {
      if (deleteEmailBlock) {
        this.emailService.deleteEmailBlock(emailBlock.wsEmailBlockId).pipe(first()).subscribe({
          next: this.handleDeleteEmailBlockSuccess,
          error: this.handleDeleteEmailBlockError
        })
      }
    })
  }

  handleDeleteEmailBlockSuccess = () => {
    this.wsNotificationsService.success('emailBlock.deleteSuccess')
    this.loadDefaultBlocks()
  }
  handleDeleteEmailBlockError = () => {
    this.wsNotificationsService.error('emailBlock.deleteError')
  }

  get defaultBlockSectionsMapValues(): Section[] {
    return Array.from(this.defaultBlocksSectionMap.values())
  }
  get defaultBlockColumnsMapValues(): Column[] {
    return Array.from(this.defaultBlocksColumnMap.values())
  }
  get defaultBlockLastChildrenMapValues(): Brick[] {
    return Array.from(this.defaultBlocksLastChildMap.values())
  }

  onDragDropped(emailBlockType: string) {
    this.dragAndDropService.onDragEnded()
    switch (emailBlockType) {
      case 'Section':
        this.buildDefaultBlocksSection()
        break
      case 'Column':
        this.buildDefaultBlocksColumn()
        break
      default:
        this.buildDefaultBlocksLastChild()
    }
    setTimeout(() => this.templateChange.emit(), 200)
  }
}
