import { Brick } from './brick'
import defaultStyles from './textStyles.json'
import mjml2html from 'mjml-browser'

export class Text extends Brick {
  constructor(content = 'new text', styles: { [key: string]: string | number } = {}) {
    super('Text', content, styles, 'mj-text')
    this.addDefaultStyles(defaultStyles)
  }

  isHeading() {
    return this.styles['mj-class'] === 'heading'
  }

  override getMjmlWithGlobalStyles(globalStyles: { [p: string]: any } = {}) {
    const stylesWithoutGlobalStyle = Object.assign({}, this.styles)
    if (
      !this.styles['font-family'] ||
      this.styles['font-family'] === 'undefined' ||
      this.styles['font-family'] === ''
    ) {
      this.styles['font-family'] = globalStyles['font-family']
    }
    if (!this.styles['color'] || this.styles['color'] === 'undefined' || this.styles['color'] === '') {
      this.styles['color'] = globalStyles['color']
    }
    const mjml = this.getMjml()
    this.styles = stylesWithoutGlobalStyle
    return mjml
  }

  override getHtmlWithGlobalStyles(
    globalStyles: { [p: string]: any } = {},
    testData: { [p: string]: any } = {}
  ): string {
    const globalStylesWithReplacedTestData = JSON.parse(JSON.stringify(globalStyles))
    this.replaceTestDataOfStyles(globalStylesWithReplacedTestData, testData)

    const linkColor = globalStylesWithReplacedTestData['link-color']?.toString() || '#0000EE'
    const linkStyles = `<mj-style inline="inline">a span { color: ${linkColor}; text-decoration: none;}
    a {color: ${linkColor}; text-decoration: none;} p { margin: 0; }</mj-style>`

    const cachedStyles = JSON.parse(JSON.stringify(this.styles))

    const colorStyles = this.styles['color']

    if (colorStyles === undefined || colorStyles === '') {
      this.styles['color'] = globalStylesWithReplacedTestData['color']
    }
    const fontFamily = this.styles['font-family']
    if (fontFamily === undefined || fontFamily === '') {
      this.styles['font-family'] = globalStylesWithReplacedTestData['font-family']
    }

    this.replaceTestDataOfStyles(this.styles, testData)

    const componentMjml = `<mjml><mj-head>${linkStyles}</mj-head><mj-body>${this.getMjml()}</mj-body></mjml>`

    if (colorStyles === undefined || colorStyles === '') {
      delete this.styles['color']
    }

    if (fontFamily === undefined || fontFamily === '') {
      delete this.styles['font-family']
    }

    this.styles = cachedStyles

    this.html = mjml2html(componentMjml).html
    return this.html
  }

  override getHtml(): string {
    const componentMjml = `<mjml><mj-head><mj-style></mj-style></mj-head><mj-body>${this.getMjml()}</mj-body></mjml>`
    this.html = mjml2html(componentMjml).html
    return this.html
  }

  override getStyles(testData: { [p: string]: any } = {}): any {
    return {
      text: {
        'font-family': this.styles['font-family'],
        'font-size': this.styles['font-size'],
        'font-style': this.styles['font-style'],
        'font-weight': this.styles['font-weight'],
        'letter-spacing': this.styles['letter-spacing'],
        'line-height': this.styles['line-height'],
        'text-align': this.styles['align'],
        'text-decoration': this.styles['text-decoration'],
        'text-transform': this.styles['text-transform'],
        color: this.replaceTestData(this.styles['color']?.toString() || '', testData),
        height: this.styles['height']
      },
      cssClass: {
        'css-class': this.styles['css-class']
      },
      td: {
        'text-align': this.styles['align'],
        'background-color': this.styles['container-background-color'],
        padding: this.getPadding()
      }
    }
  }
}
