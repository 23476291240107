<div class="mat-mdc-form-field w-full py-2" *ngIf="ngControl">
  <mat-slide-toggle [class.read-only]="readonly" [formControl]="formControl" (ngModelChange)="onFieldChange($event)">
    <span class="ml-2" *ngIf="label">{{ label | translate }}</span>
  </mat-slide-toggle>
  <div class="mat-mdc-form-field-subscript-wrapper mat-mdc-form-field-bottom-align" *ngIf="hasErrors">
    <div class="mat-mdc-form-field-error-wrapper">
      <mat-error>
        {{ validationErrorMessage }}
      </mat-error>
    </div>
  </div>
</div>
