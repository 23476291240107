import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { Brick } from '../../../data/models/brick'
import { CdkDragAndDropService } from '../../../services/util/cdk-drag-and-drop.service'
import { EditorStateService } from '../../../services/util/editor-state.service'
import { Template } from '../../../data/models/template'

@Component({
  selector: 'app-ws-email-element',
  templateUrl: './ws-email-element.component.html',
  styleUrl: './ws-email-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsEmailElementComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('textContainer') textContainer?: ElementRef
  @Input() parent?: Brick
  @Input() element?: Brick
  @Input() template: Template = new Template('')

  @Input() parentElementAndSiblings?: Brick[] = []
  elementAndSiblings: Brick[] = []

  constructor(
    public cdkDragAndDropService: CdkDragAndDropService,
    protected editorStateService: EditorStateService,
    public el: ElementRef,
    protected cd: ChangeDetectorRef
  ) {}

  @Input() dragHandle = true
  @Input() elementIsActive = false

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.el.nativeElement.classList.add('ws-email-element')
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['element']) {
      this.element = changes['element'].currentValue
    }
  }

  elementIsActiveChange(event: boolean) {
    this.elementIsActive = event
  }

  triggerChangeDetection() {
    this.cd.detectChanges()
  }
}
