import defaultStyles from './spacerStyles.json'
import { Brick } from './brick'

export class Spacer extends Brick {
  constructor(styles: { [key: string]: string | number } = {}) {
    super('Spacer', '', styles, 'mj-spacer')
    this.addDefaultStyles(defaultStyles)
  }

  override getStyles(testData: { [p: string]: any } = {}): any {
    return {
      div: {
        height: this.styles['height'],
        'line-height': this.styles['height']
      }
    }
  }
}
