import { BrickComposite } from './brickComposite'
import defaultStyles from './heroStyles.json'

export class Hero extends BrickComposite {
  constructor(styles: { [key: string]: string | number } = {}) {
    super('Hero', '', styles, 'mj-hero')
    this.addDefaultStyles(defaultStyles)
  }

  override getStyles(testData: { [p: string]: any } = {}): any {
    const backgroundRatio = Math.round(
      (parseInt((this.styles['background-height'] || '').toString(), 10) /
        parseInt((this.styles['background-width'] || '').toString(), 10)) *
        100
    )
    const background = this.styles['background-url'] ? `url(${this.styles['background-url']})` : ''

    return {
      div: {
        margin: '0 auto'
        //'max-width': containerWidth,
      },
      table: {
        width: '100%'
      },
      tr: {
        'vertical-align': 'top'
      },
      'content-td': {
        background: this.styles['container-background-color'],
        padding: this.getPadding()
      },
      'td-fluid': {
        width: `0.01%`,
        'padding-bottom': `${backgroundRatio}%`,
        'mso-padding-bottom-alt': '0'
      },
      'outlook-table': {
        //width: containerWidth,
        // width: '100%',
      },
      'outlook-td': {
        'line-height': 0,
        'font-size': 0,
        'mso-line-height-rule': 'exactly'
      },
      'outlook-inner-table': {
        //width: containerWidth,
        // width: '100%',
      },
      'outlook-image': {
        border: '0',
        height: this.styles['background-height'],
        'mso-position-horizontal': 'center',
        position: 'absolute',
        top: 0,
        //width,
        'z-index': '-3'
      },
      'outlook-inner-td': {
        // 'background-color': this.styles['inner-background-color'],
        padding: this.styles['inner-padding'],
        'padding-top': this.styles['inner-padding-top'],
        'padding-left': this.styles['inner-padding-left'],
        'padding-right': this.styles['inner-padding-right'],
        'padding-bottom': this.styles['inner-padding-bottom']
      },
      'inner-table': {
        width: '100%',
        margin: '0px'
      },
      'inner-div': {
        'background-color': this.styles['inner-background-color'],
        float: this.styles['align'],
        margin: '0px auto',
        width: this.styles['width']
      },
      commonAttributes: {
        background: background,
        'background-position': this.styles['background-position'],
        'background-repeat': 'no-repeat',
        'border-radius': this.styles['border-radius'],
        padding: this.getPadding(),
        'vertical-align': this.styles['vertical-align']
      },
      'ccs-class': {
        'css-class': this.styles['css-class']
      }
    }
  }
}
