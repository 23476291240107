<div class="container py-5" [formGroup]="testDeliveryForm">
  <h3>{{ 'template.editor.testDelivery' | translate }}</h3>

  <ws-form-input
    class="my-2"
    label="{{ 'template.editor.recipient' | translate }}"
    formControlName="from"
    placeholder="{{ 'emailAddress' | translate }}"
  ></ws-form-input>

  <div mat-dialog-actions class="flex justify-between">
    <button [disabled]="testDeliveryForm.invalid" (click)="sendTestEmail()" class="px-2" mat-flat-button color="accent">
      {{ 'send' | translate }}
      <mat-icon>send</mat-icon>
    </button>
    <button (click)="close()" mat-button>
      {{ 'template.close' | translate }}
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
