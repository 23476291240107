import { Brick } from './brick'
import defaultStyles from './imageStyles.json'

export class Image extends Brick {
  constructor(styles: { [key: string]: string | number } = {}) {
    super('Image', '', styles, 'mj-image')
    this.addDefaultStyles(defaultStyles)
  }

  // override getContentWidth() {
  //   // const width = this.styles['width']
  //   //   ? parseInt(this.styles['width'].toString(), 10)
  //   //   : Infinity
  //   //
  //   // const {box} = this.getBoxWidths()
  //   //
  //   // return min([box, width])
  //   return '100%'
  // }

  override getStyles(testData: { [p: string]: any } = {}): any {
    const width = this.getContentWidth()
    const fullWidth = this.styles['full-width'] === 'full-width'

    const { parsedWidth, unit } = this.widthParser(width)

    return {
      img: {
        border: this.styles['border'],
        'border-left': this.styles['border-left'],
        'border-right': this.styles['border-right'],
        'border-top': this.styles['border-top'],
        'border-bottom': this.styles['border-bottom'],
        'border-radius': this.styles['border-radius'],
        display: 'block',
        outline: 'none',
        'text-decoration': 'none',
        height: this.styles['height'],
        'max-height': this.styles['max-height'],
        'min-width': fullWidth ? '100%' : null,
        width: this.styles['width'] || '100%',
        'max-width': fullWidth ? '100%' : null,
        'font-size': this.styles['font-size']
      },
      td: {
        width: fullWidth ? null : `${parsedWidth}${unit}`
      },
      table: {
        'min-width': fullWidth ? '100%' : null,
        'max-width': fullWidth ? '100%' : null,
        width: fullWidth ? `${parsedWidth}${unit}` : null,
        'border-collapse': 'collapse',
        'border-spacing': '0px'
      },
      padding: {
        padding: this.styles['padding'],
        'padding-top': this.styles['padding-top'],
        'padding-right': this.styles['padding-right'],
        'padding-bottom': this.styles['padding-bottom'],
        'padding-left': this.styles['padding-left']
      }
    }
  }
}
