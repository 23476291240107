import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core'

@Component({
  selector: 'app-ws-email-text-toolbar',
  templateUrl: './ws-email-text-toolbar.component.html',
  styleUrl: './ws-email-text-toolbar.component.scss'
})
export class WsEmailTextToolbarComponent {
  @Output() linkClicked = new EventEmitter<void>()
  @Output() boldClicked = new EventEmitter<void>()
  @Output() colorClicked = new EventEmitter<void>()
  @Output() alignLeftClicked = new EventEmitter<void>()
  @Output() alignCenterClicked = new EventEmitter<void>()
  @Output() alignRightClicked = new EventEmitter<void>()
  @Output() textFormatChanged = new EventEmitter<void>()
  @ViewChild('colorPicker') colorPicker!: ElementRef

  selectedColor: string = '#000000'

  insertLink() {
    this.linkClicked.emit()
  }

  alignLeft() {
    this.alignLeftClicked.emit()
  }

  alignCenter() {
    this.alignCenterClicked.emit()
  }

  alignRight() {
    this.alignRightClicked.emit()
  }

  // toggleStyle(styleProperty: keyof CSSStyleDeclaration, value: string, useCachedSelection = false) {
  //   let selection = window.getSelection()
  //   if (useCachedSelection && !!this.cachedWindowSelection) {
  //     selection = this.cachedWindowSelection
  //   }
  //   console.log(selection)
  //   console.log(selection?.toString())
  //   console.log(selection?.getRangeAt(0).cloneRange())
  //   if (!selection) return
  //
  //   console.log(selection.anchorOffset)
  //   if (selection?.rangeCount <= 0) {
  //     console.warn('nothing selected')
  //     selection.removeAllRanges()
  //     return
  //   }
  //   const range = selection.getRangeAt(0)
  //   const selectedText = range.toString()
  //   console.log('range', range, 'selectedText:', selectedText)
  //   const commonAncestor = range.commonAncestorContainer
  //   console.log('start', range.startOffset, 'end', range.endOffset)
  //   if (!commonAncestor) return
  //   console.log(commonAncestor?.parentElement)
  //   console.log(commonAncestor)
  //   const hasChild =
  //     commonAncestor?.parentElement?.tagName !== 'DIV' &&
  //     !(commonAncestor as Element)?.classList?.contains('ws-email-text')
  //   if (hasChild) {
  //     const parentElement = commonAncestor.parentElement
  //     if (!parentElement) return
  //
  //     const styleValue = parentElement.style[styleProperty as never]
  //     if (styleValue.includes(value)) {
  //       parentElement.style[styleProperty as never] = ''
  //       if (parentElement.style.cssText === '') {
  //         const textNode = document.createTextNode(selectedText)
  //         parentElement.parentNode?.replaceChild(textNode, parentElement)
  //       }
  //     } else {
  //       parentElement.style[styleProperty as never] = value
  //     }
  //     this.textFormatChanged.emit()
  //     selection.removeAllRanges()
  //     return
  //   }
  //
  //   // Create a new element with the selected style property
  //   const element = document.createElement('span')
  //   element.id = 'ws-email-text-temporary-element'
  //   element.style[styleProperty as never] = value
  //   element.textContent = selectedText
  //
  //   // Replace the selected text with the new element
  //   range.deleteContents()
  //   range.insertNode(element)
  //   this.textFormatChanged.emit()
  //   setTimeout(() => {
  //     this.test()
  //   }, 0)
  // }

  test() {
    const newEl = document.getElementById('ws-email-text-temporary-element')
    const selection = window.getSelection()
    if (!newEl || !selection) {
      console.error('newEl not found')
      return
    }

    // Reselect the modified text
    const newRange = document.createRange()
    newRange.selectNodeContents(newEl)
    selection.removeAllRanges()
    selection.addRange(newRange)
  }

  element: HTMLElement | null = null

  changeColorWithExec() {
    document.execCommand('foreColor', false, this.selectedColor)
    this.textFormatChanged.emit()
  }

  changeBoldWithExec() {
    document.execCommand('bold', false)
    this.textFormatChanged.emit()
  }

  changeColor() {
    this.changeColorWithExec()
    // const userSelection = window.getSelection()
    // if (!userSelection) {
    //   return
    // }
    // const wordRange = this.getWordRange(userSelection)
    // if (wordRange) {
    //   console.log('word range', wordRange)
    //   // Remove inner <span> elements
    //   this.removeInnerSpans(wordRange)
    //   console.log('word range after', wordRange)
    //   const spanElement = document.createElement('span')
    //   spanElement.style.color = this.selectedColor
    //   wordRange.surroundContents(spanElement)
    //   this.restoreSelectionRange(userSelection, wordRange)
    // } else if (userSelection.rangeCount > 0) {
    //   const selectedRange = userSelection.getRangeAt(0)
    //   this.removeInnerSpans(selectedRange)
    //   const spanElement = document.createElement('span')
    //   spanElement.style.color = this.selectedColor
    //   selectedRange.surroundContents(spanElement)
    //   this.restoreSelectionRange(userSelection, selectedRange)
    // }
    this.textFormatChanged.emit()
  }

  getWordRange(selection: Selection): Range | null {
    if (selection.rangeCount === 0) {
      return null
    }

    const range = selection.getRangeAt(0)
    const wordRange = range.cloneRange()

    // Expand the selection to the beginning of the word
    while (
      wordRange.startOffset > 0 &&
      /\S/.test(wordRange.startContainer.textContent?.charAt(wordRange.startOffset - 1) || '')
    ) {
      wordRange.setStart(wordRange.startContainer, wordRange.startOffset - 1)
    }

    // Expand the selection to the end of the word
    while (
      wordRange.endOffset < (wordRange?.endContainer?.textContent?.length || -1) &&
      /\S/.test(wordRange.endContainer.textContent?.charAt(wordRange.endOffset) || '')
    ) {
      wordRange.setEnd(wordRange.endContainer, wordRange.endOffset + 1)
    }

    return wordRange
  }

  restoreSelectionRange(selection: Selection, range: Range) {
    selection.removeAllRanges()
    selection.addRange(range)
  }
  removeInnerSpans(range: Range) {
    // Create a document fragment to hold the cloned contents of the range
    const fragment = range.cloneContents()

    // Iterate over each <span> element within the fragment
    fragment.querySelectorAll('span').forEach((span) => {
      // Unwrap nested <span> elements without discarding text content
      if (span.childNodes.length > 0) {
        const parentNode = span.parentNode
        while (span.firstChild) {
          parentNode?.insertBefore(span.firstChild, span)
        }
        parentNode?.removeChild(span)
      }
    })

    // Clear the original range and insert the modified fragment
    range.deleteContents()
    range.insertNode(fragment)
  }

  toggleBold() {
    this.changeBoldWithExec()
    // const strongElement = document.createElement('strong')
    // const userSelection = window.getSelection()
    // if (!userSelection || (userSelection.rangeCount === 0 && userSelection.toString() !== '')) {
    //   return
    // }
    // const selectedTextRange = userSelection.getRangeAt(0)
    // const parentElement = selectedTextRange.commonAncestorContainer.parentElement
    // if (parentElement && parentElement.tagName.toLowerCase() === 'strong') {
    //   // If parent is already <strong>, remove the surrounding <strong> element
    //   this.removeSurroundingFrom(parentElement)
    // } else {
    //   // If parent is not <strong>, surround the selected text with <strong> element
    //   this.removeNestedStrongTags(selectedTextRange)
    //   selectedTextRange.surroundContents(strongElement)
    // }
    this.textFormatChanged.emit()
  }
  removeNestedStrongTags(range: Range) {
    // Create a document fragment to hold the cloned contents of the range
    const fragment = range.cloneContents()

    // Iterate over each <strong> element within the fragment
    fragment.querySelectorAll('strong').forEach((strong) => {
      // Unwrap nested <strong> elements without discarding text content
      if (strong.childNodes.length > 0) {
        const parentNode = strong.parentNode
        while (strong.firstChild) {
          parentNode?.insertBefore(strong.firstChild, strong)
        }
        parentNode?.removeChild(strong)
      }
    })

    // Clear the original range and insert the modified fragment
    range.deleteContents()
    range.insertNode(fragment)
  }

  removeSurroundingFrom(textElement: HTMLElement) {
    const strongElements = textElement?.parentNode?.querySelectorAll('strong')
    strongElements?.forEach((strongElement) => {
      if (strongElement.contains(textElement)) {
        const textNode = document.createTextNode(textElement?.textContent || '')
        strongElement?.parentNode?.insertBefore(textNode, strongElement)
        strongElement?.parentNode?.removeChild(strongElement)
      }
    })
  }

  cachedWindowSelection: Selection | null = null

  // changeColor(cache = false) {
  //   if (cache) {
  //     this.cachedWindowSelection = window.getSelection()
  //     console.log('cached', this.cachedWindowSelection)
  //     console.log('cached', this.cachedWindowSelection?.toString())
  //     return
  //   }
  //   this.toggleStyle('color', this.selectedColor, !!this.cachedWindowSelection)
  // }

  // changeColor() {
  //   const selection = window.getSelection()
  //   if (!selection) return
  //
  //   const range = selection.getRangeAt(0)
  //   const selectedText = range.toString()
  //
  //   const commonAncestor = range.commonAncestorContainer
  //   console.log()
  //   const hasChild = commonAncestor?.parentElement?.tagName !== 'DIV'
  //   if (hasChild) {
  //     if (!commonAncestor.parentElement) return
  //     commonAncestor.parentElement.style.color = this.selectedColor
  //     return
  //   }
  //
  //   // Create a new <span> element with the selected color
  //   const span = document.createElement('span')
  //   span.style.color = this.selectedColor
  //   span.textContent = selectedText
  //
  //   // Replace the selected text with the new <span> element
  //   range.deleteContents()
  //   range.insertNode(span)
  // }

  addLink() {
    const url = prompt('Enter the URL:')
    if (url) {
      if (url) {
        const selection = window.getSelection()
        if (selection == null || !selection.rangeCount) return

        const range = selection.getRangeAt(0)
        if (range.collapsed) {
          alert('Please select some text to create a link.')
          return
        }

        document.execCommand('createLink', false, url)
        // After creating the link, set the target to _blank
        // Set the target to _blank for the newly created link
        const anchorNode = range.startContainer.parentNode as HTMLAnchorElement
        if (anchorNode && anchorNode.tagName === 'A') {
          anchorNode.target = '_blank'
        }
      }
      this.textFormatChanged.emit()
    }
  }
}
