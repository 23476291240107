<div class="toolbar">
  <button mat-icon-button (click)="addLink()">
    <ws-icon [size]="18">link</ws-icon>
  </button>
  <button mat-icon-button (mousedown)="changeColor()">
    <ws-icon [size]="18">format_color_text</ws-icon>
  </button>
  <input type="color" #colorPicker [(ngModel)]="selectedColor" />
  <button mat-icon-button (mousedown)="toggleBold()">
    <ws-icon [size]="18">format_bold</ws-icon>
  </button>
</div>
