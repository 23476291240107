<div *ngIf="selectedElement" class="ws-email-element-settings" [ngClass]="{ 'p-4': !isSubElement }">
  @if (
    selectedElement.type === 'Section' &&
    selectedElement.getVisibleChildren().length &&
    selectedElement.getVisibleChildren()[0].type === 'Column'
  ) {
    <mat-expansion-panel
      hideToggle
      [expanded]="showSelectedSubElement"
      (opened)="showSelectedSubElement = true"
      (closed)="showSelectedSubElement = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="flex justify-between items-center w-full">
          <h1 class="element-settings-title-big">
            {{ 'element.type.columns' | translate }} {{ 'template.settings.settings' | translate }}
          </h1>
          <ws-icon [ngClass]="{ 'rotate-90': showSelectedSubElement }">chevron_right</ws-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      @if (selectedElement.getVisibleChildren().length > 1) {
        <div class="element-settings__heading-button-container w-full flex items-center pt-2 mb-4">
          @for (column of selectedElement.getVisibleChildren(); track index; let index = $index) {
            <button
              class="w-full"
              [ngClass]="{ 'element-settings__selected-headline': index === selectedSubElementIndex }"
              (click)="selectedSubElementIndex = index"
              mat-button
            >
              {{ 'element.type.column' | translate }} {{ index + 1 }}
            </button>
          }
        </div>
      }
      <app-ws-email-element-settings
        [selectedElement]="selectedElement.getVisibleChildren()[selectedSubElementIndex]"
        [isSubElement]="true"
        (selectedElementPropertyChange)="selectedElementPropertyChange.emit()"
      ></app-ws-email-element-settings>
    </mat-expansion-panel>
  }
  @if (selectedElement.type !== 'Section') {
    <ng-container *ngTemplateOutlet="elementSettings"></ng-container>
  } @else {
    <mat-expansion-panel
      hideToggle
      [expanded]="showSelectedElement"
      (opened)="showSelectedElement = true"
      (closed)="showSelectedElement = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="flex justify-between items-center w-full">
          <h1 class="element-settings-title-big">
            {{ 'element.type.sections' | translate }} {{ 'template.settings.settings' | translate }}
          </h1>
          <ws-icon [ngClass]="{ 'rotate-90': showSelectedElement }">chevron_right</ws-icon>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngTemplateOutlet="elementSettings"></ng-container>
    </mat-expansion-panel>
  }

  <ng-template #elementSettings>
    <form *ngIf="formIsInitialized" [formGroup]="elementSettingsForm">
      @if (fieldExists('name')) {
        <ws-form-select
          style="--ws-form-field-padding-top: 2em"
          label="Icon"
          formControlName="name"
          [options]="socialPlatformOptions"
        ></ws-form-select>
      }
      @if (fieldExists('icon-size')) {
        <div class="mb-4">
          <h1 class="element-settings-title">{{ 'template.element.icons-size' | translate }}</h1>
          <ws-form-input
            style="--ws-form-field-padding-top: 2em"
            [min]="0"
            suffix="px"
            label="{{ 'template.element.size' | translate }}"
            formControlName="icon-size"
            type="number"
          ></ws-form-input>
        </div>
      }
      <div *ngIf="atLeastOneFieldExists(['background-url', 'background-color'])" class="mb-4">
        <div class="flex justify-start items-center">
          <h1 class="element-settings-title">
            {{ 'template.element.background' | translate }}
          </h1>
          @if (selectedElement.styles['background-color'] || selectedElement.styles['background-url']) {
            <ws-icon (click)="resetFields(['background-color', 'background-url'])">close_small</ws-icon>
          }
        </div>
        <ws-form-input
          *ngIf="fieldExists('background-url')"
          label="{{ 'template.element.backgroundImageUrl' | translate }}"
          formControlName="background-url"
          style="--ws-form-field-padding-top: 2em"
        ></ws-form-input>
        @if (fieldExists('background-color')) {
          <div class="flex justify-between">
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              label="{{ 'template.element.backgroundColor' | translate }}"
              formControlName="background-color"
            >
            </ws-form-input>
            <div class="text-center w-full">
              <ws-form-color-picker
                style="--ws-form-field-padding-top: 2em"
                [compact]="true"
                formControlName="background-color"
              >
              </ws-form-color-picker>
            </div>
          </div>
        }
      </div>
      <div *ngIf="fieldExists('alt')" class="mb-4">
        <ws-form-input
          label="{{ 'template.element.imageAltText' | translate }}"
          formControlName="alt"
          style="--ws-form-field-padding-top: 2em"
        ></ws-form-input>
      </div>
      <div *ngIf="fieldExists('full-width')" class="mb-4">
        <h1 class="element-settings-title">{{ 'template.element.size' | translate }}</h1>
        <ws-form-toggle
          label="{{ 'template.element.fullWidth' | translate }}"
          formControlName="full-width"
          (click)="setFullWithSection()"
        ></ws-form-toggle>
      </div>

      <div *ngIf="fieldExists('columns')" class="mb-4 element-columns">
        <h1 class="element-settings-title">{{ 'Spalten' | translate }}</h1>
        <div class="number-input">
          <div class="input-container">
            <button (click)="removeEmailColumn()" class="decrement-button"><ws-icon>remove</ws-icon></button>
            <input type="text" class="input-field" formControlName="columns" readonly />
            <button (click)="addEmailColumn()" class="increment-button"><ws-icon>add</ws-icon></button>
          </div>
        </div>
      </div>
      <div *ngIf="atLeastOneFieldExists(['href', 'src'])" class="mb-4">
        <h1 class="element-settings-title">{{ 'template.element.url' | translate }}</h1>
        <ws-form-input
          *ngIf="
            fieldExists('src') &&
            (selectedElement?.type !== 'SocialElement' || selectedElement.styles?.['name'] === 'custom')
          "
          style="--ws-form-field-padding-top: 2em"
          label="{{ 'template.element.imageUrl' | translate }}"
          formControlName="src"
        ></ws-form-input>
        <ws-form-input
          *ngIf="fieldExists('href')"
          style="--ws-form-field-padding-top: 2em"
          label="{{ 'template.element.url' | translate }}"
          formControlName="href"
        ></ws-form-input>
      </div>
      <div *ngIf="atLeastOneFieldExists(['width', 'height'])" id="element-settings-width" class="mb-4">
        <h1 class="element-settings-title">{{ 'template.element.size' | translate }}</h1>
        <div [ngClass]="{ 'grid-cols-2': !['Image'].includes(selectedElement.type) }" class="grid gap-x-16">
          @if (fieldExists('width')) {
            @if (['Image'].includes(selectedElement.type)) {
              <ws-form-slider [min]="5" label="{{ 'template.element.width' | translate }}" formControlName="width">
              </ws-form-slider>
            } @else {
              <ws-form-input
                [min]="0"
                [max]="widthSuffix === '%' ? 100 : undefined"
                [suffix]="widthSuffix"
                style="--ws-form-field-padding-top: 2em"
                label="{{ 'template.element.width' | translate }}"
                formControlName="width"
                type="number"
              ></ws-form-input>
            }
          }
          @if (fieldExists('height')) {
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              [min]="0"
              suffix="px"
              label="{{ 'template.element.height' | translate }}"
              formControlName="height"
              type="number"
            ></ws-form-input>
          }
        </div>
        <div *ngIf="fieldExists('widthSuffix')" class="flex justify-start items-center col-span-2 mt-2">
          <span>px</span>
          <ws-form-toggle formControlName="widthSuffix" class="mx-2" (click)="onWidthSuffixChange()"> </ws-form-toggle>
          <span>%</span>
          <span class="ml-5 text-sm">{{ 'template.element.widthUnitSelection' | translate }}</span>
        </div>
      </div>

      <div
        *ngIf="atLeastOneFieldExists(['border', 'border-width', 'border-color', 'border-style'])"
        class="ws-email-border-settings mb-4"
      >
        <div class="flex justify-start items-center">
          <h1 class="element-settings-title">{{ 'template.element.border' | translate }}</h1>
          <ws-icon *ngIf="selectedElement?.styles?.['border']" (click)="resetBorder(true)">close_small</ws-icon>
        </div>
        <div class="grid grid-cols-2 gap-x-8">
          <ws-form-input
            *ngIf="fieldExists('border-width')"
            style="--ws-form-field-padding-top: 2em"
            suffix="px"
            label="{{ 'template.element.size' | translate }}"
            formControlName="border-width"
            type="number"
          ></ws-form-input>
          <ws-form-select
            *ngIf="fieldExists('border-style')"
            style="--ws-form-field-padding-top: 2em"
            label="{{ 'template.element.style' | translate }}"
            formControlName="border-style"
            [options]="borderStyleOptions"
          ></ws-form-select>
        </div>
        @if (fieldExists('border-color')) {
          <div class="flex justify-between mb-4">
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              label="{{ 'template.element.borderColor' | translate }}"
              formControlName="border-color"
            >
            </ws-form-input>
            <div class="text-center w-full">
              <ws-form-color-picker
                style="--ws-form-field-padding-top: 2em"
                [compact]="true"
                formControlName="border-color"
              >
              </ws-form-color-picker>
            </div>
          </div>
        }
      </div>

      <div *ngIf="fieldExists('border-radius')" class="mb-4">
        <div>
          <h1 class="element-settings-title">{{ 'template.element.borderRadius' | translate }}</h1>
        </div>
        <ws-form-input
          style="--ws-form-field-padding-top: 2em"
          suffix="px"
          [min]="0"
          label="{{ 'template.element.radius' | translate }}"
          formControlName="border-radius"
          type="number"
        ></ws-form-input>
      </div>

      <div *ngIf="atLeastOneFieldExists(['font-family', 'font-size'])" id="element-text-settings-font" class="mb-4">
        <h1 class="element-settings-title">{{ 'template.element.font' | translate }}</h1>
        <ws-form-select
          *ngIf="fieldExists('font-family')"
          label="{{ 'template.element.fontFamily' | translate }}"
          formControlName="font-family"
          [options]="fontOptions"
        ></ws-form-select>
        <ws-form-input
          *ngIf="fieldExists('font-size')"
          style="--ws-form-field-padding-top: 2em"
          formControlName="font-size"
          type="number"
          suffix="px"
          label="{{ 'template.element.fontSize' | translate }}"
        >
        </ws-form-input>
      </div>

      <div
        *ngIf="fieldExists('align') && !fieldExists('vertical-align')"
        id="element-settings-image-align"
        class="mb-4"
      >
        <h1 class="element-settings-title text-left">{{ 'template.element.alignment' | translate }}</h1>
        <div class="text-center">
          <span class="text-sm text-center font-bold">{{ 'template.element.horizontal' | translate }}</span>

          <div id="element-settings-image-horizontal-align">
            <button
              mat-icon-button
              (click)="updateFormValue('align', 'left')"
              class="ml-2"
              matTooltip="{{ 'template.element.textAlignLeft' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>format_align_left</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="updateFormValue('align', 'center')"
              class="ml-2"
              matTooltip="{{ 'template.element.textAlignCenter' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>format_align_center</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="updateFormValue('align', 'right')"
              class="ml-2"
              matTooltip="{{ 'template.element.textAlignRight' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>format_align_right</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="fieldExists('align') && fieldExists('vertical-align')" id="element-settings-align" class="mb-4">
        <h1 class="element-settings-title">{{ 'template.element.alignment' | translate }}</h1>
        <div class="grid grid-cols-2 gap-x-2 pt-2">
          <span class="text-sm text-center font-bold">{{ 'template.element.horizontal' | translate }}</span>
          <span class="text-sm text-center font-bold">{{ 'template.element.vertical' | translate }}</span>

          <div id="element-settings-horizontal-align">
            <button
              mat-icon-button
              (click)="updateFormValue('align', 'left')"
              class="ml-2"
              matTooltip="{{ 'template.element.textAlignLeft' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>format_align_left</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="updateFormValue('align', 'center')"
              class="ml-2"
              matTooltip="{{ 'template.element.textAlignCenter' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>format_align_center</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="updateFormValue('align', 'right')"
              class="ml-2"
              matTooltip="{{ 'template.element.textAlignRight' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>format_align_right</mat-icon>
            </button>
          </div>
          <div id="element-settings-vertical-align">
            <button
              mat-icon-button
              (click)="updateFormValue('vertical-align', 'top')"
              class="ml-2"
              matTooltip="{{ 'template.element.textAlignTop' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>align_vertical_top</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="updateFormValue('vertical-align', 'middle')"
              class="ml-2"
              matTooltip="{{ 'template.element.textAlignCenter' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>align_vertical_center</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="updateFormValue('vertical-align', 'bottom')"
              class="ml-2"
              matTooltip="{{ 'template.element.textAlignBottom' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>align_vertical_bottom</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div id="element-settings-padding" *ngIf="paddingExists()" class="mb-4">
        <h1 class="element-settings-title">{{ 'template.element.padding' | translate }}</h1>

        <div class="grid grid-cols-2 gap-x-24">
          <div class="pr-4">
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              formControlName="padding-top"
              [min]="0"
              type="number"
              suffix="px"
              label="{{ 'template.element.top' | translate }}"
            >
            </ws-form-input>
          </div>
          <div class="pl-4">
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              formControlName="padding-bottom"
              [min]="0"
              type="number"
              suffix="px"
              label="{{ 'template.element.bottom' | translate }}"
            >
            </ws-form-input>
          </div>

          <div class="pr-4">
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              formControlName="padding-left"
              [min]="0"
              type="number"
              suffix="px"
              label="{{ 'template.element.left' | translate }}"
            >
            </ws-form-input>
          </div>
          <div class="pl-4">
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              formControlName="padding-right"
              [min]="0"
              type="number"
              suffix="px"
              label="{{ 'template.element.right' | translate }}"
            >
            </ws-form-input>
          </div>
        </div>
      </div>

      <div id="element-settings-inner-padding" *ngIf="innerPaddingExists()" class="mb-4">
        <h1 class="element-settings-title">{{ 'template.element.innerPadding' | translate }}</h1>

        <div class="grid grid-cols-2 gap-x-24">
          <div class="pr-4">
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              formControlName="inner-padding-top"
              [min]="0"
              type="number"
              suffix="px"
              label="{{ 'template.element.top' | translate }}"
            >
            </ws-form-input>
          </div>
          <div class="pl-4">
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              formControlName="inner-padding-bottom"
              [min]="0"
              type="number"
              suffix="px"
              label="{{ 'template.element.bottom' | translate }}"
            >
            </ws-form-input>
          </div>
          <div class="pr-4">
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              formControlName="inner-padding-left"
              [min]="0"
              type="number"
              suffix="px"
              label="{{ 'template.element.left' | translate }}"
            >
            </ws-form-input>
          </div>
          <div class="pl-4">
            <ws-form-input
              style="--ws-form-field-padding-top: 2em"
              formControlName="inner-padding-right"
              [min]="0"
              type="number"
              suffix="px"
              label="{{ 'template.element.right' | translate }}"
            >
            </ws-form-input>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <h1 class="element-settings-title">{{ 'template.element.mobile' | translate }}</h1>
        <ws-form-toggle
          *ngIf="showGroupedColumns()"
          label="{{ 'template.element.groupedColumns' | translate }}"
          formControlName="grouped-columns"
          (click)="setGroupColumnsOfSection()"
        ></ws-form-toggle>
        <ws-form-toggle
          label="{{ 'template.element.hideOnMobile' | translate }}"
          formControlName="hide-on-mobile"
          (click)="hideOnMobile()"
        ></ws-form-toggle>
      </div>
      <!--    <br />-->
      <!--    <br />-->
      <!--    Debug:::-->
      <!--    <br />-->
      <!--    &lt;!&ndash;    {{ selectedElement | json }}&ndash;&gt;-->
      <!--    {{ selectedElement.styles | json }}-->
      <!--    {{ selectedElement.id | json }}-->
    </form>
  </ng-template>
</div>
