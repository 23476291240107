import {
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  Output,
  TemplateRef,
  AfterContentInit,
  SimpleChanges,
  OnChanges,
  HostListener
} from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { MatButtonModule } from '@angular/material/button'
import { NgIf, NgTemplateOutlet } from '@angular/common'
import { AbstractPlatformService } from '../../types/module-view/abstract-platform-service'
import { WsButtonComponent } from '../ws-button/ws-button.component'
import { animate, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'ws-sidebar',
  standalone: true,
  templateUrl: './ws-sidebar.component.html',
  imports: [MatIconModule, TranslateModule, MatButtonModule, NgIf, NgTemplateOutlet, WsButtonComponent],
  styleUrl: './ws-sidebar.component.scss',
  animations: [
    trigger('fade', [
      transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate(300, style({ opacity: 0 }))])
    ])
  ]
})
export class WsSidebarComponent implements AfterContentInit, OnChanges {
  @Input() sidebarTitle: string = ''
  /**
   * Overwrite the default behaviour of the sidebar with this property. The sidebar is displayed as bottom bar by default only on mobile screens.
   */
  @Input() displayAsBottomBar?: boolean
  @Input() backdropDismiss = true
  @Input() showSidebar = false

  @ContentChild('sidebarContent') templateRefSidebarContent: TemplateRef<HTMLElement> | null = null
  @ContentChild('footerContent') templateRefSidebarFooter: TemplateRef<HTMLElement> | null = null

  @Output() showSidebarChange = new EventEmitter<boolean>()

  constructor(@Inject('PlatformService') public platformService: AbstractPlatformService) {
    // only set displayAsBottomBar if it is not already given via input
    if (this.displayAsBottomBar === undefined) {
      // the sidebar is displayed as bottom bar by default only on mobile screens
      this.displayAsBottomBar = platformService.isMobile
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showSidebar']) {
      if (this.showSidebar) {
        this.showSidebarChange.emit(this.showSidebar)
      }
    }
  }

  ngAfterContentInit() {
    this.checkTemplateRefsGiven()
  }

  private checkTemplateRefsGiven() {
    if (this.templateRefSidebarContent === null) {
      console.warn(
        'No template reference for sidebar content given. Please provide a template reference with the name "sidebarContent"'
      )
    }
  }

  /**
   * Emits event to close the sidebar
   */
  closeSidebar() {
    this.showSidebarChange.emit(false)
  }

  handleBackdropClick() {
    if (this.backdropDismiss) {
      this.closeSidebar()
    }
  }

  // @HostBinding('class.hidden') get isHidden() {
  //   return !this.showSidebar
  // }

  @HostListener('document:keydown.escape', ['$event']) handleEscapeKey(event: KeyboardEvent) {
    this.closeSidebar()
  }
}
