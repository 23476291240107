<app-ws-email-element-wrapper
  #wrapper
  [element]="element"
  [elementIsActive]="elementIsActive"
  [parent]="parent"
  [dragHandle]="dragHandle"
  [component]="this"
>
  <!--[width]= "parseInt(containerWidth, 10)"-->

  <!--[if mso | IE]>
<table
align="center"
border= "0"
cellpadding= "0"
cellspacing= "0"
role= "presentation"
[ngStyle]= "element?.getStyles()?.['outlook-table']"
width="100%"
>
<tr>
  <td [ngStyle]= "element?.getStyles()?.['outlook-td']">
  <img
    [ngStyle]= "element?.getStyles()?.['outlook-image']"
    [src]="element?.getStyles()?.commonAttributes['background']" alt="hero image"/>
<![endif]-->
  <div
    [align]="element?.styles?.['align']"
    [ngStyle]="element?.getStyles()?.div"
    [class]="element?.styles?.['css-class']"
  >
    <table border="0" cellpadding="0" cellspacing="0" role="presentation" [ngStyle]="element?.getStyles()?.table">
      <tbody>
        <tr [ngStyle]="element?.getStyles()?.tr">
          <!--      ---- Begin Mode ----  -->
          @switch (element?.styles?.['mode']) {
            @case ('fluid-height') {
              <td [ngStyle]="element?.getStyles()?.tdFluid"></td>
              <td [ngStyle]="element?.getStyles()?.commonAttributes">
                <!--      Begin Content-->

                <!--[if mso | IE]>
            <table
              [align]="element?.styles['align']"
            border= "0"
            cellpadding= "0"
            cellspacing= "0"
            [ngStyle]="element?.getStyles()?.['outlook-inner-table']"
            width= "100%"
            >
            <tr>
              <td [ngStyle]="element?.getStyles()?.['outlook-inner-table']">
            <![endif]-->
                <div
                  [align]="element?.styles?.['align']"
                  class="mj-hero-content"
                  [ngStyle]="element?.getStyles()?.['inner-div']"
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    [ngStyle]="element?.getStyles()?.['inner-table']"
                  >
                    <tbody>
                      <tr>
                        <td [ngStyle]="element?.getStyles()?.['inner-td']">
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                            [ngStyle]="element?.getStyles()?.['inner-table']"
                          >
                            <tbody>
                              <!--    ${this.renderChildren(children, {-->
                              <!--    renderer: (component) =>-->
                              <!--    component.constructor.isRawElement()-->
                              <!--    ? component.render()-->
                              <!--    : `-->
                              @for (child of element?.getChildren(); track child.id) {
                                <tr>
                                  <!--                        todo: use child padding-->
                                  <td
                                    [align]="child?.styles?.['align']"
                                    [style.background]="child?.styles?.['container-background-color']"
                                    [class]="child?.styles?.['css-class']"
                                    [ngStyle]="child?.getStyles().td"
                                    style="font-size: 0; word-break: break-word"
                                  >
                                    @switch (child.type) {
                                      @case ('Text') {
                                        <app-ws-email-text [parent]="element" [element]="child"></app-ws-email-text>
                                      }
                                      @case ('Image') {
                                        <app-ws-email-image [parent]="element" [element]="child"></app-ws-email-image>
                                      }
                                    }
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]>
            </td>
            </tr>
            </table>
            <![endif]-->

                <!--      End Content -->
              </td>
              <td [ngStyle]="element?.getStyles()?.tdFluid"></td>
            }
            @case ('fixed-height') {
              <td
                class="fixed-height"
                [ngStyle]="element?.getStyles()?.commonAttributes"
                [height]="element?.styles?.['height']"
              >
                <!--      ${this.renderContent()}-->
                <!--      Begin Content-->

                <!--[if mso | IE]>
            <table
              [align]="element?.styles['align']"
            border= "0"
            cellpadding= "0"
            cellspacing= "0"
            [ngStyle]="element?.getStyles()?.['outlook-inner-table']"
            width= "100%"
            >
            <tr>
              <td [ngStyle]="element?.getStyles()?.['outlook-inner-table']">
            <![endif]-->
                <div
                  [align]="element?.styles?.['align']"
                  class="mj-hero-content"
                  [ngStyle]="element?.getStyles()?.['inner-div']"
                >
                  <table
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    role="presentation"
                    [ngStyle]="element?.getStyles()?.['inner-table']"
                  >
                    <tbody>
                      <tr>
                        <td [ngStyle]="element?.getStyles()?.['inner-td']">
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            role="presentation"
                            [ngStyle]="element?.getStyles()?.['inner-table']"
                          >
                            <tbody>
                              <!--    ${this.renderChildren(children, {-->
                              <!--    renderer: (component) =>-->
                              <!--    component.constructor.isRawElement()-->
                              <!--    ? component.render()-->
                              <!--    : `-->
                              @for (child of element?.getChildren(); track child.id) {
                                <tr>
                                  <!--                        todo: use child padding-->
                                  <td
                                    [align]="child?.styles?.['align']"
                                    [style.background]="child?.styles?.['container-background-color']"
                                    [class]="child?.styles?.['css-class']"
                                    [style.padding]="child?.getPadding()"
                                    style="font-size: 0; word-break: break-word"
                                  >
                                    @switch (child.type) {
                                      @case ('Text') {
                                        <app-ws-email-text [parent]="element" [element]="child"></app-ws-email-text>
                                      }
                                      @case ('Image') {
                                        <app-ws-email-image [parent]="element" [element]="child"></app-ws-email-image>
                                      }
                                      @case ('Button') {
                                        <app-ws-email-button [parent]="element" [element]="child"></app-ws-email-button>
                                      }
                                    }
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--[if mso | IE]>
            </td>
            </tr>
            </table>
            <![endif]-->

                <!--      End Content -->
              </td>
            }
          }
          <!--      ---- END Mode ----  -->
        </tr>
      </tbody>
    </table>
  </div>
  <!--[if mso | IE]>
</td>
</tr>
</table>
<![endif]-->
</app-ws-email-element-wrapper>
