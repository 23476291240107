import { Translations } from '@ws-core/core-library'

export class WsEmailTemplate {
  wsEmailTemplateId = 0
  name: string = ''
  mjml: Translations = { de: '', en: '', nl: '', fr: '', it: '' }
  html: Translations = { de: '', en: '', nl: '', fr: '', it: '' }
  testData: string = '{}'
  styles: string = '{}'

  constructor(
    wsEmailTemplateId: number = 0,
    name: string = '',
    mjml: Translations = { de: '', en: '', nl: '', fr: '', it: '' },
    html: Translations = { de: '', en: '', nl: '', fr: '', it: '' },
    testData: string = '{}',
    styles: string = '{}'
  ) {
    this.wsEmailTemplateId = wsEmailTemplateId
    this.name = name
    this.mjml = mjml
    this.html = html
    this.testData = testData
    this.styles = styles
  }
}
