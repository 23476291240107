import { Component, Inject } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { first } from 'rxjs'
import { EmailService } from '../../../services/email.service'
import { Template } from '../../../data/models/template'
import { WsEmailTemplate } from '../../../data/types/ws-email-template'

@Component({
  selector: 'app-ws-email-create-template-dialog',
  templateUrl: './ws-email-create-template-dialog.component.html',
  styleUrl: './ws-email-create-template-dialog.component.scss'
})
export class WsEmailCreateTemplateDialogComponent {
  templateToDuplicateId?: number
  saving = false

  createTemplateForm = new FormGroup({
    templateName: new FormControl(''),
    size: new FormControl()
  })

  constructor(
    private emailService: EmailService,
    private dialogRef: MatDialogRef<WsEmailCreateTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.templateToDuplicateId = data
  }

  save() {
    if (!this.createTemplateForm.valid || !this.createTemplateForm.value['templateName']) {
      return
    }
    this.saving = true
    if (!this.templateToDuplicateId) {
      this.createNewTemplate(this.createTemplateForm.value['templateName'])
    } else {
      this.duplicateTemplate(this.createTemplateForm.value['templateName'])
    }
  }

  close() {
    this.dialogRef.close()
  }

  createNewTemplate(templateName: string): Template | null | undefined {
    if (templateName == '' || templateName.includes('%')) {
      this.handleResponse(400)
      return null
    }
    const newTemplate = new WsEmailTemplate()
    newTemplate.name = templateName
    this.emailService
      .saveEmailTemplate(newTemplate)
      .pipe(first())
      .subscribe({
        next: (response: Template) => {
          this.dialogRef.close(response)
          this.handleResponse(200)
          this.saving = false
          return response
        },
        error: (err: any) => {
          this.handleResponse(500)
          this.saving = false
        }
      })
    return null
  }

  duplicateTemplate(templateName: string): void {
    if (!this.templateToDuplicateId) {
      return
    }
    this.emailService
      .duplicateTemplate(this.templateToDuplicateId, templateName)
      .pipe(first())
      .subscribe({
        next: (response: WsEmailTemplate) => {
          this.dialogRef.close(response)
          this.handleResponse(200)
          this.saving = false
          return response
        },
        error: (err: any) => {
          this.handleResponse(500)
          this.saving = false
        }
      })
  }

  private handleResponse(code: number) {
    console.log('HANDLE RESPONSE | CODE: ', code)
  }
}
