@if (data.brick) {
  <mat-dialog-content>
    <div class="flex flex-col items-center">
      <span mat-dialog-title class="flex">{{ 'emailBlock.deleteEmailBlockDialog' | translate }}</span>
      {{ data.brick.name }}
      <div
        [ngStyle]="{ width: ['Image', 'Block'].includes(data.brick.type) ? '600px' : '' }"
        [innerHTML]="data.brick.getHtmlWithTestData(data.template.testData) | safeHtml"
      ></div>
    </div>
  </mat-dialog-content>
}
<div mat-dialog-actions class="flex w-full justify-between">
  <button
    mat-icon-button
    class="mat-button"
    (click)="save()"
    matTooltip="{{ 'emailBlock.delete' | translate }}"
    matTooltipPosition="below"
  >
    <mat-icon>check</mat-icon>
  </button>
  <button
    mat-icon-button
    class="mat-button"
    (click)="close()"
    matTooltip="{{ 'template.close' | translate }}"
    matTooltipPosition="below"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
