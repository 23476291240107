export class WsEmailBlock {
  wsEmailBlockId: number = 0
  name: string = ''
  type: string = ''
  mjml: string = '<mjml><mj-body></mj-body></mjml>'
  customBlock = true

  constructor(name: string, type: string, mjml: string, customBlock: boolean) {
    this.name = name
    this.type = type
    this.mjml = mjml
    this.customBlock = customBlock
  }
}
