<h1 mat-dialog-title>
  @if (templateToDuplicateId) {
    {{ 'template.duplicate' | translate }}
  } @else {
    {{ 'template.create' | translate }}
  }
</h1>
<mat-dialog-content [formGroup]="createTemplateForm">
  <ws-form-input label="{{ 'template.name' | translate }}" formControlName="templateName"></ws-form-input>
</mat-dialog-content>

<div mat-dialog-actions class="flex justify-between">
  <button
    [disabled]="saving"
    mat-icon-button
    class="mat-button"
    (click)="save()"
    matTooltip="{{ 'template.save' | translate }}"
    matTooltipPosition="below"
  >
    @if (saving) {
      <ws-icon class="animate-spin h-5 w-5 mr-3">refresh</ws-icon>
    } @else {
      <mat-icon>check</mat-icon>
    }
  </button>
  <button
    mat-icon-button
    class="mat-button"
    (click)="close()"
    matTooltip="{{ 'template.close' | translate }}"
    matTooltipPosition="below"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
