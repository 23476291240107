<div #ref>
  <ws-form-text-editor-menu
    #customEditorMenu
    [editor]="editor"
    [toolbar]="toolbar"
    [showTextColorPicker]="showTextColorPicker"
    [showEmojiPicker]="showEmojiPicker"
  ></ws-form-text-editor-menu>
  <ng-content></ng-content>
</div>
