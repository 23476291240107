<h2 mat-dialog-title>{{ 'template.editor.testData.addVariable' | translate }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="">
    <ws-form-select [formControl]="typeSelect" [options]="typeOptions" label="Typ" required></ws-form-select>
  </div>
  @if (typeSelect.value === 'custom') {
    <form [formGroup]="customVariableFormGroup" class="divide-y">
      <div class="grid grid-cols-2 items-center">
        <ws-form-input
          formControlName="identifier"
          label="{{ 'template.editor.testData.key' | translate }}"
          required
        ></ws-form-input>
        <ws-form-input
          formControlName="value"
          label="{{ 'template.editor.testData.value' | translate }}"
          required
        ></ws-form-input>
      </div>
    </form>
  } @else {
    <form [formGroup]="predefinedVariableFormGroup" class="divide-y">
      <div class="grid grid-cols-2 items-center">
        <ws-form-select
          formControlName="predefinedVariable"
          label="{{ 'template.editor.testData.key' | translate }}"
          required
          [options]="placeholderOptions"
          [filter]="true"
        ></ws-form-select>
        <ws-form-input
          formControlName="value"
          label="{{ 'template.editor.testData.value' | translate }}"
          required
        ></ws-form-input>
      </div>
    </form>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <div class="px-4 py-3">
    <button (click)="save()" class="px-2" color="primary" mat-flat-button>
      {{ 'save' | translate }}
      <mat-icon>check</mat-icon>
    </button>
    <button (click)="close()" mat-flat-button>
      {{ 'template.close' | translate }}
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-dialog-actions>
