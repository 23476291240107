import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Template } from '../../../data/models/template'
import { Brick } from '../../../data/models/brick'
import { EditorStateService } from '../../../services/util/editor-state.service'

@Component({
  selector: 'app-ws-email-layers',
  templateUrl: './ws-email-layers.component.html',
  styleUrl: './ws-email-layers.component.scss'
})
export class WsEmailLayersComponent {
  @Input() template: Template = new Template('')
  @Input() selectedElement?: Brick
  @Output() selectedElementChange = new EventEmitter()

  constructor(private editorStateService: EditorStateService) {}

  onLayerClick(event: MouseEvent, element: Brick) {
    event.stopPropagation()
    if (!element || !this.isVisible(element)) return
    this.editorStateService.selectElement(element, true)
  }

  setInvisible(element: Brick) {
    if (!element) return
    element.setInvisible()
    if (this.selectedElement === element) this.editorStateService.unselectSelectedElement()
    this.editorStateService.updateViewOfAllElements()
    this.selectedElementChange.emit()
  }

  setVisible(element: Brick) {
    if (!element) return
    if (element.styles['css-class']) {
      element.setVisible()
      setTimeout(() => {
        this.editorStateService.updateViewOfAllElements()
        this.editorStateService.selectElement(element, true)
        this.selectedElementChange.emit()
      }, 250)
    }
  }

  isVisible(element: Brick) {
    return !(element.styles['css-class']?.toString() || '').includes('hidden-element')
  }

  duplicateElement(element: Brick) {
    if (!element) return
    this.editorStateService.duplicateElement(element)
    this.editorStateService.updateViewOfAllElements()
    this.selectedElementChange.emit()
  }

  deleteElement(element: Brick) {
    if (!element) return

    const performDeletion = () => {
      this.editorStateService.deleteElement(element)
      this.editorStateService.updateViewOfAllElements()
      this.selectedElementChange.emit()
    }

    if (element.isHidden()) {
      this.setVisible(element)
      setTimeout(performDeletion, 1000)
      return
    }
    performDeletion()
  }
}
