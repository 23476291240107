import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { WsEmailDeleteTemplateDialogComponent } from '../ws-email-delete-template-dialog/ws-email-delete-template-dialog.component'
import { Brick } from '../../../data/models/brick'
import { Template } from '../../../data/models/template'

@Component({
  selector: 'app-ws-email-delete-email-block-dialog',
  templateUrl: './ws-email-delete-email-block-dialog.component.html',
  styleUrl: './ws-email-delete-email-block-dialog.component.scss'
})
export class WsEmailDeleteEmailBlockDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<WsEmailDeleteTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { brick: Brick; template: Template }
  ) {}

  save() {
    this.dialogRef.close(true)
  }

  close() {
    this.dialogRef.close(false)
  }
}
