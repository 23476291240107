<div class="flex justify-center items-center min-h-screen">
  <div class="bg-gray-100 rounded-2xl p-4">
    <div class="text-center p-4">
      <span class="text-2xl">WS Email Creator</span>
    </div>
    <!-- Container displaying all templates -->
    <div class="flex items-center justify-center">
      <div class="flex flex-row justify-start items-center">
        <div class="flex pb-[8px]">
          <button
            mat-button
            color="primary"
            (click)="openCreateTemplateDialog()"
            matTooltip="{{ 'template.create' | translate }}"
            matTooltipPosition="below"
          >
            {{ 'template.create' | translate }}
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="max-h-[510px] overflow-auto">
      @for (templateOption of templatesOptions; track templateOption) {
        <div class="p-2 flex justify-between items-center">
          <div class="text-center">
            {{ templateOption.label }}
          </div>
          <div class="flex">
            <a [routerLink]="['/editor/template', templateOption.value]">
              <button
                mat-icon-button
                color="accent"
                class="ml-2"
                matTooltip="{{ 'template.edit' | translate }}"
                matTooltipPosition="below"
              >
                <mat-icon>edit_document</mat-icon>
              </button>
            </a>
            <button
              mat-icon-button
              color="accent"
              (click)="openCreateTemplateDialog(templateOption.value)"
              class="ml-2 pt-[20px]"
              matTooltip="{{ 'template.duplicate' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>file_copy</mat-icon>
            </button>
            <button
              mat-icon-button
              color="accent"
              (click)="openDeleteTemplateDialog(templateOption.value)"
              class="ml-2"
              matTooltip="{{ 'template.delete' | translate }}"
              matTooltipPosition="below"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <!--  show edit template name icon if template has been loaded -->
          <!--      <button-->
          <!--        mat-icon-button-->
          <!--        *ngIf="selectedTemplateId && template && !templateNameChanged"-->
          <!--        (click)="this.templateNameChanged = true"-->
          <!--        class="ml-2 pt-[20px]"-->
          <!--        matTooltip="{{ 'template.rename' | translate }}"-->
          <!--        matTooltipPosition="below"-->
          <!--      >-->
          <!--        <mat-icon>edit</mat-icon>-->
          <!--      </button>-->
          <!--      <mat-form-field *ngIf="selectedTemplateId && template && templateNameChanged">-->
          <!--        <input matInput placeholder="{{ templateName }}" [formControlName]="templateName" type="text" />-->
          <!--      </mat-form-field>-->
          <!--      <button-->
          <!--        mat-icon-button-->
          <!--        *ngIf="selectedTemplateId && template && templateNameChanged"-->
          <!--        (click)="renameTemplate()"-->
          <!--        class="ml-2 pt-[20px]"-->
          <!--        matTooltip="{{ 'template.rename' | translate }}"-->
          <!--        matTooltipPosition="below"-->
          <!--      >-->
          <!--        <mat-icon>done</mat-icon>-->
          <!--      </button>-->
        </div>
      }
    </div>
  </div>
</div>
