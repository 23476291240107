import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { WsEmailElementComponent } from '../ws-email-element/ws-email-element.component'

@Component({
  selector: 'app-ws-email-image',
  templateUrl: './ws-email-image.component.html',
  styleUrls: ['./ws-email-image.component.scss', '../ws-email-element/ws-email-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsEmailImageComponent extends WsEmailElementComponent implements OnInit, AfterViewInit {
  resizeMode: boolean = false
  override ngAfterViewInit() {
    super.ngAfterViewInit()
  }

  handleResizeEnd() {}

  override elementIsActiveChange(event: boolean) {
    this.elementIsActive = event
    if (!this.elementIsActive) {
      this.resizeMode = false
    }
  }
}
