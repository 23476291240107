<app-ws-email-element-wrapper
  *ngIf="!!element"
  [element]="element"
  [elementIsActive]="elementIsActive"
  [parent]="parent"
  [dragHandle]="dragHandle"
  [class]="element.styles['css-class']"
  [component]="this"
  (elementIsActiveChange)="elementIsActiveChange($event)"
>
  <!--  (dblclick)="resizeMode = true"-->
  <!--  <ng-container *ngIf="elementIsActive && resizeMode; else nonResizableTemplate">-->
  <!--    <div appResizable class="resizeable-image" [blockElement]="element" (resizeEnd)="handleResizeEnd()">-->
  <!--      <span [innerHTML]="element.getHtmlWithTestData(template.testData) | safeHtml: { removeHref: true }"> </span>-->
  <!--    </div>-->
  <!--  </ng-container>-->

  <!--  <ng-template #nonResizableTemplate>-->
  <span [innerHTML]="element.getHtmlWithTestData(template.testData) | safeHtml: { removeHref: true }"> </span>
  <!--  </ng-template>-->
</app-ws-email-element-wrapper>
