import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { inject } from '@angular/core'
import { first } from 'rxjs'
import { EmailService } from './services/email.service'
import { Template } from './data/models/template'

export const WsEmailTemplateResolver: ResolveFn<Template> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const emailService = inject(EmailService)
  const templateId = route.paramMap.get('templateId')

  return emailService.loadTemplateById(Number(templateId)).pipe(first())
}
