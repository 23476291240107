import { Component } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { wsCoreLibraryTranslations } from '@ws-core/core-library'
import translationsEn from '../translations/en.json'
import translationsDe from '../translations/de.json'
import { EmailService } from './services/email.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'email'
  constructor(
    private translate: TranslateService,
    private emailService: EmailService
  ) {
    translate.use('de')

    translate.setTranslation('en', wsCoreLibraryTranslations['en'])
    translate.setTranslation('de', wsCoreLibraryTranslations['de'])

    translate.setTranslation('en', translationsEn, true)
    translate.setTranslation('de', translationsDe, true)

    const url = new URL(window.location.href)
    const emailTempKeyParamName = 'token'
    const tempApiKeyFromQueryParam = url.searchParams.get(emailTempKeyParamName)

    if (tempApiKeyFromQueryParam) {
      this.emailService.setTempApiKey(tempApiKeyFromQueryParam)
    }
  }
}
