@if (template) {
  <div [formGroup]="pageSettingsForm" class="ws-email-page-settings p-4">
    <div class="ws-email-page-settings-background mb-4">
      <div class="flex justify-start items-center">
        <h1 class="element-settings-title">{{ 'template.element.background' | translate }}</h1>
        @if (template.styles['background-color']) {
          <ws-icon (click)="reset('background-color')">close_small</ws-icon>
        }
      </div>
      <div class="flex justify-between">
        <ws-form-input
          style="--ws-form-field-padding-top: 2em"
          formControlName="background-color"
          label="{{ 'template.element.backgroundColor' | translate }}"
        >
        </ws-form-input>
        <div class="text-center w-full">
          <ws-form-color-picker
            [compact]="true"
            style="--ws-form-field-padding-top: 2em"
            formControlName="background-color"
          >
          </ws-form-color-picker>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="flex justify-start items-center">
        <h1 class="element-settings-title text-left">{{ 'template.element.font' | translate }}</h1>
      </div>
      <ws-form-select
        label="{{ 'template.element.fontFamily' | translate }}"
        formControlName="font-family"
        style="--ws-form-field-padding-top: 2em"
        [options]="fontOptions"
      ></ws-form-select>
      <div class="flex justify-between">
        <ws-form-input
          style="--ws-form-field-padding-top: 2em"
          formControlName="color"
          label="{{ 'template.element.fontColor' | translate }}"
        >
        </ws-form-input>
        <div class="text-center w-full">
          <ws-form-color-picker [compact]="true" style="--ws-form-field-padding-top: 2em" formControlName="color">
          </ws-form-color-picker>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="flex justify-start items-center">
        <h1 class="element-settings-title text-left">{{ 'Link' | translate }}</h1>
      </div>
      <div class="flex justify-between">
        <ws-form-input
          style="--ws-form-field-padding-top: 2em"
          formControlName="link-color"
          label="{{ 'template.element.fontColor' | translate }}"
        >
        </ws-form-input>
        <div class="text-center w-full">
          <ws-form-color-picker [compact]="true" style="--ws-form-field-padding-top: 2em" formControlName="link-color">
          </ws-form-color-picker>
        </div>
      </div>
    </div>
  </div>
}
