import { Pipe, PipeTransform } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'

interface SafeHtmlOptions {
  removeHref?: boolean
  centerBody?: boolean
  dividerPreview?: boolean
}

@Pipe({
  name: 'safeHtml',
  standalone: true
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string, options?: SafeHtmlOptions) {
    if (options?.removeHref) {
      html = this.removeHref(html)
    }
    if (options?.centerBody) {
      html = this.centerBody(html)
    }
    if (options?.dividerPreview) {
      html = this.setDividerWidth(html)
    }
    return this.sanitizer.bypassSecurityTrustHtml(html)
  }
  private removeHref(html: string): string {
    return html.replace(/href=".*?"/g, '')
  }

  centerBody(html: string): string {
    return html.replace(/<body([^>]*)>/, (match, p1) => {
      if (p1.includes('style=')) {
        return `<body${p1.replace(/style="([^"]*)"/, 'style="$1 display: flex; justify-content: center;"')}>`
      } else {
        return `<body${p1} style="display: flex; justify-content: center;">`
      }
    })
  }

  private setDividerWidth(html: string): string {
    return html.replace(/<div([^>]*)>/g, (match, p1) => {
      if (p1.includes('style=')) {
        return `<div${p1.replace(/style="([^"]*)"/, 'style="$1 width: 280px;"')}>`
      } else {
        return `<div${p1} style="width: 240px;">`
      }
    })
  }
}
