<div class="mat-mdc-form-field w-full" *ngIf="ngControl">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <mat-slider discrete [min]="min" [max]="max" [step]="step">
    <input matSliderThumb [formControl]="formControl" (valueChange)="onFieldChange($event)" [value]="value" />
  </mat-slider>
  <div class="mat-form-field-subscript-wrapper" *ngIf="hasValidators">
    <mat-error *ngIf="hasErrors">
      {{ validationErrorMessage }}
    </mat-error>
  </div>
</div>
