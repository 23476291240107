import { ChangeDetectionStrategy, Component } from '@angular/core'
import { WsEmailElementComponent } from '../ws-email-element/ws-email-element.component'

@Component({
  selector: 'app-ws-email-spacer',
  templateUrl: './ws-email-spacer.component.html',
  styleUrls: ['./ws-email-spacer.component.scss', '../ws-email-element/ws-email-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsEmailSpacerComponent extends WsEmailElementComponent {}
