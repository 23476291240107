import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { Template } from '../../../data/models/template'
import { MjmlProcessorService } from '../../../services/util/mjml-processor.service'
import { EditorStateService } from '../../../services/util/editor-state.service'
import { Brick } from '../../../data/models/brick'
import { Section } from '../../../data/models/section'
import { Column } from '../../../data/models/column'
import { Image } from '../../../data/models/image'
import { Button } from '../../../data/models/button'
import { Text } from '../../../data/models/text'
import { first, Subscription } from 'rxjs'
import { Clipboard } from '@angular/cdk/clipboard'
import { Divider } from '../../../data/models/divider'
import { EmailService } from '../../../services/email.service'
import { Spacer } from '../../../data/models/spacer'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { WsEmailTestDeliveryInformation } from '../../../data/types/ws-email-test-delivery-information'
import { WsDialogService, WsNotificationsService } from '@ws-core/core-library'
import { WsEmailTemplate } from '../../../data/types/ws-email-template'
import { WsEmailTemplateHistory } from '../../../data/types/ws-email-template-history'
import { WsEmailEditTestDataDialogComponent } from '../../dialogs/ws-email-edit-test-data-dialog/ws-email-edit-test-data-dialog.component'
import { WsEmailTestDeliveryDialogComponent } from '../../dialogs/ws-email-test-delivery-dialog/ws-email-test-delivery-dialog.component'
import { WsEmailAiDialogComponent } from '../../dialogs/ws-email-ai-dialog/ws-email-ai-dialog.component'
import { WsEmailDefaultBlocksComponent } from '../ws-email-default-blocks/ws-email-default-blocks.component'
import { Social } from '../../../data/models/social'
import { SocialElement, SocialNetworks } from '../../../data/models/social-element'
import { CdkDragAndDropService } from '../../../services/util/cdk-drag-and-drop.service'
import { UndoRedoAction, WsEmailUndoRedoService } from '../../../services/util/ws-email-undo-redo.service'

export type TemplateLanguage = 'de' | 'en' | 'nl' | 'fr' | 'it'

@Component({
  selector: 'app-ws-email-editor-view',
  templateUrl: './ws-email-editor-view.component.html',
  styleUrl: './ws-email-editor-view.component.scss'
})
export class WsEmailEditorViewComponent implements OnInit, AfterContentChecked, OnDestroy {
  @ViewChild('desktopPreviewIframe') iframeDesktop: ElementRef | undefined
  @ViewChild('mobilePreviewIframe') iframeMobile: ElementRef | undefined
  @ViewChild(WsEmailDefaultBlocksComponent) defaultBlocksComponent: WsEmailDefaultBlocksComponent | undefined
  editorStateSubscription?: Subscription
  deviceWidthOptions = {
    mobile: 320,
    desktop: 800
  }
  editorWidth = this.deviceWidthOptions.desktop
  @Input() template: Template = new Template('')
  @Input() wsEmailTemplate: WsEmailTemplate = new WsEmailTemplate()
  processingUndoRedo: boolean = false

  @Input() testDataJson: { [key: string]: any } = {}
  @Output() save = new EventEmitter<WsEmailTemplate>()

  templatePreview: string | undefined

  selectedIndex = 0
  drafts: Map<Template, string> = new Map()

  selectedElement?: Brick

  section: Brick = new Section({ 'background-color': 'yellow' })
  column: Brick = new Column({})
  sections = [this.section]

  /** new Items **/
  newTemplate = new Template('New Template')
  newSections = [{ name: 'Empty Section', value: new Section({}) }]
  newColumn = new Column({})
  newImage = this.renewImage()
  newButton = new Button({}, 'new button')
  newHeadline = new Text('new heading', { 'font-size': '22px', 'mj-class': 'heading' })
  newText = new Text('new text', {})
  newSocial = this.renewSocial()
  newDivider = new Divider()
  newSpacer = new Spacer({
    height: '30px'
  })
  selectedSettingsTab = 0
  currentLanguage: TemplateLanguage = 'de'
  emailTestDeliveryInformation: WsEmailTestDeliveryInformation = new WsEmailTestDeliveryInformation()
  loadingTemplateHistory = false
  aiText = ''
  templateHistory: WsEmailTemplateHistory[] = []
  languages: TemplateLanguage[] = ['de', 'en', 'nl', 'fr', 'it']
  private latestSavedTemplate: WsEmailTemplateHistory = new WsEmailTemplateHistory()
  private autoSaveTimeout?: number

  constructor(
    private mjmProcessorService: MjmlProcessorService,
    protected editorStateService: EditorStateService,
    private clipboard: Clipboard,
    private emailService: EmailService,
    public dialog: MatDialog,
    private wsDialogService: WsDialogService,
    public translate: TranslateService,
    private wsNotificationsService: WsNotificationsService,
    protected dragAndDropService: CdkDragAndDropService,
    protected undoRedoService: WsEmailUndoRedoService,
    private cd: ChangeDetectorRef
  ) {
    this.initializeSections()
  }

  ngAfterContentChecked(): void {
    this.cd.detectChanges()
  }

  onEditorTabChange(event: any) {
    if (this.selectedIndex === 0) {
      this.templatePreview = this.getTemplatePreview()
    }
    this.selectedIndex = event.index
  }

  ngOnInit(): void {
    this.templatePreview = this.getTemplatePreview()
    this.setLatestSavedEmailTemplate()
    this.subscribeEditorState()
    //Todo: uncomment provide better templates
    // templatesJson.forEach((template) => {
    //   const processedTemplate = this.mjmProcessorService.generateTemplateFromMjml(
    //     template.mjml,
    //     template.name,
    //     template.bodyStyles,
    //     template.testDataJson
    //   )
    //   this.drafts.set(processedTemplate, processedTemplate.getHtml())
    // })
    this.loadEmailTemplateHistory()
    if (this.template.getChildren().length === 0) {
      this.selectedTemplateTab = 0
    }
    this.captureUndoRedoTemplate()
  }

  chooseTemplate(template: any) {
    this.editorStateService.unselectSelectedElement()
    const dialog = this.openConfirmUnsavedChangesDialog()
    dialog.afterClose$.subscribe((e: any) => {
      switch (e) {
        case 'confirm':
          this.parseMjmlIntoTemplate(template.mjml)
          this.templatePreview = this.getTemplatePreview()

          this.selectedTemplateTab = 1

          this.undoRedoService.clearStack()
          setTimeout(() => {
            this.captureUndoRedoTemplate()
          }, 100)
      }
    })
  }

  parseMjmlIntoTemplate(mjml: string) {
    if (mjml) {
      const template: Template = this.mjmProcessorService.generateTemplateFromMjml(mjml)
      this.template.setChildren(template.getChildren())
      this.template.mjml = mjml
    }
  }

  selectedTemplateTab = 1

  getTemplatePreview() {
    let templateHtml = this.template.getHtmlWithTestData()
    const scrollbarStyles = `
        <style>
            ::-webkit-scrollbar { width: 5px; }
            ::-webkit-scrollbar-track { background-color: #f9f9f9; }
            ::-webkit-scrollbar-thumb { background-color: #ccc; border-radius: 5px;}
        </style>
    `
    const headIndex = templateHtml.indexOf('</head>')

    if (headIndex !== -1) {
      templateHtml = templateHtml.slice(0, headIndex) + scrollbarStyles + templateHtml.slice(headIndex)
    }

    templateHtml = templateHtml.replace('<head>', '<head>' + '<base target="_blank">')
    return templateHtml
  }

  subscribeEditorState() {
    this.editorStateSubscription = this.editorStateService.selectedElement$.subscribe((selectedElementComponent) => {
      this.selectedTemplateTab = 1
      if (selectedElementComponent?.element) {
        this.selectedElement = selectedElementComponent.element
        if (['Text'].includes(this.selectedElement?.type || '')) {
          setTimeout(() => {
            this.selectedSettingsTab = 1
          }, 300)
          return
        }
        this.selectedSettingsTab = 1
      } else {
        this.selectedElement = undefined
        this.selectedSettingsTab = 0
        this.startAutoSaveProcess()
      }
    })
  }
  onGuidelinesClick() {
    this.editorStateService.showGuidelines = !this.editorStateService.showGuidelines
    this.editorStateService.updateViewOfAllElements()
  }
  renewSections() {
    this.initializeSections()
  }

  getNewSectionsValues(): Brick[] {
    return this.newSections.map((section) => section.value)
  }

  get getSectionsIds(): string[] {
    return (
      this.template
        .getChildren()
        .filter((brick) => brick.type === 'Section')
        .map((section) => section.id) || []
    )
  }

  get getColumnIds(): string[] {
    return (
      this.template.getChildren().flatMap((section) =>
        section
          .getChildren()
          .filter((brick) => brick.type === 'Column')
          .map((column) => column.id)
      ) || []
    )
  }

  initializeSections() {
    this.sections = []
    const sectionWithOneColumn = this.generateNewSection(1)
    const sectionWithTwoColumns = this.generateNewSection(2)
    const sectionWithTwoColumns2 = this.generateNewSection(2, ['33%', '67%'])
    const sectionWithTwoColumns3 = this.generateNewSection(2, ['67%', '33%'])
    const sectionWithThreeColumns = this.generateNewSection(3)
    const sectionWithFourColumns = this.generateNewSection(4)

    this.newSections = [
      { name: 'Section With One Column', value: sectionWithOneColumn },
      { name: 'Section With Two Columns', value: sectionWithTwoColumns },
      { name: 'Section With Two Columns', value: sectionWithTwoColumns2 },
      { name: 'Section With Two Columns', value: sectionWithTwoColumns3 },
      { name: 'Section With Three Columns', value: sectionWithThreeColumns },
      { name: 'Section With Four Columns', value: sectionWithFourColumns }
    ]
  }

  onDragDropped(brick: Brick) {
    this.dragAndDropService.onDragEnded()

    switch (brick.type) {
      case 'Section':
        this.renewSections()
        break
      case 'Column':
        this.renewColumns()
        break
      case 'Image':
        this.renewImage()
        break
      case 'Button':
        this.renewButton()
        break
      case 'Text':
        if (brick.content === 'new text') {
          this.renewText()
        } else {
          this.renewHeadline()
        }
        break
      case 'Social':
        this.renewSocial()
        break
      case 'Divider':
        this.renewDivider()
        break
      case 'Spacer':
        this.renewSpacer()
        break
    }

    setTimeout(() => {
      this.startAutoSaveProcess()
    }, 100)
  }

  generateNewSection(columns = 0, columnRatio: string[] = []) {
    const hasColumnRatios = columnRatio.length === columns
    const section = new Section({})
    for (let i = 0; i < columns; i++) {
      if (hasColumnRatios) {
        section.add(new Column({ width: columnRatio[i] }))
      } else {
        section.add(new Column({}))
      }
    }
    return section
  }

  renewColumns() {
    this.newColumn = new Column({})
  }

  renewImage(): Image {
    this.newImage = new Image({
      src: 'https://www.waldhart.at/fileadmin/user_upload/illustrationen/bergegondeln.png',
      alt: 'bergegondeln',
      width: '600px'
    })
    return this.newImage
  }
  renewButton() {
    this.newButton = new Button({}, 'new button')
  }
  renewText() {
    this.newText = new Text('new text', {})
    // align: 'center',
    // 'font-size': this.template.styles['font-size'],
    //   'font-family': this.template.styles['font-family']
  }

  renewHeadline() {
    this.newHeadline = new Text('new heading', { 'font-size': '22px', 'mj-class': 'heading' })
  }

  renewSocial() {
    const social = new Social({ 'icon-size': '35px' })
    const networks: SocialNetworks = new SocialElement().defaultSocialNetworks

    const createSocialElement = (name: string) => {
      const { alt, title, src, 'background-color': backgroundColor, 'base-url': href } = networks[name.split('-')[0]]
      return new SocialElement('', {
        name: name,
        'icon-size': '35px',
        'icon-height': '35px',
        alt,
        title: title,
        src,
        'background-color': backgroundColor,
        href: href,
        align: 'center'
      })
    }

    const socialElements = [createSocialElement('facebook-noshare'), createSocialElement('instagram')]
    socialElements.forEach((socialElement) => {
      social.add(socialElement)
    })
    this.newSocial = social
    return social
  }
  renewDivider() {
    this.newDivider = new Divider()
  }
  renewSpacer() {
    this.newSpacer = new Spacer({
      height: '30px'
    })
  }

  copyCodeToClipboard(code?: string) {
    this.editorStateService.unselectSelectedElement()
    this.clipboard.copy(code || '')
  }

  openEditTestDataDialog() {
    const editTestDataDialogConfig = new MatDialogConfig()

    editTestDataDialogConfig.disableClose = true
    editTestDataDialogConfig.autoFocus = true
    editTestDataDialogConfig.minWidth = '50vw'

    editTestDataDialogConfig.data = this.testDataJson
    const editTestDataDialogRef = this.dialog.open(WsEmailEditTestDataDialogComponent, editTestDataDialogConfig)

    editTestDataDialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.testDataJson = response
        this.template.testData = response
        this.saveEmailTemplate()
        this.defaultBlocksComponent?.buildDefaultBlocks()
        this.editorStateService.updateViewOfAllElements()
      }
    })
  }

  openEmailTestDeliveryDialog() {
    const editTestDataDialogConfig = new MatDialogConfig()

    editTestDataDialogConfig.disableClose = true
    editTestDataDialogConfig.autoFocus = true
    editTestDataDialogConfig.minWidth = '30vw'

    this.emailTestDeliveryInformation.html = this.template.getHtml(true)
    editTestDataDialogConfig.data = this.emailTestDeliveryInformation
    const editTestDataDialogRef = this.dialog.open(WsEmailTestDeliveryDialogComponent, editTestDataDialogConfig)

    editTestDataDialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.emailService
          .sendTestEmail(this.emailTestDeliveryInformation)
          .pipe(first())
          .subscribe({
            next: () => this.wsNotificationsService.success('template.message.testDelivery.success'),
            error: () => this.wsNotificationsService.error('template.message.testDelivery.error')
          })
      }
    })
  }

  openAiDialog() {
    const aiDialogConfig = new MatDialogConfig()

    aiDialogConfig.disableClose = true
    aiDialogConfig.autoFocus = true
    aiDialogConfig.minWidth = '50vw'

    aiDialogConfig.data = this.aiText
    const editTestDataDialogRef = this.dialog.open(WsEmailAiDialogComponent, aiDialogConfig)

    editTestDataDialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.aiText = response
        this.generateTemplateWithAi()
      }
    })
  }

  generateTemplateWithAi() {
    this.emailService
      .askAI(
        'Du bist ein Marketingmitarbeiter namens Max Marketingmann, der für die Skischule Alpenwind in Lahnenberg arbeitet. Deine Aufgabe ist es, eine Werbemail zu verfassen, um Teilnehmer für den diesjährigen Skikurs zu gewinnen. Du hast Feedback von Teilnehmern erhalten, das von ihren Erfahrungen mit den Skilehrern handelt. Verwende diese Informationen, um die Teilnehmer direkt in ihrer verwendeten Sprache aus Feedback anzusprechen und sie für den nächsten Kurs zu motivieren. Schreibe jedoch nicht direkt über das Feedback selbst, sondern nutze es als Grundlage, um die Vorzüge des Kurses und die Expertise der Skischule zu betonen. Die E-Mail ist direkt an den jewiligen Teilnehmer gerichtet. Modifiziere den Text innerhalb der Text Tags von folgenden Template in MJML. Verwende nur tags aus dieser liste ["<mjml>", "<mj-head>", "<mj-body>", "<mj-section>", "<mj-column>", "<mj-text>", "<mj-image>", "<mj-divider>", "<mj-spacer>", "<mj-group>"] als Response bitte mjml String start und ende mjml tags. Code muss body tag enthalten. Keine leeren mj-text tags (z.B. <mj-text></mj-text>' +
          this.template.getMjml(true),
        this.aiText
      )
      .pipe(first())
      .subscribe((response) => {
        this.parseMjmlIntoTemplate(response.choices[0].message.content.replaceAll('`', '').replaceAll('´', ''))
        this.templatePreview = this.getTemplatePreview()
      })
  }

  saveEmailTemplate() {
    clearTimeout(this.autoSaveTimeout)
    this.updateWsEmailTemplate()
    this.save.emit(this.wsEmailTemplate)
    this.setLatestSavedEmailTemplate()
  }

  loadEmailTemplateHistory() {
    this.loadingTemplateHistory = true
    this.setLatestSavedEmailTemplate()
    if (!this.template?.wsEmailTemplateId) return
    this.emailService
      .getHistoryOfTemplate(this.template.wsEmailTemplateId)
      .pipe(first())
      .subscribe({
        next: (templateHistory: WsEmailTemplateHistory[]) => {
          this.templateHistory = templateHistory
          this.templateHistory.forEach((history: WsEmailTemplateHistory) => {
            history.mjml = JSON.stringify(history.mjml)
            history.html = JSON.stringify(history.html)
          })

          this.templateHistory.unshift(this.latestSavedTemplate)
        },
        complete: () => {
          this.loadingTemplateHistory = false
        }
      })
  }

  updateWsEmailTemplate() {
    this.setWsEmailTemplateWithCurrentTemplate()
    this.wsEmailTemplate.testData = JSON.stringify(this.template.testData)
    this.wsEmailTemplate.styles = JSON.stringify(this.template.getStyles())
  }

  setWsEmailTemplateWithCurrentTemplate() {
    if (this.wsEmailTemplate.mjml) {
      this.wsEmailTemplate.mjml[this.currentLanguage] = this.template.getMjml()
    }

    if (this.wsEmailTemplate.html) {
      this.wsEmailTemplate.html[this.currentLanguage] = this.template.getHtml()
    }
    this.wsEmailTemplate.styles = JSON.stringify(this.template.getStyles())
  }

  setLatestSavedEmailTemplate() {
    this.updateWsEmailTemplate()
    this.latestSavedTemplate = new WsEmailTemplateHistory(
      JSON.stringify(this.wsEmailTemplate.mjml),
      JSON.stringify(this.wsEmailTemplate.html),
      this.wsEmailTemplate.testData,
      this.wsEmailTemplate.styles
    )
  }

  onHistoryVersionSelect(historyEntry: WsEmailTemplateHistory) {
    const dialog = this.openConfirmUnsavedChangesDialog()
    dialog.afterClose$.subscribe((e: any) => {
      switch (e) {
        case 'confirm':
          this.handleHistoryTemplateChange(historyEntry)
          this.undoRedoService.clearStack()
          clearTimeout(this.autoSaveTimeout)
          setTimeout(() => {
            this.captureUndoRedoTemplate()
          }, 100)
      }
    })
  }

  openConfirmUnsavedChangesDialog() {
    return this.wsDialogService.open({
      dialogTitleText: 'unsavedChanges',
      dialogContentText: 'unsavedChangesLost'
    })
  }

  checkIfTemplateIsAlreadySavedAsHistory(): boolean {
    this.setWsEmailTemplateWithCurrentTemplate()
    return this.templateHistory.some((history: WsEmailTemplateHistory) => {
      return this.languages.some((lang: string) => {
        return (
          history.mjml === this.wsEmailTemplate.mjml[lang] &&
          history.testData === this.wsEmailTemplate.testData &&
          history.styles === this.wsEmailTemplate.styles
        )
      })
    })
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.selectedElement?.type === 'Text') {
      //Text Editor has its own UNDO/REDO
      return
    }
    if (event.ctrlKey && event.key === 'z') {
      this.undoAction()
      event.preventDefault()
    } else if (event.ctrlKey && event.key === 'y') {
      this.redoAction()
      event.preventDefault()
    }
  }

  redoAction() {
    this.processingUndoRedo = true
    if (this.undoRedoService.redoAvailable()) {
      this.restoreUndoRedoTemplate(this.undoRedoService.redo())
    }
    setTimeout(() => {
      this.processingUndoRedo = false
    }, 50)
  }

  undoAction() {
    this.processingUndoRedo = true
    if (this.undoRedoService.undoAvailable()) {
      this.restoreUndoRedoTemplate(this.undoRedoService.undo())
    }
    setTimeout(() => {
      this.processingUndoRedo = false
    }, 50)
  }

  captureUndoRedoTemplate() {
    this.setWsEmailTemplateWithCurrentTemplate()
    this.undoRedoService.captureAction(this.wsEmailTemplate)
  }

  restoreUndoRedoTemplate(actionToRestore?: UndoRedoAction) {
    if (actionToRestore) {
      this.currentLanguage = this.undoRedoService.currentLanguage
      this.wsEmailTemplate.mjml = JSON.parse(actionToRestore.mjml)
      this.wsEmailTemplate.html = JSON.parse(actionToRestore.html)
      this.wsEmailTemplate.styles = actionToRestore.styles
      this.wsEmailTemplate.testData = actionToRestore.testData

      this.parseMjmlIntoTemplate(this.wsEmailTemplate.mjml[this.currentLanguage])
      this.template.styles = JSON.parse(actionToRestore.styles)
      this.template.testData = JSON.parse(actionToRestore.testData)
      this.testDataJson = this.template.testData
    }

    this.templatePreview = this.getTemplatePreview()
    if (this.selectedElement) {
      this.editorStateService.unselectSelectedElement()
    }
  }

  startAutoSaveProcess() {
    this.setWsEmailTemplateWithCurrentTemplate()
    const hasChanges =
      JSON.stringify(this.wsEmailTemplate.mjml) !== this.latestSavedTemplate.mjml ||
      this.wsEmailTemplate.testData !== this.latestSavedTemplate.testData ||
      this.wsEmailTemplate.styles !== this.latestSavedTemplate.styles

    if (hasChanges) {
      // console.log('start auto save process')
      this.captureUndoRedoTemplate()
      if (this.checkIfTemplateIsAlreadySavedAsHistory()) {
        // console.log('template already saved as history')
        return
      }
      clearTimeout(this.autoSaveTimeout)
      this.autoSaveTimeout = setTimeout(() => {
        this.saveEmailTemplate()
        // console.log('auto save executed')
      }, 120000)
    } else {
      // console.warn('clear auto save timer - template not changed')
      clearTimeout(this.autoSaveTimeout)
    }
  }

  ngOnDestroy(): void {
    this.editorStateSubscription?.unsubscribe()
    clearTimeout(this.autoSaveTimeout)
  }

  onTranslationTabClick(language: TemplateLanguage) {
    this.setWsEmailTemplateWithCurrentTemplate()
    this.currentLanguage = language
    this.undoRedoService.currentLanguage = this.currentLanguage

    if (this.wsEmailTemplate.mjml[this.currentLanguage]) {
      this.parseMjmlIntoTemplate(this.wsEmailTemplate.mjml[this.currentLanguage])
    } else {
      this.parseMjmlIntoTemplate(this.wsEmailTemplate.mjml['de'])
    }
    this.setWsEmailTemplateWithCurrentTemplate()
  }

  resetTemplate(language: string) {
    const dialog = this.openConfirmUnsavedChangesDialog()
    dialog.afterClose$.subscribe((e: any) => {
      switch (e) {
        case 'confirm':
          this.onTranslationTabClick('de')
          delete this.wsEmailTemplate.mjml[language]
          delete this.wsEmailTemplate.html[language]
          this.startAutoSaveProcess()
      }
    })
  }

  /**
   * Ensure that a new Button has initially the same font-family as the template
   */
  onButtonDragStarted() {
    this.dragAndDropService.onDragStarted()
  }

  private handleHistoryTemplateChange(wsEmailHistoryEntry: WsEmailTemplateHistory) {
    if (!wsEmailHistoryEntry || !this.template) {
      return
    }
    this.editorStateService.unselectSelectedElement()
    this.wsEmailTemplate.mjml = JSON.parse(wsEmailHistoryEntry.mjml)
    this.wsEmailTemplate.html = JSON.parse(wsEmailHistoryEntry.html)
    this.wsEmailTemplate.styles = wsEmailHistoryEntry.styles
    this.wsEmailTemplate.testData = wsEmailHistoryEntry.testData
    this.testDataJson = JSON.parse(wsEmailHistoryEntry.testData)

    if (!this.wsEmailTemplate.mjml[this.currentLanguage]) {
      this.currentLanguage = 'de'
    }

    this.template = this.mjmProcessorService.generateTemplateFromMjml(
      this.wsEmailTemplate.mjml[this.currentLanguage],
      this.template.name,
      JSON.parse(this.wsEmailTemplate.styles),
      JSON.parse(this.wsEmailTemplate.testData),
      this.template.wsEmailTemplateId
    )
  }
}
