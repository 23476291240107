<app-ws-email-element-wrapper
  #wrapper
  [element]="element"
  [(elementIsActive)]="elementIsActive"
  [parent]="parent"
  [dragHandle]="dragHandle"
  [template]="template"
  [ngClass]="{
    'section-guidelines-border': editorStateService.showGuidelines && !elementIsActive,
    'section-guidelines-space': editorStateService.showGuidelines
  }"
  [component]="this"
>
  @if (element?.isFullWidth()) {
    <table
      align="center"
      [class]="element?.styles?.['css-class']"
      [ngStyle]="element?.getStyles(template.testData).tableFullWidth"
      border="0"
      cellpadding="0"
      cellspacing="0"
      role="presentation"
    >
      <tbody>
        <tr>
          <td>
            <div [ngStyle]="element?.getStyles(template.testData).div" [style.max-width]="getContainerWidth()">
              <!--  // [ngClass]="getCssClass()"-->
              @if (element?.hasBackground()) {
                <div class="innerDiv">
                  <table
                    [ngStyle]="element?.getStyles(template.testData).table"
                    align="center"
                    role="presentation"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                  >
                    <tbody>
                      <tr>
                        <td
                          [ngStyle]="element?.getStyles().td"
                          [id]="element?.id || ''"
                          [cdkDropListConnectedTo]="sectionIds()"
                          (cdkDropListDropped)="cdkDragAndDropService.drop($event)"
                          [cdkDropListData]="element?.getChildren()"
                          cdkDropListOrientation="horizontal"
                          cdkDropList
                          class="droplist-for-columns"
                        >
                          @if (element?.getVisibleChildren()?.length === 0) {
                            <div class="bg-white" style="font-size: 16px">
                              {{ 'element.emptySection' | translate }}
                            </div>
                          }
                          @for (child of element?.getVisibleChildren(); track child.id) {
                            @switch (child.type) {
                              @case ('Column') {
                                <app-ws-email-column
                                  class="ws-email-column"
                                  [hidden]="child.isHidden()"
                                  [style.min-height]="highestColumnHeight"
                                  [ngStyle]="getMjColumnWidth(child)"
                                  [template]="template"
                                  [parent]="element"
                                  [element]="child"
                                  [parentElementAndSiblings]="elementAndSiblings"
                                ></app-ws-email-column>
                              }
                            }
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              } @else {
                <table
                  [ngStyle]="element?.getStyles(template.testData).table"
                  role="presentation"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                >
                  <tbody>
                    <tr>
                      <td
                        [ngStyle]="element?.getStyles().td"
                        [id]="element?.id || ''"
                        [cdkDropListConnectedTo]="sectionIds()"
                        (cdkDropListDropped)="cdkDragAndDropService.drop($event)"
                        [cdkDropListData]="element?.getChildren()"
                        cdkDropListOrientation="horizontal"
                        cdkDropList
                        class="droplist-for-columns"
                      >
                        @if (element?.getVisibleChildren()?.length === 0) {
                          <div class="bg-white" style="font-size: 16px">
                            {{ 'element.emptySection' | translate }}
                          </div>
                        }
                        @for (child of element?.getVisibleChildren(); track child.id) {
                          @switch (child.type) {
                            @case ('Column') {
                              <app-ws-email-column
                                class="ws-email-column"
                                [hidden]="child.isHidden()"
                                [style.min-height]="highestColumnHeight"
                                [ngStyle]="getMjColumnWidth(child)"
                                [template]="template"
                                [parent]="element"
                                [element]="child"
                                [parentElementAndSiblings]="elementAndSiblings"
                              ></app-ws-email-column>
                            }
                          }
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  } @else {
    <div [ngStyle]="element?.getStyles(template.testData).div" [style.max-width]="getContainerWidth()">
      <!--  <div class="example-custom-placeholder" *cdkDragPlaceholder></div>-->
      <!--  // [ngClass]="getCssClass()"-->
      @if (element?.hasBackground()) {
        <div class="innerDiv">
          <table
            [ngStyle]="element?.getStyles(template.testData).table"
            align="center"
            role="presentation"
            border="0"
            cellpadding="0"
            cellspacing="0"
          >
            <tbody>
              <tr>
                <td
                  [ngStyle]="element?.getStyles().td"
                  [id]="element?.id || ''"
                  [cdkDropListConnectedTo]="sectionIds()"
                  (cdkDropListDropped)="cdkDragAndDropService.drop($event)"
                  [cdkDropListData]="element?.getChildren()"
                  cdkDropListOrientation="horizontal"
                  cdkDropList
                  class="droplist-for-columns"
                >
                  @if (element?.getVisibleChildren()?.length === 0) {
                    <div class="bg-white" style="font-size: 16px">
                      {{ 'element.emptySection' | translate }}
                    </div>
                  }
                  @for (child of element?.getVisibleChildren(); track child.id) {
                    @switch (child.type) {
                      @case ('Column') {
                        <app-ws-email-column
                          class="ws-email-column"
                          [hidden]="child.isHidden()"
                          [style.min-height]="highestColumnHeight"
                          [ngStyle]="getMjColumnWidth(child)"
                          [template]="template"
                          [parent]="element"
                          [element]="child"
                          [parentElementAndSiblings]="elementAndSiblings"
                        ></app-ws-email-column>
                      }
                    }
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      } @else {
        <table
          [ngStyle]="element?.getStyles(template.testData).table"
          role="presentation"
          border="0"
          cellpadding="0"
          cellspacing="0"
        >
          <tbody>
            <tr>
              <td
                [ngStyle]="element?.getStyles().td"
                [id]="element?.id || ''"
                [cdkDropListConnectedTo]="sectionIds()"
                (cdkDropListDropped)="cdkDragAndDropService.drop($event)"
                [cdkDropListData]="element?.getChildren()"
                cdkDropListOrientation="horizontal"
                cdkDropList
                class="droplist-for-columns"
              >
                @if (element?.getVisibleChildren()?.length === 0) {
                  <div class="bg-white" style="font-size: 16px">
                    {{ 'element.emptySection' | translate }}
                  </div>
                }
                @for (child of element?.getVisibleChildren(); track child.id) {
                  @switch (child.type) {
                    @case ('Column') {
                      <app-ws-email-column
                        class="ws-email-column"
                        [hidden]="child.isHidden()"
                        [style.min-height]="highestColumnHeight"
                        [ngStyle]="getMjColumnWidth(child)"
                        [template]="template"
                        [parent]="element"
                        [element]="child"
                        [parentElementAndSiblings]="elementAndSiblings"
                      ></app-ws-email-column>
                    }
                  }
                }
              </td>
            </tr>
          </tbody>
        </table>
      }
    </div>
  }
</app-ws-email-element-wrapper>
