import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, Validators } from '@angular/forms'

@Component({
  selector: 'app-ws-email-edit-test-data-dialog',
  templateUrl: './ws-email-edit-test-data-dialog.component.html',
  styleUrl: './ws-email-edit-test-data-dialog.component.scss'
})
export class WsEmailEditTestDataDialogComponent implements OnInit {
  testDataFormField = new FormControl('', [Validators.required])

  constructor(
    private dialogRef: MatDialogRef<WsEmailEditTestDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {
    this.testDataFormField.setValue(JSON.stringify(data, null, 2))
  }

  ngOnInit() {
    this.testDataFormField.valueChanges.subscribe((value) => {
      if (value) {
        this.dataChanged(value)
      } else {
        this.dataNotParseable()
      }
    })
  }

  dataChanged(event: string) {
    try {
      this.data = JSON.parse(event)
    } catch (error) {
      this.dataNotParseable()
    }
  }

  dataNotParseable() {
    this.testDataFormField.setErrors({ json: true })
  }

  save() {
    this.dialogRef.close(this.data)
  }

  close() {
    this.dialogRef.close()
  }
}
