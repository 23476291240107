<app-ws-email-element-wrapper
  #wrapper
  [element]="element"
  [elementIsActive]="elementIsActive"
  [parent]="parent"
  [dragHandle]="dragHandle"
  [ngClass]="{ spacerGuidelines: editorStateService.showGuidelines && !elementIsActive }"
  [component]="this"
>
  <div [ngStyle]="element?.getStyles().div">&#8202;</div>
</app-ws-email-element-wrapper>
