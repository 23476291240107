import { Injectable } from '@angular/core'
import { MjmlProcessorService } from './mjml-processor.service'
import { Brick } from '../../data/models/brick'
import { Section } from '../../data/models/section'
import { Column } from '../../data/models/column'
import { Template } from '../../data/models/template'
import { WsEmailBlock } from '../../data/types/ws-email-block'
import { Social } from '../../data/models/social'

@Injectable({
  providedIn: 'root'
})
export class BrickFactoryService {
  constructor(private mjmlProcessor: MjmlProcessorService) {}

  createSectionsFromDefaultBrick(defaultBrick: WsEmailBlock): Section[] {
    const sections = this.mjmlProcessor.generateSectionsFromMjml(defaultBrick.mjml)
    sections.forEach((section) => {
      section.name = defaultBrick.name
    })
    return sections
  }

  createColumnsFromDefaultBrick(defaultBrick: WsEmailBlock): Column {
    const brick = this.mjmlProcessor.generateColumnFromMjml(defaultBrick.mjml)
    brick.name = defaultBrick.name
    return brick
  }

  createLastChildFromDefaultBrick(defaultBrick: WsEmailBlock): Brick {
    const brick = this.mjmlProcessor.generateLastChildFromMjml(defaultBrick.mjml)
    brick.name = defaultBrick.name
    return brick
  }

  duplicateBrick(brick: Brick): Brick {
    switch (brick.type) {
      case 'Template':
        return this.duplicateTemplate(brick as Template)
      case 'Section':
        return this.duplicateSection(brick as Section)
      case 'Column':
        return this.duplicateColumn(brick as Column)
      case 'SocialElement':
        return this.duplicateSocialElement(brick)
      default:
        return this.duplicateLastChild(brick)
    }
  }

  private duplicateTemplate(template: Template) {
    return this.mjmlProcessor.generateTemplateFromMjml(template.getMjml())
  }

  private duplicateSection(section: Section) {
    const template = new Template('Template x')
    template.add(section)
    return this.duplicateTemplate(template).getChildren()?.[0]
  }

  private duplicateColumn(brick: Column) {
    const section = new Section({})
    section.add(brick)
    return this.duplicateSection(section).getChildren()?.[0]
  }

  private duplicateLastChild(brick: Brick) {
    const column = new Column()
    column.add(brick)
    return this.duplicateColumn(column).getChildren()?.[0]
  }

  private duplicateSocialElement(brick: Brick): Brick {
    const social = new Social()
    social.add(brick)

    social.styles = { 'icon-size': brick.styles['icon-height'] || '35px' }
    return this.duplicateLastChild(social).getChildren()?.[0]
  }
}
