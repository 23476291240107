<app-ws-email-element-wrapper
  #wrapper
  *ngIf="element"
  [element]="element"
  [(elementIsActive)]="elementIsActive"
  [parent]="parent"
  [template]="template"
  [dragHandle]="dragHandle"
  (elementIsActiveChange)="elementIsActiveChange($event)"
  [component]="this"
>
  @if (element.getStyles().height) {
    <table [id]="element.id" role="presentation" border="0" cellpadding="0" cellspacing="0">
      <tr>
        <td
          [height]="element.getStyles(template.testData).text.height"
          [ngClass]="{ 'ws-email-text__active': elementIsActive }"
          style="vertical-align: top"
          [ngStyle]="template.getStyleOfAttributes()"
        >
          <ng-container [ngTemplateOutlet]="textEditor"></ng-container>
          @if (!elementIsActive) {
            <span [innerHTML]="element.getHtmlWithGlobalStyles(template.styles, template.testData) | safeHtml"></span>
          }
        </td>
      </tr>
    </table>
  } @else {
    <div
      [id]="element.id"
      class="ws-email-text"
      [ngStyle]="template.getStyleOfAttributes()"
      [ngClass]="{ 'ws-email-text__active': elementIsActive }"
    >
      <ng-container [ngTemplateOutlet]="textEditor"></ng-container>
      @if (!elementIsActive) {
        <div [innerHTML]="element.getHtmlWithGlobalStyles(template.styles, template.testData) | safeHtml"></div>
      }
    </div>
  }

  <ng-template #textEditor>
    @if (elementIsActive) {
      <ws-form-text-editor-new
        (click)="$event.stopPropagation()"
        [focusEditorAfterViewInit]="true"
        [(content)]="element.content"
        [useMenu]="true"
        [showTextColorPicker]="element.styles['mj-class'] !== 'heading'"
        [useFloatingMenu]="true"
        [ngClass]="{ 'ws-email-text-heading': element.styles['mj-class'] }"
        [ngStyle]="element.getStyles(template.testData).text"
        style="
          --ngx-editor-border-color: transparent;
          --ws-form-field-padding-top: 0;
          --ws-form-text-editor-content-padding: 0 12px 0 0;
          --ws-form-text-editor-border-color: transparent;
          --ws-form-text-editor-content-p-margin: 0;
        "
        [style]="
          '--ws-form-text-editor-link-color:' +
          template.replaceTestData(template.styles['link-color'].toString(), template.testData) +
          ';'
        "
        [toolbar]="
          ((element.styles['mj-class'] && element.styles['mj-class'].toString()) || '').includes('heading')
            ? reducedToolbar
            : toolbar
        "
      ></ws-form-text-editor-new>
    }
  </ng-template>
</app-ws-email-element-wrapper>
