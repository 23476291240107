<div #ref>
  <ngx-editor-menu
    #editorMenu
    *ngIf="editor"
    [editor]="editor"
    [toolbar]="toolbar"
    [customMenuRef]="customMenu"
  ></ngx-editor-menu>
  <ng-content></ng-content>
</div>

<ng-template #customMenu>
  @if (showTextColorPicker) {
    <div class="relative flex items-center flex-nowrap">
      <mat-icon
        class="ws-text-editor_toolbar-button ws-text-editor_color-selection ml-1.5 my-2 cursor-pointer"
        [style.--selected-color]="colorSelected"
        [matTooltip]="'ws.forms.textEditor.tooltip.useColor' | translate"
        (mousedown)="applyTextColor()"
        >match_case
      </mat-icon>
      <mat-icon
        class="cursor-pointer"
        (mousedown)="textColorPicker.openColorPicker()"
        [matTooltip]="'ws.forms.textEditor.tooltip.selectColor' | translate"
        >expand_more
      </mat-icon>
      <ws-form-color-picker
        #textColorPicker
        [compact]="true"
        [(ngModel)]="colorSelected"
        (colorSelected)="applyTextColor()"
        class="ws-text-editor_color-picker absolute top-0 left-0 opacity-0 pt-0 pointer-events-none"
      ></ws-form-color-picker>
    </div>
  }
  @if (showEmojiPicker) {
    <div class="relative flex items-center">
      <mat-icon
        class="ws-text-editor_toolbar-button mx-1.5 my-2 cursor-pointer"
        [matTooltip]="'ws.forms.textEditor.tooltip.insertEmoji' | translate"
        [matMenuTriggerFor]="emojiMenu"
        (mousedown)="$event.preventDefault()"
        >mood
      </mat-icon>
    </div>

    <mat-menu #emojiMenu="matMenu" class="ws-text-editor_emoji-menu" (mousedown)="$event.preventDefault()">
      <ng-template matMenuContent>
        <ws-form-emoji-insert (emojiClicked)="insertEmoji($event)"></ws-form-emoji-insert>
      </ng-template>
    </mat-menu>
  }
</ng-template>
