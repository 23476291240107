<ws-sidebar
  (showSidebarChange)="onShowDialogChange($event)"
  [(showSidebar)]="showDialog"
  class="ws-email-select-image-dialog__sidebar"
  sidebarTitle="template.image.select"
>
  <ng-template #sidebarContent>
    <div>
      <div class="flex mb-6 items-center">
        <div class="menu-view__search-field mr-4 border rounded-lg overflow-hidden grow">
          <ws-simple-input
            [clearable]="true"
            [formControl]="searchFormControl"
            icon="search"
            placeholder="{{ 'ws.forms.placeholder.search' | translate }}"
            style="margin: 0"
          ></ws-simple-input>
        </div>

        <div class="w-fit flex-none">
          <ws-button
            (click)="imageSelectDialogState = ImageSelectDialogStates.LinkInput"
            buttonClass="w-full justify-center"
            class="mr-2 block text-darkgrey"
          >
            <ws-icon>link</ws-icon>
            <div class="max-xl:hidden">{{ 'template.editor.image.useLink' | translate }}</div>
          </ws-button>

          <ws-button
            (click)="imageSelectDialogState = ImageSelectDialogStates.Upload"
            buttonClass="w-full justify-center"
            class="bg-accent block text-white"
          >
            <ws-icon>upload</ws-icon>
            <div class="max-xl:hidden">{{ 'template.editor.image.upload.upload' | translate }}</div>
          </ws-button>
        </div>
      </div>

      <div class="">
        @if (imageSelectDialogState === ImageSelectDialogStates.Upload) {
          <div>
            <ws-advanced-file-upload #fileUpload (fileSelected)="onFileSelected($event)"></ws-advanced-file-upload>
            <ws-button
              buttonClass="w-full justify-center"
              class="mt-4 mr-2 bg-darkgrey block text-white"
              (click)="imageSelectDialogState = ImageSelectDialogStates.Selection"
            >
              <ws-icon>arrow_back</ws-icon>
              {{ 'back' | translate }}
            </ws-button>
            <ws-button
              buttonClass="w-full justify-center"
              class="mt-4 bg-accent block text-white"
              (click)="onUploadImage()"
              [disabled]="!selectedFile"
              [loading]="uploading"
            >
              <ws-icon>save</ws-icon>
              {{ 'template.image.select' | translate }}
            </ws-button>
          </div>
        } @else if (imageSelectDialogState === ImageSelectDialogStates.LinkInput) {
          <ws-form-input
            placeholder="https://example.com/image.png"
            label="{{ 'template.element.imageUrl' | translate }}"
            [formControl]="linkInputFormControl"
          ></ws-form-input>
          <ws-button
            buttonClass="w-full justify-center"
            class="mt-4 mr-2 bg-darkgrey block text-white"
            (click)="imageSelectDialogState = ImageSelectDialogStates.Selection"
          >
            <ws-icon>arrow_back</ws-icon>
            {{ 'back' | translate }}
          </ws-button>
          <ws-button
            buttonClass="w-full justify-center"
            class="mt-4 bg-accent block text-white"
            (click)="onLinkSelected()"
          >
            <ws-icon>save</ws-icon>
            {{ 'template.image.select' | translate }}
          </ws-button>
        } @else {
          <div class="grid grid-cols-3 md:grid-cols-4 gap-x-4 gap-y-6">
            @if (shownImages.length === 0) {
              <div class="col-span-full text-center text-darkgrey w-full border rounded-lg py-4">
                {{ 'template.image.noImagesFound' | translate }}
              </div>
            }
            @for (image of shownImages; track image) {
              <div>
                <div
                  class="aspect-[4/3] bg-white items-center justify-center cursor-pointer rounded-lg overflow-hidden border hover:ring hover:ring-accent"
                  (click)="onSelectImage(image.url)"
                >
                  <img
                    class="object-contain w-full h-full"
                    ngSrc="{{ image.url }}"
                    alt="{{ image.filename }}"
                    height="100"
                    width="100"
                  />
                </div>
                <div class="text-sm text-[--mdc-outlined-text-field-label-text-color] pt-2 break-all">
                  {{ image.filename }}
                </div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </ng-template>
</ws-sidebar>
