import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { WsEmailElementComponent } from '../ws-email-element/ws-email-element.component'

@Component({
  selector: 'app-ws-email-social',
  templateUrl: './ws-email-social.component.html',
  styleUrls: ['./ws-email-social.component.scss', '../ws-email-element/ws-email-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsEmailSocialComponent extends WsEmailElementComponent implements OnInit, AfterViewInit {}
