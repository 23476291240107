{
  "align": "center",
  "background-color": "#414141",
  "border": "none",
  "border-bottom": "",
  "border-left": "",
  "border-right": "",
  "border-top": "",
  "border-radius": "3px",
  "color": "#ffffff",
  "container-background-color": "",
  "css-class": "",
  "font-size": "16px",
  "font-style": "",
  "font-weight": "normal",
  "height": "",
  "href": "",
  "inner-padding": "10px 25px",
  "letter-spacing": "",
  "line-height": "120%",
  "padding": "10px 25px",
  "padding-bottom": "",
  "padding-left": "",
  "padding-right": "",
  "padding-top": "",
  "rel": "",
  "target": "_blank",
  "text-align": "",
  "text-decoration": "none",
  "text-transform": "none",
  "title": "",
  "vertical-align": "middle",
  "width": ""
}
