import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { WsEmailTestDeliveryInformation } from '../../../data/types/ws-email-test-delivery-information'

@Component({
  selector: 'app-ws-email-test-delivery-dialog',
  templateUrl: './ws-email-test-delivery-dialog.component.html',
  styleUrl: './ws-email-test-delivery-dialog.component.scss'
})
export class WsEmailTestDeliveryDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<WsEmailTestDeliveryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WsEmailTestDeliveryInformation
  ) {}

  testDeliveryForm = new FormGroup({
    from: new FormControl('', [Validators.email, Validators.required]),
    subject: new FormControl(this.data.subject, [Validators.required])
  })

  close(result = false) {
    this.dialogRef.close(result)
  }

  sendTestEmail() {
    if (this.testDeliveryForm.valid) {
      this.data.testEmailAddresses.push(this.testDeliveryForm.value.from || '')
    }
    this.close(true)
  }
}
