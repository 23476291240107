{
  "font-size": "16px",
  "font-style": "",
  "font-weight": "",
  "line-height": "1.4",
  "letter-spacing": "",
  "height": "",
  "text-decoration": "",
  "text-transform": "",
  "align": "left",
  "container-background-color": "",
  "padding": "10px 25px",
  "padding-top": "",
  "padding-bottom": "",
  "padding-left": "",
  "padding-right": "",
  "css-class": ""
}
