{
  "alt": "",
  "align": "center",
  "border": "0",
  "height": "auto",
  "padding": "10px 25px",
  "target": "_blank",
  "font-size": "13px",
  "href": ""
}
