<app-ws-email-element-wrapper
  *ngIf="element"
  [element]="element"
  [elementIsActive]="elementIsActive"
  [parent]="parent"
  [component]="this"
>
  <span [id]="element.id" [innerHTML]="element.getHtmlWithTestData(template.testData) | safeHtml: { removeHref: true }">
  </span>
</app-ws-email-element-wrapper>
