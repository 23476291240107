import { Injectable } from '@angular/core'
import { copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop'

@Injectable({
  providedIn: 'root'
})
export class CdkDragAndDropService {
  dragging = false
  constructor() {}

  onDragStarted() {
    this.dragging = true
    document.body.style.cursor = 'grabbing'
  }

  onDragEnded() {
    this.dragging = false
    document.body.classList.remove('cursor-grabbing')
  }

  drop(event: any) {
    this.dragging = false
    if (!event || event.container.data == undefined || event.previousContainer.data === undefined) {
      console.warn('empty data or event')
      return
    }
    if (event.previousContainer.id.includes('newSectionDroplist')) {
      copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex)
      return
    }
    if (event.previousContainer.data === event.container.data) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex)
    }
  }
}
