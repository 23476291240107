{
  "background-color": "#ffffff",
  "background-height": "none",
  "background-position": "center center",
  "background-url": "",
  "background-width": "",
  "border-radius": "",
  "height": "0px",
  "mode": "fluid-height",
  "padding": "0",
  "padding-bottom": "",
  "padding-left": "",
  "padding-right": "",
  "padding-top": "",
  "vertical-align": "top"
}
