import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { CdkDropList } from '@angular/cdk/drag-drop'
import { Template } from '../../../data/models/template'
import { Subject } from 'rxjs'
import { CdkDragAndDropService } from '../../../services/util/cdk-drag-and-drop.service'
import { EditorStateService } from '../../../services/util/editor-state.service'

@Component({
  selector: 'app-ws-email-editor',
  templateUrl: './ws-email-editor.component.html',
  styleUrl: './ws-email-editor.component.scss'
})
export class WsEmailEditorComponent implements OnInit, OnDestroy {
  @Input() template: Template = new Template('')
  dragHandle = true
  newSectionDroplist: string | CdkDropList | (string | CdkDropList)[] = ['newSectionDroplist']

  ngOnInit() {
    this.allDropLists = []
  }
  allDropLists: any = []

  constructor(
    public cdkDragAndDropService: CdkDragAndDropService,
    private editorStateService: EditorStateService
  ) {}

  private destroy$ = new Subject<void>()

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  onTemplateClick() {
    this.editorStateService.unselectSelectedElement()
  }
}
