import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { WsEmailViewComponent } from './modules/editor/ws-email-view/ws-email-view.component'
import { WsEmailTemplateResolver } from './ws-email-template.resolver'
import { WsEmailAdminComponent } from './modules/editor/ws-email-admin/ws-email-admin.component'

const routes: Routes = [
  {
    path: '',
    component: WsEmailAdminComponent
  },
  {
    path: 'editor/template/:templateId',
    component: WsEmailViewComponent,
    resolve: {
      template: WsEmailTemplateResolver
    }
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
