{
  "container-background-color": "",
  "css-class": "",
  "height": "20px",
  "padding": "",
  "padding-bottom": "",
  "padding-left": "",
  "padding-right": "",
  "padding-top": ""
}
