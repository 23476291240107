import defaultStyles from './dividerStyles.json'
import { Brick } from './brick'

export class Divider extends Brick {
  constructor(styles: { [key: string]: string | number } = {}) {
    super('Divider', '', styles, 'mj-divider')
    this.addDefaultStyles(defaultStyles)
  }

  override getStyles(testData: { [p: string]: any } = {}): any {
    let computeAlign = '0px auto'
    if (this.styles['align'] === 'left') {
      computeAlign = '0px'
    } else if (this.styles['align'] === 'right') {
      computeAlign = '0px 0px 0px auto'
    }
    const p = {
      'border-top': ['style', 'width', 'color']
        .map((attr) => this.replaceTestData(this.styles[`border-${attr}`]?.toString() || '', testData))
        .join(' '),
      'font-size': '1px',
      margin: computeAlign,
      width: this.styles['width']
    }
    // parent td (column)
    const td = {
      padding: this.getPadding()
    }

    return {
      p,
      td,
      outlook: {
        ...p,
        width: 1 // Todo: change (getOutlookWidth)
      }
    }
  }
}
