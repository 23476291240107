import { BrickComposite } from './brickComposite'
import defaultStyles from './socialStyles.json'

export class Social extends BrickComposite {
  constructor(styles: { [key: string]: string | number } = {}) {
    super('Social', '', styles, 'mj-social')
    this.addDefaultStyles(defaultStyles)
  }

  override getStyles(testData: { [p: string]: any } = {}): any {
    return {
      tableVertical: {
        margin: '0px'
      }
    }
  }

  // getSocialElementAttributes() {
  //   const base: any = {}
  //   if (this.styles?.getAttribute('inner-padding')) {
  //     base.padding = this.styles?.getAttribute('inner-padding')
  //   }
  //
  //   return [
  //     'border-radius',
  //     'color',
  //     'font-family',
  //     'font-size',
  //     'font-weight',
  //     'font-style',
  //     'icon-size',
  //     'icon-height',
  //     'icon-padding',
  //     'text-padding',
  //     'line-height',
  //     'text-decoration',
  //   ]
  //     .filter((e) => !isNil(this.styles?.[e]))
  //     .reduce((res, attr) => {
  //       res[attr] = this.styles?.[e])
  //       return res
  //     }, base)
  // }
}
