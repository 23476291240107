<div class="w-full h-full advanced-file-upload__wrapper flex flex-wrap flex-col justify-center content-center">
  <div
    class="advanced-file-upload__drop-zone p-4 flex flex-col m-auto text-center w-full relative border border-dashed border-darkgrey"
    #fileUploadDropZone
  >
    <input
      #fileDropRef
      class="w-full h-full absolute top-0 left-0 opacity-0 cursor-pointer"
      id="fileDropRef"
      type="file"
      [multiple]="multiple"
      [accept]="allowedFileTypes.acceptedFileTypesString"
      (change)="onFileSelected($event)"
      (click)="onInputClick($event)"
    />
    @if ((!multiple && !file) || multiple) {
      @if (!platformService.isMobile) {
        <ws-icon size="40px" class="text-accent">upload</ws-icon>
        <span class="mt-2">{{
          (multiple ? 'ws.forms.files.dragAndDropHere' : 'ws.forms.file.dragAndDropHere') | translate
        }}</span>
        <span class="mt-2">{{ 'ws.forms.file.or' | translate }}</span>
      }
      <ws-button
        class="bg-accent text-white mt-2 max-w-[250px] m-auto"
        (click)="fileDropRef.click()"
        (dragover)="$event.preventDefault()"
        >{{ (multiple ? 'ws.forms.files.browseForFiles' : 'ws.forms.file.browseForFile') | translate }}
      </ws-button>
    }
    <ws-file-preview
      [previewSize]="previewSize"
      [multiple]="multiple"
      [sortingAllowed]="sortingAllowed"
      [files]="multiple ? files : file ? [file] : []"
      (indexOfFileRemoved)="removeFromFiles($event)"
      (sortingOfFilesChanged)="multiple ? emitFiles($event) : emitFile($event[0])"
    >
    </ws-file-preview>
  </div>
  <div class="flex flex-wrap justify-between items-center mt-2 text-xs">
    <span class="mr-2">{{
      'ws.forms.file.allowedFileTypes' | translate: { allowedTypes: userFriendlyFileTypes }
    }}</span>
    <span>{{ 'ws.forms.file.maxFileSize' | translate: { maxFileSize: maxFileSize } }}</span>
  </div>
</div>
