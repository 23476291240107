<div mat-dialog-title>
  <h2 class="text-primary">{{ 'template.editor.testData.edit' | translate }}</h2>
  <ws-form-toggle
    [formControl]="advancedViewFormControl"
    class="absolute top-[1.5em] right-[1.5em] text-darkgrey"
    label="{{ 'template.editor.advancedView' | translate }}"
  ></ws-form-toggle>
</div>

<mat-dialog-content class="ws-email-test-data-dialog__content-wrapper">
  @if (advancedViewFormControl.value) {
    <div>
      <ws-form-textarea [formControl]="testDataAdvancedFormControl" class="w-full px-6" [rows]="20"></ws-form-textarea>
    </div>
  } @else {
    <div class="ws-email-test-data-dialog__title-wrapper grid grid-cols-2 items-center uppercase px-6">
      <span
        ><h4>{{ 'template.editor.testData.key' | translate }}</h4></span
      ><span
        ><h4>{{ 'template.editor.testData.value' | translate }}</h4></span
      >
    </div>
    <div class="shadow-inner px-6">
      <div class="py-4">
        <form [formGroup]="testDataFormGroup" class="divide-y">
          @for (entry of placeholders; track entry.identifier) {
            <div class="ws-email-test-data-dialog__variable-wrapper grid grid-cols-2 items-center">
              @if (entry.isCustom) {
                <ws-form-input [formControlName]="entry.identifier + '__key'" />
              } @else {
                <div>
                  <mat-label class="text-primary">{{ entry.label | localized }}</mat-label>
                  <div class="text-sm">
                    {{ '\$\{' + entry.identifier + '\}' }}
                  </div>
                </div>
              }
              <div class="grid grid-cols-[1fr__auto]">
                <ws-form-input [formControlName]="entry.identifier" />
                <ws-button
                  class="flex items-center"
                  [flat]="true"
                  buttonClass="h-full"
                  (click)="deletePlaceholderVariable(entry.identifier)"
                >
                  <ws-icon class="delete-icon">delete</ws-icon>
                </ws-button>
              </div>
            </div>
          }
        </form>
        <ws-button (click)="openAddVariableDialog()">
          <ws-icon class="add-new-icon">add</ws-icon>
          <span class="hidden xl:block">{{ 'template.editor.testData.addVariable' | translate }}</span>
        </ws-button>
      </div>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <div class="px-4 py-3">
    <button (click)="save()" class="px-2" color="primary" mat-flat-button>
      {{ 'save' | translate }}
      <mat-icon>check</mat-icon>
    </button>
    <button (click)="close()" mat-flat-button>
      {{ 'template.close' | translate }}
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-dialog-actions>
