import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core'
import { WsEmailElementComponent } from '../ws-email-element/ws-email-element.component'

@Component({
  selector: 'app-ws-email-column',
  templateUrl: './ws-email-column.component.html',
  styleUrls: ['./ws-email-column.component.scss', '../ws-email-element/ws-email-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsEmailColumnComponent extends WsEmailElementComponent implements OnInit, AfterViewInit {
  columnIds: any = () => {
    if (!this.element) return
    return this.template.getVisibleChildren().flatMap((sections) => sections.getVisibleChildren().map((c) => c.id))
  }

  override ngAfterViewInit() {
    // this.el.nativeElement.style.width = this.getMjColumnWidth().width;
    this.el.nativeElement.classList.add('ws-email-column')
    super.ngAfterViewInit()
  }

  getMjColumnWidth() {
    const width = this.element?.styles['width']

    if (width) {
      return { width, 'max-width': width }
    }

    if (this.parent && this.parent.getVisibleChildren().length > 1) {
      const dynamicWidth = 100 / this.parent.getVisibleChildren().length + '%'
      return { width: dynamicWidth, 'max-width': dynamicWidth }
    }

    return { width, 'max-width': width }
  }
}
