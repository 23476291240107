import { Directive, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core'
import { Brick } from '../data/models/brick'

@Directive({
  standalone: true,
  selector: '[appResizable]'
})
export class ResizableDirective {
  @Input() blockElement: Brick | undefined
  @Output() resizeEnd: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>()
  private dragging: boolean = false
  private startX: number = 0
  private startY: number = 0
  private startWidth: number = 0
  private startHeight: number = 0

  constructor(private elementRef: ElementRef) {}

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    event.preventDefault()
    if (!this.blockElement) return
    this.dragging = true
    this.startX = event.clientX
    this.startY = event.clientY
    this.startWidth =
      Number(this.blockElement.styles['width']?.toString().replace('px', '')) ||
      this.elementRef.nativeElement.offsetWidth
    this.startHeight =
      Number(this.blockElement.styles['height']?.toString().replace('px', '')) ||
      this.elementRef.nativeElement.offsetHeight
    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('mouseup', this.onMouseUp)
  }

  onMouseMove = (event: MouseEvent) => {
    if (!this.dragging || !this.blockElement) return
    const dx = event.clientX - this.startX
    let newWidth
    newWidth = this.startWidth + dx
    const aspectRatio = this.startWidth / this.startHeight

    // Get container width
    const containerWidth = this.elementRef.nativeElement.parentElement.offsetWidth

    // Ensure new width does not exceed container width
    if (newWidth > containerWidth) {
      newWidth = containerWidth
    }
    const newHeight = newWidth / aspectRatio

    this.blockElement.styles['width'] = Math.round(newWidth) + 'px'
    this.blockElement.styles['height'] = Math.round(newHeight) + 'px'
  }

  onMouseUp = () => {
    this.dragging = false
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseup', this.onMouseUp)
    this.resizeEnd.emit()
  }
}
