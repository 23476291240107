export class WsEmailTemplateHistory {
  wsEmailTemplateHistoryId?: number = 0
  mjml: string = '{}'
  html: string = '{}'
  testData: string = '{}'
  styles: string = '{}'
  created: Date = new Date()

  constructor(
    mjml: string = '{}',
    html: string = '{}',
    testData: string = '{}',
    styles: string = '{}',
    created: Date = new Date()
  ) {
    this.mjml = mjml
    this.html = html
    this.testData = testData
    this.styles = styles
    this.created = created
  }
}
