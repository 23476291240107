<app-ws-email-element-wrapper
  #wrapper
  [element]="element"
  [elementIsActive]="elementIsActive"
  [parent]="parent"
  [dragHandle]="dragHandle"
  [component]="this"
>
  @if (element?.styles?.['mode'] === 'horizontal') {
    <table [align]="element?.styles?.['align']" border="0" cellpadding="0" cellspacing="0" role="presentation">
      <tr>
        <!-- Social Element Attributes-->
        <td>
          <table
            [align]="element?.styles?.['align']"
            border="0"
            cellpadding="0"
            cellspacing="0"
            role="presentation"
            style="float: none; display: inline-table"
          >
            <tbody
              cdkDropList
              [id]="element?.id || ''"
              (cdkDropListDropped)="cdkDragAndDropService.drop($event)"
              [cdkDropListData]="element?.getChildren()"
              cdkDropListOrientation="horizontal"
            >
              <!-- render component ()-->
              @for (child of element?.getChildren(); track child?.id) {
                <td [class]="child.styles['css-class']" align="center" [ngStyle]="child.getStyles().td">
                  <app-ws-email-social-element
                    [id]="child?.id"
                    [template]="template"
                    [parent]="element"
                    [element]="child"
                    class="social-element-horizontal"
                  ></app-ws-email-social-element>
                </td>
              }
            </tbody>
          </table>
        </td>
      </tr>
    </table>
  } @else {
    <!-- vertical -->
    <!--    <table-->
    <!--      border="0"-->
    <!--      cellpadding="0"-->
    <!--      cellspacing="0"-->
    <!--      role="presentation"-->
    <!--      [ngStyle]="element?.getStyles()?.tableVertical"-->
    <!--    >-->
    <!--      <tbody-->
    <!--        cdkDropList-->
    <!--        [id]="element?.id || ''"-->
    <!--        [cdkDropListConnectedTo]="socialElementIds"-->
    <!--        (cdkDropListDropped)="cdkDragAndDropService.drop($event)"-->
    <!--        [cdkDropListData]="element?.getChildren()"-->
    <!--        cdkDropListOrientation="horizontal"-->
    <!--      >-->
    <!--        @for (child of element?.getChildren(); track child?.id) {-->
    <!--          <app-ws-email-social-element-->
    <!--            [id]="child?.id"-->
    <!--            [template]="template"-->
    <!--            [parent]="element"-->
    <!--            [element]="child"-->
    <!--            [dragHandle]="false"-->
    <!--            class="social-element-vertical"-->
    <!--          ></app-ws-email-social-element>-->
    <!--        }-->
    <!--        &lt;!&ndash;      ${this.renderChildren(children, { attributes: this.getSocialElementAttributes(), })}&ndash;&gt;-->
    <!--      </tbody>-->
    <!--    </table>-->
  }
</app-ws-email-element-wrapper>
