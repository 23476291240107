import { Injectable } from '@angular/core'
import { BehaviorSubject, first, Observable, tap } from 'rxjs'
import { Brick } from '../data/models/brick'
import { WsEmailBlock } from '../data/types/ws-email-block'
import { HttpClient } from '@angular/common/http'
import { Template } from '../data/models/template'
import { OpenAIChatCompletion } from '../../../../pos/src/app/shared/ai/ai.service'
import { WsEmailTestDeliveryInformation } from '../data/types/ws-email-test-delivery-information'
import { WsEmailTemplateHistory } from '../data/types/ws-email-template-history'
import { WsEmailTemplate } from '../data/types/ws-email-template'

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  defaultBlocks: WsEmailBlock[] = []
  private _defaultBlocks: BehaviorSubject<WsEmailBlock[] | []> = new BehaviorSubject<WsEmailBlock[] | []>([])
  defaultBlocksObservable = this._defaultBlocks.asObservable()

  constructor(private http: HttpClient) {}

  sendTestEmail(testDeliveryInformation: WsEmailTestDeliveryInformation) {
    return this.http.post('/api/wsemail/test-delivery', testDeliveryInformation)
  }

  setTempApiKey(key: string) {
    sessionStorage.setItem('ws|email-api-key', key)
  }

  getTempApiKey(): string {
    return sessionStorage.getItem('ws|email-api-key') || ''
  }

  loadAllTemplates() {
    return this.http.get<Template[]>('/api/wsemail/template')
  }

  loadTemplateById(templateId: number) {
    return this.http.get<Template>('/api/wsemail/template/' + templateId)
  }

  saveEmailTemplate(template: WsEmailTemplate) {
    return this.http.post<Template>('/api/wsemail/template', {
      name: template.name
    })
  }

  updateEmailTemplate(template: WsEmailTemplate) {
    return this.http.put<Template>(`/api/wsemail/template/${template.wsEmailTemplateId}`, {
      ...template
    })
  }

  deleteTemplate(templateId: number): Observable<any> {
    return this.http.delete(`/api/wsemail/template/${templateId}`)
  }

  //*** Default Blocks */

  saveDefaultBrick(brick: Brick, mjml: string, defaultBlockName = ''): Observable<WsEmailBlock> {
    const newEmailBlock = new WsEmailBlock(
      defaultBlockName ? defaultBlockName : brick.generateId(),
      brick.type,
      `<mjml><mj-body>${mjml}</mj-body></mjml>`,
      true
    )
    return this.http.post<WsEmailBlock>(`/api/wsemail/email-block`, newEmailBlock).pipe(
      tap((block: WsEmailBlock) => {
        this.defaultBlocks.push(block)
        this._defaultBlocks.next(this.defaultBlocks)
      })
    )
  }

  loadDefaultBricks() {
    this.http
      .get<WsEmailBlock[]>('/api/wsemail/email-block')
      .pipe(first())
      .subscribe((defaultBricks: WsEmailBlock[]) => {
        this.defaultBlocks = defaultBricks
        this._defaultBlocks.next(this.defaultBlocks)
      })
  }

  deleteEmailBlock(blockId: number) {
    return this.http.delete(`/api/wsemail/email-block/${blockId.toString()}`)
  }

  askAI(instructions: string, message: string): Observable<OpenAIChatCompletion> {
    return this.http.post<OpenAIChatCompletion>('/api/wsemail/ai/chat/completions', {
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'system',
          content: instructions
        },
        {
          role: 'user',
          content: message
        }
      ]
    })
  }

  getHistoryOfTemplate(templateId: number): Observable<WsEmailTemplateHistory[]> {
    return this.http.get<WsEmailTemplateHistory[]>(`/api/wsemail/template/${templateId}/history/`)
  }

  duplicateTemplate(wsEmailTemplateId: number, templateName: string): Observable<WsEmailTemplate> {
    return this.http.post<WsEmailTemplate>(`/api/wsemail/template/${wsEmailTemplateId}/duplicate`, templateName)
  }
}
