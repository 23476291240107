{
  "align": "center",
  "alt": "",
  "background-color": "",
  "border-radius": "3px",
  "color": "#333333",
  "css-class": "",
  "font-family": "Ubuntu, Helvetica, Arial, sans-serif",
  "font-size": "13px",
  "font-style": "normal",
  "font-weight": "normal",
  "href": "none",
  "icon-height": "",
  "icon-size": "",
  "line-height": "22px",
  "name": "",
  "padding": "4px",
  "padding-bottom": "",
  "padding-left": "",
  "padding-right": "",
  "padding-top": "",
  "icon-padding": "0px",
  "text-padding": "4px 4px 4px 0",
  "sizes": "",
  "src": "",
  "srcset": "",
  "rel": "",
  "target": "_blank",
  "title": "none",
  "text-decoration": "none",
  "vertical-align": "middle"
}

