import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core'

@Directive({
  selector: '[appIframe]',
  standalone: true
})
export class IframeDirective implements OnInit {
  @Input() resizeHeight: string = ''
  @Input() resizeWidth: string = ''

  @Output() iframeClick = new EventEmitter<ElementRef>()
  private iframeMouseOver: boolean = false

  ngOnInit(): void {
    this.renderer.listen(window, 'blur', () => this.onWindowBlur())
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener('load')
  onLoad() {
    this.resizeIframe()
  }

  resizeIframe() {
    const iframe = this.el.nativeElement
    try {
      if (this.resizeWidth) {
        this.renderer.setStyle(iframe, 'width', this.resizeWidth)
      }
      if (this.resizeHeight) {
        this.renderer.setStyle(iframe, 'height', this.resizeHeight)
        return
      }
      //default height full content
      const contentHeight = iframe.contentWindow.document.body.scrollHeight
      this.renderer.setStyle(iframe, 'height', `${contentHeight}px`)
    } catch (error) {
      console.error('Error resizing iframe:', error)
    }
  }

  @HostListener('mouseover')
  private onIframeMouseOver() {
    this.iframeMouseOver = true
    this.resetFocusOnWindow()
  }

  @HostListener('mouseout')
  private onIframeMouseOut() {
    this.iframeMouseOver = false
    this.resetFocusOnWindow()
  }

  private onWindowBlur() {
    if (this.iframeMouseOver) {
      this.resetFocusOnWindow()
      this.iframeClick.emit(this.el)
    }
  }

  private resetFocusOnWindow() {
    setTimeout(() => {
      window.focus()
    }, 100)
  }
}
