<div cdkDropListGroup>
  <div
    [id]="template.id"
    class="empty-template-droplist body-container container"
    [style]="template.getBackgroundColor()"
    [cdkDropListData]="template.getChildren()"
    [cdkDropListConnectedTo]="newSectionDroplist"
    (cdkDropListDropped)="cdkDragAndDropService.drop($event)"
    (click)="onTemplateClick()"
    cdkDropList
  >
    @for (element of template.getChildren(); track element.id; let i = $index) {
      @switch (element.type) {
        @case ('Section') {
          <app-ws-email-section
            [parent]="template"
            [template]="template"
            [element]="element"
            [parentElementAndSiblings]="[template]"
            [hidden]="element.isHidden()"
          ></app-ws-email-section>
        }
        @case ('Hero') {
          <app-ws-email-hero
            [hidden]="element.isHidden()"
            [template]="template"
            [element]="element"
          ></app-ws-email-hero>
        }
      }
    }
  </div>
</div>
