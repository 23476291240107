import { Injectable } from '@angular/core'
import { Template } from '../../data/models/template'
import { BrickComposite } from '../../data/models/brickComposite'
import { Column } from '../../data/models/column'
import { Section } from '../../data/models/section'
import { Text } from '../../data/models/text'
import { Image } from '../../data/models/image'
import { Divider } from '../../data/models/divider'
import { Button } from '../../data/models/button'
import { Spacer } from '../../data/models/spacer'
import { Hero } from '../../data/models/hero'
import { Social } from '../../data/models/social'
import { SocialElement } from '../../data/models/social-element'
import { Brick } from '../../data/models/brick'

@Injectable({
  providedIn: 'root'
})
export class MjmlProcessorService {
  constructor() {}

  parseMjml(mjml: string) {
    try {
      const parser = new DOMParser()

      if (!mjml) {
        throw new Error('MJML content is empty or undefined')
      }

      mjml = mjml
        .replaceAll(/src="([^"]*)&([^"]*)"/g, 'src="$1&amp;$2"')
        .replaceAll('&nbsp;', ' ')
        .replaceAll('</br>', '<br />')
        .replaceAll('<hr>', '<hr />')
        .replace(/(<br(?![^>]*\/>)[^>]*)(>)/g, '$1 /$2')
        .replace(/(<img[^>]+)(?<!\/)>/gi, '$1/>')

      mjml = this.encodeLinks(mjml)

      const xmlDoc = parser.parseFromString(mjml, 'text/xml')
      // Check if parsing was successful
      if (xmlDoc.getElementsByTagName('parsererror').length > 0) {
        throw new Error('XML parsing error: ' + xmlDoc.getElementsByTagName('parsererror')[0].textContent)
      }

      // Process the MJML document
      const mjmlElement = xmlDoc.getElementsByTagName('mjml')[0]

      if (!mjmlElement) {
        console.error('Invalid MJML format')
        return null
      }

      const mjBodyElement = mjmlElement.getElementsByTagName('mj-body')[0]

      if (!mjBodyElement) {
        console.error('MJML does not contain an mj-body element')
      }

      return mjBodyElement
    } catch (error: any) {
      console.error('Parse error:', error.message)
      return null
    }
  }

  generateTemplateFromMjml(
    mjml: string,
    name = 'Template x',
    styles = {},
    testData = {},
    wsEmailTemplateId: number | null = null
  ): Template {
    const template: Template = new Template(name, styles, testData, wsEmailTemplateId)
    const mjBodyElement = this.parseMjml(mjml)
    if (mjBodyElement === null) {
      return template
    }

    this.setStylesFromAttributes(mjBodyElement.attributes, template.styles)

    // Recursively process the mj-body element and its children
    this.processTemplateBodyElement(mjBodyElement, template)
    return template
  }

  private processTemplateBodyElement(mjBodyElement: Element, template: Template) {
    for (const child of Array.from(mjBodyElement.children)) {
      if (child.tagName === 'mj-section') {
        this.processMjmlSectionElement(child, template)
      }

      if (child.tagName === 'mj-hero') {
        const heroStyles: { [p: string]: string | number } = {}

        this.setStylesFromAttributes(child.attributes, heroStyles)
        const hero = new Hero(heroStyles)
        template.add(hero)
        this.processMjmlLastChildElement(child, hero)
      }
    }
  }

  generateSectionsFromMjml(mjml: string): Section[] {
    const bodyElement = this.parseMjml(mjml)
    if (bodyElement == null) {
      return []
    }
    const sections: Section[] = []
    Array.from(bodyElement.getElementsByTagName('mj-section')).forEach((sectionElement) => {
      sections.push(this.processMjmlSectionElement(sectionElement, new Template('Template x')))
    })
    return sections
  }

  generateColumnFromMjml(mjml: string): Column {
    const bodyElement = this.parseMjml(mjml)
    if (bodyElement == null) {
      return new Column()
    }
    return this.processMjColumnElement(bodyElement)[0] || new Column()
  }

  generateLastChildFromMjml(mjml: string): Brick {
    const bodyElement = this.parseMjml(mjml)
    if (bodyElement == null) {
      return new Column()
    }

    return this.processMjmlLastChildElement(bodyElement, new Column()) || new Text('new text')
  }

  processMjmlSectionElement(sectionElement: Element, template: Template) {
    const sectionStyles: { [p: string]: string | number } = {}
    this.setStylesFromAttributes(sectionElement.attributes, sectionStyles)
    if (sectionElement.getElementsByTagName('mj-group').length > 0) {
      sectionStyles['grouped-columns'] = 'true'
    }
    const section = new Section(sectionStyles)
    template.add(section)
    this.processMjColumnElement(sectionElement, section)
    return section
  }

  processMjColumnElement(columnElement: Element, section?: BrickComposite) {
    const columnElements = Array.from(columnElement.getElementsByTagName('mj-column'))
    const createdColumns = []
    if (columnElements.length > 0) {
      for (const columnElement of columnElements) {
        const columnStyles: { [p: string]: string | number } = {}
        this.setStylesFromAttributes(columnElement.attributes, columnStyles)
        const column = new Column(columnStyles)

        if (section) section.add(column)

        // Recursively process the children of mj-column
        this.processMjmlLastChildElement(columnElement, column)
        createdColumns.push(column)
      }
    }
    return createdColumns
  }

  private processMjmlLastChildElement(mjColumnElement: Element, parentBrick: BrickComposite) {
    for (const child of Array.from(mjColumnElement.children)) {
      if (child.tagName === 'mj-text') {
        // Create a new Text brick for each mj-text
        const textStyles: { [p: string]: string | number } = {}
        this.setStylesFromAttributes(child.attributes, textStyles)

        const textBrick = new Text(this.decodeLinks(child.innerHTML) || 'new text', textStyles)

        parentBrick.add(textBrick)
      } else if (child.tagName === 'mj-image') {
        // Create a new Text brick for each mj-image

        const imageStyles: { [p: string]: string | number } = {}
        this.setStylesFromAttributes(child.attributes, imageStyles)

        const imageBrick = new Image(imageStyles)
        parentBrick.add(imageBrick)
      } else if (child.tagName === 'mj-divider') {
        const dividerStyles: { [p: string]: string | number } = {}
        this.setStylesFromAttributes(child.attributes, dividerStyles)

        const dividerBrick = new Divider(dividerStyles)
        parentBrick.add(dividerBrick)
      } else if (child.tagName === 'mj-spacer') {
        const spacerStyles: { [p: string]: string | number } = {}
        this.setStylesFromAttributes(child.attributes, spacerStyles)

        const spacerBrick = new Spacer(spacerStyles)
        parentBrick.add(spacerBrick)
      } else if (child.tagName === 'mj-button') {
        const buttonStyles: { [p: string]: string | number } = {}

        this.setStylesFromAttributes(child.attributes, buttonStyles)

        const buttonBrick = new Button(buttonStyles, child.innerHTML || '')
        parentBrick.add(buttonBrick)
      } else if (child.tagName === 'mj-social') {
        const socialStyles: { [p: string]: string | number } = {}

        this.setStylesFromAttributes(child.attributes, socialStyles)

        const socialBrick = new Social(socialStyles)
        parentBrick.add(socialBrick)

        Array.from(child.children).forEach((socialElement) => {
          if (socialElement.tagName === 'mj-social-element') {
            const socialElementStyles: { [p: string]: string | number } = {}

            this.setStylesFromAttributes(socialElement.attributes, socialElementStyles)

            //inherited from its parent (socialBrick)
            socialElementStyles['icon-size'] = socialBrick.styles['icon-size'] || '35px'
            socialElementStyles['icon-height'] = socialBrick.styles['icon-size'] || '35px'

            const content = socialElement.textContent ? socialElement.textContent.trim() : ''
            const socialElementBrick = new SocialElement(content, socialElementStyles)
            socialBrick.add(socialElementBrick)
          }
        })
      }
    }
    return parentBrick.getChildren()[0]
  }

  private encodeLinks(text: string): string {
    return text
      .replaceAll(/href="([^"]*)"/g, (match, url) => {
        const decodedUrl = encodeURIComponent(url)
        return `href="${decodedUrl}"`
      })
      .replaceAll(/src="([^"]*)"/g, (match, url) => {
        const decodedUrl = encodeURIComponent(url)
        return `src="${decodedUrl}"`
      })
  }

  private decodeLinks(text: string): string {
    return text
      .replaceAll(/href="([^"]*)"/g, (match, url) => {
        const decodedUrl = decodeURIComponent(url)
        return `href="${decodedUrl}"`
      })
      .replaceAll(/src="([^"]*)"/g, (match, url) => {
        const decodedUrl = decodeURIComponent(url)
        return `src="${decodedUrl}"`
      })
  }

  private setStylesFromAttributes(attributesOfParsedMjml: NamedNodeMap, styles: { [p: string]: string | number }) {
    const attributes = Array.from(attributesOfParsedMjml)

    attributes.forEach((attribute) => {
      if (attribute.name === 'src' || attribute.name === 'href') {
        styles[attribute.name] = decodeURIComponent(attribute.value)
        return
      }
      styles[attribute.name] = attribute.value
    })

    return styles
  }
}
