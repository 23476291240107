<mat-dialog-content>
  <div>
    <ws-form-textarea label="Ai Nachricht" [rows]="10" class="w-full" [formControl]="testDataFormField">
    </ws-form-textarea>
  </div>
</mat-dialog-content>

<div mat-dialog-actions class="flex justify-between">
  <button class="px-2" mat-flat-button color="accent" (click)="save()" [disabled]="!testDataFormField.valid">
    {{ 'save' | translate }}
    <mat-icon>check</mat-icon>
  </button>
  <button mat-button (click)="close()" matTooltipPosition="below">
    {{ 'template.close' | translate }}
    <mat-icon>close</mat-icon>
  </button>
</div>
