import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core'

import { WsEmailElementComponent } from '../ws-email-element/ws-email-element.component'
import { Brick } from '../../../data/models/brick'

@Component({
  selector: 'app-ws-email-section',
  templateUrl: './ws-email-section.component.html',
  styleUrls: ['./ws-email-section.component.scss', '../ws-email-element/ws-email-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WsEmailSectionComponent extends WsEmailElementComponent implements OnInit, AfterViewInit {
  // @ViewChildren(WsEmailColumnComponent) childComponents?: QueryList<WsEmailColumnComponent>
  highestColumnHeight = '100%'
  amountOfColumns = 0

  sectionIds(): string[] {
    const connectedDroplists = this.template.getVisibleChildren().map((child) => child.id)
    connectedDroplists.push('newColumnDroplist')
    return connectedDroplists
  }
  override ngOnInit() {
    super.ngOnInit()
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit()

    this.el.nativeElement.classList.add('ws-email-column')
    // this.calculateColumnHeight()
    this.amountOfColumns = this.element?.getChildren().length || 0
  }

  private calculateColumnHeight() {
    setTimeout(() => {
      let highestColumnHeight = 50
      const wsEmailColumns = this.el.nativeElement.querySelectorAll('.ws-email-column')
      const emptyColumns = this.el.nativeElement.querySelectorAll('.empty-column')
      // console.log(wsEmailColumns)
      if (wsEmailColumns === null) {
        return
      }
      wsEmailColumns.forEach((column: any) => {
        if (column.offsetHeight > highestColumnHeight) {
          highestColumnHeight = column.offsetHeight
        }
      })
      emptyColumns.forEach((column: any) => {
        column.style.height = highestColumnHeight + 'px'
      })
    }, 3000)
  }

  getContainerWidth() {
    return this.template?.styles['width'] || '600px'
  }

  getMjColumnWidth(column: Brick) {
    const width = column.styles['width']

    const height = this.el.nativeElement.height + 'px'
    if (width) {
      return { 'min-height': height, width: width, 'max-width': width }
    }

    const lengthVisibleColumns = this.element?.getVisibleChildren().length || 1

    if (this.element && lengthVisibleColumns > 1) {
      const dynamicWidth = 100 / lengthVisibleColumns + '%'
      return { 'min-height': height, width: dynamicWidth, 'max-width': dynamicWidth }
    }

    return { 'min-height': height, width: '100%', 'max-width': '100%' }
  }
}
